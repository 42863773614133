import {DriverService} from "../DriverService";
import {DriverLocation} from "../../data/DriverLocation";
import {io} from "socket.io-client";
import {JwtHelper} from "../../../../common/helper/JwtHelper";
import {API} from "../../../../common/const/Routes";
import {Driver} from "../../data/Driver";
import {httpHelper} from "../../../../common/helper/HttpCommon";
import {Pagination} from "../../../../common/data/Pagination";
import {SOCKET_URL} from "../../../../common/const/Variables";

export class DriverServiceDefaultImpl implements DriverService {

    private static _instance: DriverServiceDefaultImpl

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    async findAllDrivers(status: string[]): Promise<Pagination<Driver[]>> {
        const params = new URLSearchParams();
        params.append("size", "180");
        status.forEach(s => params.append("status", s))
        return await httpHelper.get<Pagination<Driver[]>>(API.DRIVERS_ADMIN, params);
    }

    findAllDriversLocation(
        onConnect: () => void,
        onDisconnect: () => void,
        onNewLocation: (location: DriverLocation) => void
    ): Promise<void> {

        const socket = io(
            SOCKET_URL,
            {auth: {token: JwtHelper.getJwt()}}
        );

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('all_drivers_location', (event: any) => {
            const location = JSON.parse(String.fromCharCode.apply(null, [...new Uint8Array(event.value)]));
            onNewLocation(location)
        });

        return Promise.resolve();
    }

}