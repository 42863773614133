import React from "react";
import Select from 'react-select';
import clsx from "clsx";


export interface Value {
    readonly value: string,
    readonly label: string
}

interface MultiSelectProps<T> {
    readonly options: T[],
    readonly values?: T[],
    readonly onChange: (newValue: T[]) => void
}

export default function MultiSelect<T extends Value>({options, onChange, values}: MultiSelectProps<T>) {
    return <>
        <Select
            onChange={(e: any) => {
                onChange(e)
            }}
            value={values}
            isMulti={true}
            className={clsx("border", "border-gray-300 ", "block", " w-full", "min-w-0", "flex-1", "rounded-md", "focus:border-indigo-500", "focus:ring-indigo-500", "sm:text-sm")}
            styles={reactSelectStyle}
            options={options}
        />
    </>
}

const reactSelectStyle = {
    control: (provided: any, state: any) => ({
        ...provided,
        height: "40px",
        border: "none",
        boxShadow: "none"
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? "rgba(189,197,209,.4)" : "white",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
        border: "none"
    })
};