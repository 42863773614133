import InputText from "../../../../../components/InputText";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import React, {useEffect, useState} from "react";
import {BusinessService} from "../../../../business/service/BusinessService";
import {BusinessServiceImpl} from "../../../../business/service/impl/BusinessServiceImpl";
import {toast} from "react-toastify";
import {IPartialBusiness} from "../../../../business/data/PartialBusiness";
import {ContactType} from "../../../../business/data/Contact";
import InputMask from "../../../../../components/InputMask";
import {IBusiness} from "../../../../business/data/Business";


export default function Contact() {
    const businessService: BusinessService = BusinessServiceImpl.Instance;

    const [business, setBusiness] = useState<IPartialBusiness | null>(null)
    const [overlay, setOverlay] = useState<boolean>(false)

    useEffect(() => {
        fetchBusiness().then()
    }, [])

    async function fetchBusiness() {
        businessService.find().then((business) => {
            business = {
                ...business, contacts: business?.contacts?.map((c) => {
                    return {type: c.type, link: c.originalLink, originalLink: c.originalLink}
                }) ?? null
            }
            setBusiness(business)
        })
    }

    async function onSubmit(e: any) {
        e.preventDefault();
        console.log(business)
        if (business != null) {
            setOverlay(true)
            await businessService.update({...business, updateCover: false} as IBusiness)
            setOverlay(false)
            toast("Business has been updated successfully")
        }
    }

    function changeContact(v: string, type: ContactType) {
        let exists = false;
        let copyBusiness = {...business} as IPartialBusiness
        business?.contacts?.forEach((c, i) => {
            if (v !== "") {
                if (c.type === type && business?.contacts != null) {
                    c = {...c, link: v, originalLink: v}
                    business.contacts[i] = c
                    copyBusiness = {...business,contacts: business.contacts}
                    exists = true
                    return
                }
            } else {
                if (c.type === type && business?.contacts != null) {
                    business.contacts.splice(i, 1)
                    copyBusiness = {...business,contacts: business.contacts}
                    exists = true
                    return
                }
            }
        })

        if(!exists && v !== "" && business) {
            if(business.contacts == null) {
                copyBusiness = {...business,contacts: [{type: type, link: v, originalLink: v}]}
            } else {
                business.contacts.push({type: type, link: v, originalLink: v})
                copyBusiness = {...business,contacts: business?.contacts ?? null}
            }
        }

        setBusiness(copyBusiness)
    }

    return <>
        {
            (business != null) ?
                <form className="divide-y divide-gray-200 lg:col-span-9" onSubmit={onSubmit}
                      action="#" method="POST">
                    <div className="py-6 px-4 sm:p-6 lg:pb-8">
                        <div>
                            <h2 className="text-lg font-medium leading-6 text-gray-900">Contacts</h2>
                            <p className="mt-1 text-sm text-gray-500">
                                Business contacts information.
                            </p>
                        </div>
                        <div className="mt-6 grid grid-cols-12 gap-6">
                            <div className="col-span-6">
                                <div className="flex-grow space-y-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Whatsapp
                                        </label>
                                        <div className="mt-2">
                                            <InputMask mask="44 9999 999999"
                                                       value={business?.contacts?.find(c => c.type === ContactType.WHATSAPP)?.originalLink ?? ""}
                                                       onChange={(v) => {
                                                            changeContact(v, ContactType.WHATSAPP)
                                                       }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-6">
                                <div className="flex-grow space-y-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Instagram
                                        </label>
                                        <div className="mt-2">
                                            <InputText
                                                initialValue={business?.contacts?.find(c => c.type === ContactType.INSTAGRAM)?.originalLink ?? ""}
                                                onChange={(v) => {
                                                    changeContact(v, ContactType.INSTAGRAM)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-6">
                                <div className="flex-grow space-y-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Email
                                        </label>
                                        <div className="mt-2">
                                            <InputText
                                                initialValue={business?.contacts?.find(c => c.type === ContactType.EMAIL)?.originalLink ?? ""}
                                                onChange={(v) => {
                                                    changeContact(v, ContactType.EMAIL)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-6">
                                <div className="flex-grow space-y-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Facebook
                                        </label>
                                        <div className="mt-2">
                                            <InputText
                                                initialValue={business?.contacts?.find(c => c.type === ContactType.FACEBOOK)?.originalLink ?? ""}
                                                onChange={(v) => {
                                                    changeContact(v, ContactType.FACEBOOK)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-6">
                                <div className="flex-grow space-y-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Phone
                                        </label>
                                        <div className="mt-2">
                                            <InputMask mask="44 999 9999 9999"
                                                       value={business?.contacts?.find(c => c.type === ContactType.PHONE)?.originalLink ?? ""}
                                                       onChange={(v) => {
                                                           changeContact(v, ContactType.PHONE)
                                                       }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-6">
                                <div className="flex-grow space-y-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Website
                                        </label>
                                        <div className="mt-2">
                                            <InputText
                                                initialValue={business?.contacts?.find(c => c.type === ContactType.WEBSITE)?.originalLink ?? ""}
                                                onChange={(v) => {
                                                    changeContact(v, ContactType.WEBSITE)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-6">
                                <div className="flex-grow space-y-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Linkedin
                                        </label>
                                        <div className="mt-2">
                                            <InputText
                                                initialValue={business?.contacts?.find(c => c.type === ContactType.LINKEDIN)?.originalLink ?? ""}
                                                onChange={(v) => {
                                                    changeContact(v, ContactType.LINKEDIN)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 flex justify-end py-4 px-4 sm:px-6">
                        <button
                            type="submit"
                            className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-indigo-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                        >
                            Save Changes
                        </button>
                    </div>
                    <LoadingOverlay active={overlay}/>
                </form> : null
        }
    </>
}