import UserService from "../UserService";
import User from "../../../../../../../common/data/User";
import {API} from "../../../../../../../common/const/Routes";
import {httpHelper} from "../../../../../../../common/helper/HttpCommon";

export default class UserServiceImpl implements UserService {

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private static _instance: UserServiceImpl

    async addDriverRole(email: string): Promise<User> {
        const resource = API.DRIVER_ROLE.replace("{email}", email)
        return await httpHelper.put<User>(resource, null);
    }

    async findUserById(): Promise<User> {
        return await httpHelper.get<User>(API.USERS);
    }
}