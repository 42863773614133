import {OrderType as Type} from "../data/Order";
import classNames from "../../../common/helper/CssHelper";

interface OrderTypeProps {
    orderType: Type
}

export default function OrderType({orderType}: OrderTypeProps) {
    return <>
        <span
            className={classNames((orderType == Type.PICKUP) ? "text-amber-800" : "text-purple-800", "text-sm font-bold")}>
            {orderType.toLocaleLowerCase()}
        </span>
    </>
}