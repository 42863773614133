import jwt_decode from "jwt-decode";
import {Media} from "../../../common/data/Media";
import CatalogItem from "./CatalogItem";
import {JwtHelper} from "../../../common/helper/JwtHelper";

export default interface CatalogSection {
    readonly id: string,
    readonly key: string,
    readonly businessId: string,
    readonly names: any,
    readonly order: number,
    readonly created?: Date,
}

export function initCatalogSection(): CatalogSection {
    return {
        businessId : JwtHelper.getBusinessId(),
        names : {
            PT : "",
            EN : "",
        },
        order : 0
    } as CatalogSection
}