import { JwtHelper } from "../common/helper/JwtHelper";
import { SessionHelper } from "../common/helper/SessionHelper";
import {Routes} from "./const/Routes";
import {Navigate} from "react-router-dom";

interface Prop {
    children: React.ReactNode
}

export function PrivateRoutes({ children }: Prop) {
    const isAdmin = SessionHelper.retrieveUserSession()?.isAdminRole() || false;

    if (isAdmin) {
        JwtHelper.wipeJWT();
        SessionHelper.wipeUserSession();
        return <Navigate to={Routes.LOGIN} />;
    }
    const jwt = localStorage.getItem("jwt");
    return jwt ? <>{children}</> : <Navigate to={Routes.LOGIN} />;
}