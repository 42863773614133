import Spinner from "./Spinner";


interface LoadingOverlay {
    active: boolean
}

export default function LoadingOverlay({active}: LoadingOverlay) {
    return <>
        {(active) ? <div className="fixed z-30 w-full h-full bg-white opacity-60 inset-y-0 left-0">
            <div className="absolute top-1/2 left-1/2">
                <Spinner/>
            </div>
        </div> : null}
    </>
}