import jwt_decode from "jwt-decode";


export class JwtHelper {

    static setJwt(jwt: string): void {
        localStorage.setItem("jwt", jwt)
    }

    static getJwt(): string | null {
        return localStorage.getItem("jwt")
    }

    static wipeJWT(): void {
        return localStorage.removeItem("jwt")
    }

    static getBusinessId(): string {
        const jwt = localStorage.getItem("jwt")
        let businessId = ""
        if (jwt != null) {
            const decoded: any = jwt_decode(jwt);
            businessId = decoded["business-id"]
        }
        return businessId
    }
}