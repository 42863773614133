export default interface CatalogOption {
    names: any,
    descriptions: any,
    options: Option[],
    minimum : number,
    maximum: number,
    isMulti: boolean,
    order: number,
    name: string
    pos: number
}

export interface Option {
    names: any,
    descriptions: any
    name: string | null
    description: string | null
    price?: number | null
    quantity?: number | null
}

export interface Customization {
    items: CatalogOption[]
}


export function initOption(): Option {
    return {
        names: {
            PT: "",
            EN: ""
        },
        descriptions: {
            PT: "",
            EN: ""
        },
        price: null
    } as Option
}
export function initCatalogOption() : CatalogOption {
    return {
        names: {
            PT: "",
            EN: ""
        },
        descriptions: {
            PT: "",
            EN: ""
        },
        options: [],
        minimum: 0,
        maximum: 0,
        isMulti: false,
        order: 0,
        name: "",
        pos: 0
    } as CatalogOption
}