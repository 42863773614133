import clsx from "clsx";
import {ClockIcon, CogIcon, ShoppingBagIcon, TruckIcon, UserCircleIcon} from "@heroicons/react/solid";
import General from "./section/General";
import React, {useState} from "react";
import Tab from "../../../common/data/Tab";
import WorkingHours from "./section/workingHours/WorkingHours";
import Delivery from "./section/delivery/Delivery";
import Driver from "./section/driver/Driver";
import Contact from "./section/contact/Contact";
import {FaWhatsapp} from "@react-icons/all-files/fa/FaWhatsapp";
import {FaWhatsappSquare} from "@react-icons/all-files/fa/FaWhatsappSquare";

const tabs: Tab[] = [
    {name: 'General', href: '#general', current: true, icon: UserCircleIcon},
    {name: 'Delivery and Prep', href: '#delivery', current: false, icon: TruckIcon},
    {name: 'Contacts', href: '#contacts', current: false, icon: FaWhatsappSquare},
    {name: 'Working Times', href: '#working-times', current: false, icon: ClockIcon},
    {name: 'Drivers', href: '#drivers', current: false, icon: ShoppingBagIcon}

]

export default function Settings() {

    const [selectedTab, setSelectedTab] = useState<string>("#general");

    function getTabContent() {
        switch (selectedTab) {
            case "#general":
                return <General/>
            case "#delivery":
                return <Delivery/>
            case "#working-times":
                return <WorkingHours/>
            case "#drivers":
                return <Driver/>
            case "#contacts":
                return <Contact/>
        }
    }

    return <main className="relative">
        <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
            <aside className="py-6 lg:col-span-3">
                <nav className="space-y-1">
                    {tabs.map((item) => (
                        <a
                            onClick={(e) => {
                                setSelectedTab(item.href)
                            }}
                            key={item.name}
                            href={item.href}
                            className={clsx(
                                selectedTab == item.href
                                    ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                                    : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                                'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                        >
                            <item.icon
                                className={clsx(
                                    selectedTab == item.href
                                        ? 'text-teal-500 group-hover:text-teal-500'
                                        : 'text-gray-400 group-hover:text-gray-500',
                                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                )}
                                aria-hidden="true"
                            />
                            <span className="truncate">{item.name}</span>
                        </a>
                    ))}
                </nav>
            </aside>
            {getTabContent()}
        </div>
    </main>
}