import React, {useEffect, useState} from "react";
import clsx from "clsx";

interface InputTextArea {
    onChange(newValue: string): void

    initialValue?: string
    error?: boolean,
    rows?: number,
    errorMessage?: string,
    placeholder?: string,
    disabled?: boolean
}

export default function InputTextArea(
    {
        onChange,
        initialValue,
        error,
        rows,
        placeholder = "",
        errorMessage,
        disabled = false
    }: InputTextArea) {

    const [value, setValue] = useState<string>((initialValue) ? initialValue : "");

    useEffect(() => {
        onChange(value)
    }, [value])

    return <div className="relative mt-1 rounded-md w-full">
        <textarea disabled={disabled}
                  rows={(rows) ? rows : 3}
                  placeholder={placeholder}
                  className={clsx((error) ? "border-red-600" : "border-gray-300 ", "block", " w-full", "min-w-0", "flex-1", "rounded-md", "focus:border-indigo-500", "focus:ring-indigo-500", "sm:text-sm", (disabled) ? "bg-gray-100" : "")}
                  value={value}
                  onChange={(e) => {
                      setValue(e.target.value)
                  }}
        />
        {
            (error && errorMessage != "") ? <p className="mt-2 text-sm text-red-600">
                {errorMessage}
            </p> : <></>
        }
    </div>

}