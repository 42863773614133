import React from "react";

interface StatusProps {
    readonly status: string;
}

export default function StatusPin({status}: StatusProps) {

    function getStatus() {
        switch (status) {
            case "ONLINE":
                return <span className="absolute bottom-2 right-2 block translate-x-1/2 translate-y-1/2 transform rounded-full border-2 border-white">
                              <span className="block h-3 w-3 rounded-full bg-emerald-400" />
                            </span>;
            case "OFFLINE":
                return <span className="absolute bottom-2 right-2 block translate-x-1/2 translate-y-1/2 transform rounded-full border-2 border-white">
                              <span className="block h-3 w-3 rounded-full bg-gray-300" />
                            </span>;
            case "ASSIGNED":
                return <span className="absolute bottom-2 right-2 block translate-x-1/2 translate-y-1/2 transform rounded-full border-2 border-white">
                              <span className="block h-3 w-3 rounded-full bg-indigo-300" />
                            </span>

        }
    }
    return <>
        {getStatus()}
    </>
}