import {CategoryService} from "../CategoryService";
import {Category} from "../../data/Category";
import {httpHelper} from "../../../../common/helper/HttpCommon";
import {API} from "../../../../common/const/Routes";

export default class CategoryServiceImpl implements CategoryService {

    private static _instance: CategoryServiceImpl;

    async findAll(): Promise<Category[]> {
        return await httpHelper.get<Category[]>(API.CATEGORIES)
    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    async findAllSubCategories(): Promise<Category[]> {
        return await httpHelper.get<Category[]>(API.SUBCATEGORIES)
    }

}