import React, {useEffect, useState} from "react";
import {GoogleMap, LoadScript, OVERLAY_MOUSE_TARGET, OverlayViewF} from '@react-google-maps/api';
import {DriverLocation} from "../data/DriverLocation";
import {DriverService} from "../service/DriverService";
import {DriverServiceDefaultImpl} from "../service/impl/DriverServiceDefaultImpl";
import {Driver} from "../data/Driver";
import DriverRow from "./DriverRow";
import StatusPin from "./StatusPin";
import {Switch} from "@headlessui/react";
import clsx from "clsx";
import {PlaceService} from "../service/PlaceService";
import {PlaceServiceDefaultImpl} from "../service/impl/PlaceServiceDefaultImpl";
import {Place} from "../data/Place";
import {JsxElement} from "typescript";

const containerStyle = {
    width: '100%',
    height: '100vh'
};

interface Coordinate {
    lat: number,
    lng: number
}

export default function Drivers() {

    const driverService: DriverService = DriverServiceDefaultImpl.Instance;
    const placesService: PlaceService = PlaceServiceDefaultImpl.Instance;
    //const [isConnected, setIsConnected] = useState(socket.connected);
    const [markers, setMarkers] = useState<Map<string, DriverLocation>>();
    const [places, setPlaces] = useState<Place[]>([]);
    const [showOffline, setShowOffline] = useState<boolean>(false);
    const [drivers, setDrivers] = useState<Map<string, Driver>>(new Map<string, Driver>());
    const [zoom, setZoom] = useState<number>(11);
    const [center, setCenter] = useState<Coordinate>({
        lat: 51.507130,
        lng: -0.270750
    });

    useEffect(() => {
        fetchDrivers(showOffline);
        const interval = setInterval(function () {
            fetchDrivers(showOffline)
        }, 60000);

        return () => {
            clearInterval(interval)
        }

    }, [])

    useEffect(() => {
        placesService.findAllPlaces().then(
            places => {
                setPlaces(places)
            }
        )
    }, [])

    useEffect(() => {
        driverService.findAllDriversLocation(
            onConnect,
            onDisconnect,
            onNewLocation).then()
    }, [drivers])

    function fetchDrivers(showOffline: boolean) {
        var status = [];
        if (showOffline) {
            status.push("ONLINE", "OFFLINE", "ASSIGNED")
        } else {
            status.push("ONLINE", "ASSIGNED")
        }
        driverService.findAllDrivers(status).then((drivers) => {
            const map = new Map<string, Driver>();
            const markers = new Map<string, DriverLocation>();
            drivers.content
                .forEach((driver: Driver) => {
                    map.set(driver.id, driver)
                    if (driver && driver.driverLocation) {
                        markers.set(driver.id, driver.driverLocation)
                    }
                })
            setDrivers(map)
            setMarkers(markers)
        })
    }

    function onConnect() {
        console.log("connected!");
    }

    function onDisconnect() {
        console.log("disconnected!");
    }

    function onNewLocation(location: DriverLocation) {
        if (markers && markers.get(location.driverId)) {
            const newRef = markers.set(location.driverId, location)
            setMarkers(newRef)
        }
    }

    function getDriverRows() {
        const rows: any[] = []
        drivers.forEach((driver: Driver, _: string) => {
            rows.push(
                <DriverRow id={driver.id}
                           image={driver.avatar || "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"}
                           name={`${driver.firstName} ${driver.lastName}`}
                           earnings={driver.currentEarnings}
                           status={driver.status.toString()}
                           onNewPosition={(id: string) => {
                               const marker = markers?.get(id) ?? void 0
                               if (marker && marker.location) {
                                   setCenter({
                                       lat: Number(driver.driverLocation.location.latitude),
                                       lng: Number(driver.driverLocation.location.longitude)
                                   })
                                   setZoom(17)
                               }
                           }}
                />
            )
        })
        return rows
    }

    function getMarkers(): JSX.Element[] {
        return (markers && markers.size > 0) ? Array
            .from(markers.values())
            .filter((location: DriverLocation) => drivers.has(location.driverId))
            .map((location: DriverLocation) => {
                return (location && location.location) ? <OverlayViewF
                    key={location.driverId}
                    position={{
                        lat: Number(location.location.latitude),
                        lng: Number(location.location.longitude)
                    }} mapPaneName={OVERLAY_MOUSE_TARGET}>
                    <div className="bg-white p-1 rounded-full shadow-md">
                        <div className="flex-shrink-0">
                            <img className="h-15 w-11 rounded-full"
                                 src={drivers.get(location.driverId)?.avatar || "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"}
                                 alt=""/>
                            <StatusPin
                                status={drivers.get(location.driverId)?.status ?? "OFFLINE"}/>
                        </div>
                    </div>
                </OverlayViewF> : <></>
            }) : []
    }


    function getPlacesMarkers(): JSX.Element[] {
        return places.map(place => {
            return <OverlayViewF
                key={place.id}
                position={{
                    lat: Number(place.location.latitude),
                    lng: Number(place.location.longitude)
                }} mapPaneName={OVERLAY_MOUSE_TARGET}>
                <div className="bg-white p-1 rounded-full shadow-md">
                    <div className="flex-shrink-0">
                        <img className="h-15 w-11 rounded-full"
                             src={place?.logo?.url || "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"}
                             alt=""/>
                    </div>
                </div>
            </OverlayViewF>
        });
    }

    function getAllMarkers(): JSX.Element[] {
        return getPlacesMarkers().concat(getMarkers())
    }

    return <div>
        <div className="grid grid-cols-12">
            <div className="col-span-12">
                <div>
                    <LoadScript
                        googleMapsApiKey="AIzaSyAcTVLO5jPTOVajuLtgH8jTQYcqVUeG8Nw">
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={zoom}>
                            {getAllMarkers()}
                        </GoogleMap>
                    </LoadScript>

                </div>
            </div>
            <div className="absolute w-full md:bottom-auto top h-48 md:h-full md:w-auto bg-white overflow-auto">
                <div className="px-6 lg:px-6">
                    <div>
                        <Switch.Group as="li"
                                      className="flex items-center justify-between py-4">
                            <div className="flex flex-col">
                                <Switch.Label as="p"
                                              className="text-sm font-medium text-gray-900"
                                              passive>
                                    Show Offline
                                </Switch.Label>
                            </div>
                            <Switch
                                checked={showOffline}
                                onChange={(e: boolean) => {
                                    console.log(e);
                                    setShowOffline(e)
                                    fetchDrivers(e)
                                }}
                                className={clsx(
                                    showOffline ? 'bg-teal-500' : 'bg-gray-200',
                                    'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                )}
                            >
                                <span
                                    aria-hidden="true"
                                    className={clsx(
                                        showOffline ? 'translate-x-5' : 'translate-x-0',
                                        'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                    )}
                                />
                            </Switch>
                        </Switch.Group>
                    </div>
                </div>
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {getDriverRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}