import clsx from "clsx";
import React from "react";

export interface Option {
    name: string,
    id: string
}

interface SimpleSelectProps {
    value: string
    options: Option[]
    error?: boolean
    errorMessage?: string
    disabled?: boolean

    onChange(id: string): void
}

export default function SimpleSelect({
                                         value,
                                         options,
                                         error = false,
                                         errorMessage = "",
                                         disabled = false,
                                         onChange
                                     }: SimpleSelectProps) {
    return (
        <div className="relative mt-1 rounded-md w-full">
            <select onChange={(e) => {
                onChange(e.target.value)
            }} value={value}
                    className={clsx((error) ? "border-red-600" : "border-gray-300 ", "block", " w-full", "min-w-0", "flex-1", "rounded-md", "focus:border-indigo-500", "focus:ring-indigo-500", "sm:text-sm", (disabled) ? "bg-gray-100" : "")}>
                {
                    options.map((option, index) => {
                        return <option key={index} value={option.id}>{option.name}</option>
                    })
                }
            </select>
            {
                (error && errorMessage != "") ? <p className="mt-2 text-sm text-red-600">
                    {errorMessage}
                </p> : <></>
            }
        </div>
    )
}