import UploadResponse from "../core/catalog/data/UploadResponse";
import React, {Fragment, useCallback, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XIcon} from "@heroicons/react/solid";
import Cropper from "react-easy-crop";
import LoadingOverlay from "./LoadingOverlay";
import getCroppedImg from "../common/helper/cropImage";
import {toast} from "react-toastify";
import {MediaService, MediaType} from "../common/service/media/MediaService";
import MediaServiceDefaultImpl from "../common/service/media/impl/MediaServiceDefaultImpl";


interface GenericFileUploadProps {
    open: boolean

    onClose(): void

    onCompleted(uploadResponse: UploadResponse): void

    aspect?: number
}

export default function GenericFileUpload({
                                              open,
                                              onClose,
                                              onCompleted,
                                              aspect = 1
                                          }: GenericFileUploadProps) {

    const mediaService: MediaService = MediaServiceDefaultImpl.Instance;

    const [image, setImage] = useState<string>("");
    const [overlay, setOverlay] = useState<boolean>(false);

    const [crop, setCrop] = useState({x: 0, y: 0})
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({})

    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    function onUpload(e: any) {
        e.preventDefault()
        setOverlay(true)
        getCroppedImg(
            image,
            croppedAreaPixels,
        ).then((croppedImage) => {
            mediaService.upload(MediaType.MARKETPLACE_CATALOG, croppedImage).then((uploadResponse) => {
                setOverlay(false)
                onClose()
                onCompleted(uploadResponse)
                toast("Image has been successfully uploaded")
            })
        })
    }

    return <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={() => {
            onClose()
        }}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </Transition.Child>

            <div className="fixed inset-0 z-20 overflow-y-auto">
                <div
                    className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel
                            className="relative transform w-full overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-4xl">
                            <div
                                className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => {
                                        onClose()
                                    }}
                                ><span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6"
                                           aria-hidden="true"/>
                                </button>
                            </div>
                            <div
                                className="space-y-8 sm:space-y-5 px-4 pt-5 pb-4">
                                <div
                                    className="space-y-6 sm:space-y-5">
                                    <div>
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Upload
                                            your file</h3>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                            Upload file and crop image.
                                        </p>
                                    </div>
                                    {
                                        (image != "") ?
                                            <div
                                                className="crop-parent">
                                                <div
                                                    className="app">
                                                    <div
                                                        className="crop-container">
                                                        <Cropper
                                                            image={image}
                                                            crop={crop}
                                                            zoom={zoom}
                                                            aspect={aspect}
                                                            onCropChange={setCrop}
                                                            onCropComplete={onCropComplete}
                                                            onZoomChange={setZoom}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : <div
                                                className="relative p-12">
                                                <div
                                                    className="flex px-5 mx-auto max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-20 pb-20">
                                                    <div
                                                        className="space-y-1 text-center">
                                                        <svg
                                                            className="mx-auto h-12 w-12 text-gray-400"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            viewBox="0 0 48 48"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        <div
                                                            className="flex text-sm text-gray-600">
                                                            <label
                                                                htmlFor="file-upload"
                                                                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                                            >
                                                                <span>Upload a file</span>
                                                                <input
                                                                    onChange={(e: any) => {

                                                                        if (!e.target.files || e.target.files.length === 0) {
                                                                            setImage("")
                                                                            return
                                                                        }

                                                                        const objectUrl = URL.createObjectURL(e.target.files[0])
                                                                        setImage(objectUrl)
                                                                    }}
                                                                    id="file-upload"
                                                                    name="file-upload"
                                                                    type="file"
                                                                    className="sr-only"/>
                                                            </label>
                                                            <p className="pl-1">or
                                                                drag and
                                                                drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">PNG,
                                                            JPG, GIF up
                                                            to
                                                            10MB</p>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="border border-t">
                                <div className="p-5">
                                    <div
                                        className="flex justify-end">
                                        <button
                                            onClick={() => {
                                                setImage("")
                                                onClose()
                                            }}
                                            type="button"
                                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={onUpload}
                                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
            <LoadingOverlay active={overlay}/>
        </Dialog>
    </Transition.Root>
}