import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import {XIcon} from "@heroicons/react/solid";
import {Order} from "../data/Order";
import OrderType from "./OrderType";
import Moment from "react-moment";
import Status from "./OrderStatus";

interface OrderDetailsProps {
    open: boolean,

    onClose(): void,

    order: Order
}

export default function OrderDetails({open, onClose, order}: OrderDetailsProps) {

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform w-full overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-3xl sm:p-6">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <main className="relative lg:min-h-full">
                                    <div>
                                        <div>
                                            <div className="lg:col-start-2">
                                                <h1 className="text-xl font-bold">
                                                    Order summary
                                                </h1>
                                                <div
                                                    className="divide-y divide-gray-200 border-t mt-5"/>
                                                <div
                                                    className="mt-5 flex items-center justify-between">
                                                    <div className="grid grid-cols-2 gap-2">
                                                        <div className="flex items-center">
                                                            <div
                                                                className="h-10 w-10 flex-shrink-0">
                                                                <img
                                                                    className="h-10 w-10 rounded-full"
                                                                    src={order?.user?.avatar ?? ""}
                                                                    alt=""/>
                                                            </div>
                                                            <div className="ml-4">
                                                                <div
                                                                    className="font-medium text-sm text-gray-900">{(order && order.user && order.user.fullName != "") ? order?.user?.fullName ?? "" : "Unknown"}</div>
                                                                <div
                                                                    className="text-gray-500 text-sm">{(order && order.user && order.user.phoneNumber != "") ? order?.user?.phoneNumber ?? "" : "Phone not defined"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1 className="text-xl">
                                                        Order <span
                                                        className="text-indigo-600 font-bold">#{(order) ? order.orderNumber : ""}</span>
                                                    </h1>
                                                </div>
                                                <div
                                                    className="divide-y divide-gray-200 border-t mt-5"/>
                                                <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 mt-5">
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Type</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            {
                                                                (order && order.type) ?
                                                                    <OrderType
                                                                        orderType={order.type}/> : null
                                                            }

                                                        </dd>
                                                    </div>

                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Street</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            {`${(order && order.deliveryAddress) ? order.deliveryAddress.street : "Not defined"}, ${(order && order.deliveryAddress) ? order.deliveryAddress.flat : "Not defined"}`}
                                                        </dd>
                                                    </div>

                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Postcode</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            {(order && order.deliveryAddress) ? order.deliveryAddress.postalCode : "Not defined"}
                                                        </dd>
                                                    </div>


                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Status</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <Status orderStatus={order.status}/>
                                                        </dd>
                                                    </div>

                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Driver</dt>
                                                        <dd className="mt-1 max-w-prose space-y-5 text-sm text-gray-900">
                                                            <p>{(order && order.driver && order.driver.fullName != "") ? order.driver.fullName : "No driver"}</p>
                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Created</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <Moment
                                                                date={(order && order.deliveryAddress) ? order.created : ""}
                                                                fromNow ago/> ago
                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Payment
                                                            method
                                                        </dt>
                                                        <dd className="mt-1 max-w-prose space-y-5 text-sm text-gray-900">
                                                            <p>{(order && order.paymentMethod != "") ? order.paymentMethod : "No payment method"}</p>
                                                        </dd>
                                                    </div>

                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Estimated
                                                            time
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <div>
                                                                <Moment
                                                                    date={(order && order.deliveryAddress) ? order.expectedCompletionTime.start : ""}
                                                                    format="H:mm"/> - <Moment
                                                                date={(order && order.deliveryAddress) ? order.expectedCompletionTime.end : ""}
                                                                format="H:mm"/>
                                                                {" - "} <Moment
                                                                date={(order && order.deliveryAddress) ? order.expectedCompletionTime.start : ""}
                                                                format="YYYY-MM-DD"/>
                                                            </div>

                                                        </dd>
                                                    </div>

                                                    <div className="sm:col-span-2">
                                                        <dt className="text-sm font-medium text-gray-500">Instructions</dt>
                                                        <dd className="mt-1 max-w-prose space-y-5 text-sm text-gray-900">
                                                            <p>{(order && order.instructions != "") ? order.instructions : "No instructions defined"}</p>
                                                        </dd>
                                                    </div>
                                                </dl>
                                                <h3 className="mt-6 font-bold text-sm">
                                                    Items
                                                </h3>
                                                <ul
                                                    role="list"
                                                    className="mt-3 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
                                                >
                                                    {
                                                        (order && order.items) ? order.items.map((t) => {
                                                            return <li key={t?.item?.id ?? ""}
                                                                       className="flex justify-between items-center space-x-3 py-3">
                                                                <div className="flex items-center">
                                                                    <div
                                                                        className="h-10 w-10 flex-shrink-0">
                                                                        <img
                                                                            className="h-10 w-10"
                                                                            src={t?.item?.pictureThumb?.url ?? ""}
                                                                            alt={t?.item?.pictureThumb?.alt ?? ""}/>
                                                                    </div>
                                                                    <div className="ml-4">
                                                                        <div
                                                                            className="font-medium text-gray-900">
                                                                            <strong
                                                                                className="text-blue">{t?.quantity ?? ""}x</strong> {t?.item?.name ?? ""}
                                                                        </div>
                                                                        <div
                                                                            className="text-gray-500">{t?.instructions ?? ""}</div>
                                                                        {
                                                                            (t?.customization && t?.customization.items.length > 0) ? t.customization.items.map((c) => {
                                                                                return c.options.map((o) => {
                                                                                    return <div className="text-gray-500">{c.name} · <strong>{(o.quantity && o.quantity > 0) ? `${o.quantity}x` : null} {o.name ?? ""} {(o.price && o.price != 0) ? `£${o.price.toFixed(2)}` : null}</strong></div>
                                                                                })
                                                                            }) : <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span
                                                                        className="text-gray-900 font-bold">£{t?.totalPrice?.toFixed(2) ?? 0}</span>
                                                                </div>
                                                            </li>
                                                        }) : null
                                                    }
                                                </ul>

                                                <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                                                    <div className="flex justify-between">
                                                        <dt>Subtotal</dt>
                                                        <dd className="text-gray-900">£{order.subTotal}</dd>
                                                    </div>

                                                    <div className="flex justify-between">
                                                        <dt>Delivery</dt>
                                                        <dd className="text-gray-900">£{order.deliveryPrice}</dd>
                                                    </div>
                                                    {
                                                        (order && order.discount) ?
                                                            <div className="flex justify-between">
                                                                <dt>Discount
                                                                    {order.coupon ? <span> (#{order.coupon.code} - {order.coupon.description})</span> : null}
                                                                </dt>
                                                                <dd className="text-gray-900 font-bold text-red-600">-£{order.discount}</dd>
                                                            </div> : <></>
                                                    }
                                                    <div
                                                        className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                                                        <dt className="text-base">Total</dt>
                                                        <dd className="text-base font-bold">
                                                            {
                                                                (order && order.discount) ? <span
                                                                    className="text-gray-500 text-md font-medium mr-1 line-through">£{order.totalBeforeDiscount}</span>
                                                                    : <></>
                                                            }
                                                            £{order.total}
                                                        </dd>
                                                    </div>
                                                </dl>
                                                <div
                                                    className="mt-16 border-t border-gray-200 py-6 text-right">
                                                    <button
                                                        onClick={onClose}
                                                        type="button"
                                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}