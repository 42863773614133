import {PushRequest} from "./PushRequest";

export default interface Push {
    readonly id: string;
    readonly userId: string;
    readonly title: string;
    readonly message: string;
    readonly data: PushData;
    readonly time: Date;
    readonly status: PushStatus;
}

export interface PushData {
    readonly redirect: RedirectType;
    readonly id: string;
}

export enum PushStatus {
    SENT = "SENT", PENDING = "PENDING"
}

export enum RedirectType {
    BUSINESS = "BUSINESS",
}

export class PushHelper {
    public static newPushRequest(): PushRequest {
        return {
            title: "",
            message: "",
            data: {
                redirect: RedirectType.BUSINESS,
                id: "",
            },
            time: new Date(),
        } as PushRequest
    }

    public static from(push: Push): PushRequest {
        return {
            id: push.id,
            title: push.title,
            message: push.message,
            data: {
                redirect: push.data.redirect,
                id: push.data.id,
            },
            time: push.time,
            status : push.status
        } as PushRequest
    }
}
