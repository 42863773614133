import {FormatOptionLabelMeta} from "react-select";
import AsyncSelect from "react-select/async";
import React from "react";
import {AddressService} from "../../../core/address/service/AddressService";
import {
    AddressServiceDefaultImpl
} from "../../../core/address/service/impl/AddressServiceDefaultImpl";
import {Address} from "../../../core/address/data/AddressOption";


interface AddressSelectProps {
    onChange(addr: Address): void
    error?: boolean,
    errorMessage?: string,
}

export function AddressSelect({onChange, error = false, errorMessage = ""}: AddressSelectProps) {

    const reactSelectStyle = {
        control: (provided: any, state: any) => ({
            ...provided,
            padding: "0px 0",
            fontSize: "0.875rem",
            boxShadow: "none",
            borderColor: (error) ? "red" : "#ccc"
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? "rgba(189,197,209,.4)" : "white",
        }),
        indicatorSeparator: (provided: any, state: any) => ({
            border: "none"
        })
    };

    const addressService: AddressService = AddressServiceDefaultImpl.Instance

    function loadOptions(inputValue: string, callback: any) {
        addressService.autocomplete(inputValue).then((resp) => {
            callback(resp.items)
        })
    }

    function noOptionsMessage(term: { inputValue: string }) {
        return term.inputValue.length > 3 ? (
            <span>No options</span>
        ) : (
            <span>Search input must be at least 3 characters</span>
        );
    }

    function formatOptionLabel(
        address: any, formatOptionLabelMeta: FormatOptionLabelMeta<any>) {

        return <>
            <div className="flex items-center">
                <div className="ml-4">
                    <div
                        className="font-medium text-sm text-gray-900">{address.mainText}</div>
                    <div
                        className="text-sm text-gray-500">{address.secondaryText}</div>
                </div>
            </div>
        </>
    }

    function onChangeValue(address: any) {
        addressService.findAddressByReferenceId(address.referenceId).then((addr) => {
            onChange(addr)
        })
    }

    return <>
        <div className="w-full">
            <AsyncSelect
                placeholder={"Search for an address"}
                value={""}
                className="z-15 rounded"
                noOptionsMessage={noOptionsMessage}
                loadOptions={loadOptions} formatOptionLabel={formatOptionLabel}
                styles={reactSelectStyle}
                getOptionValue={(option: any) => {
                    return option.referenceId;
                }}
                onChange={onChangeValue}
            />
            {
                (error && errorMessage != "") ? <p className="mt-2 text-sm text-red-600">
                    {errorMessage}
                </p> : <></>
            }
        </div>
    </>
}