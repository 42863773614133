import LoadingOverlay from "../../../components/LoadingOverlay";
import Moment from "react-moment";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon, PencilAltIcon} from "@heroicons/react/solid";
import React, {Fragment, useEffect, useState} from "react";
import classNames from "../../../common/helper/CssHelper";
import CatalogService from "../../catalog/service/CatalogService";
import CatalogServiceDefaultImpl from "../../catalog/service/impl/CatalogServiceDefaultImpl";
import CatalogSection from "../../catalog/data/CatalogSection";
import EditSection from "./EditSection";
import AddSection from "./AddSection";
import EmptyState from "../../../components/EmptyState";


export default function Section() {

    const catalogService: CatalogService = CatalogServiceDefaultImpl.Instance;

    const [overlay, setOverlay] = useState<boolean>(false);
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);

    const [sections, setSections] = useState<CatalogSection[]>([]);
    const [selectedSection, setSelectedSection] = useState<CatalogSection>({} as CatalogSection);

    useEffect(() => {
        fetchSections().then()
    }, [])

    async function fetchSections() {
        const sections =  await catalogService.findSections();
        setSections(sections)
    }

    return <>
        <div className="py-6 sm:px-6 lg:px-8 px-4">
            <LoadingOverlay active={overlay}/>
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Section list</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        The list of sections.
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            setOpenCreate(true)
                        }}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Add new section
                    </button>
                </div>
            </div>
            <div
                className="shadow ring-1 ring-black md:overflow-visible overflow-x-auto ring-opacity-5 md:rounded-lg mt-5">
                <table className="min-w-full z-0 divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                        <th scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Name
                        </th>
                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Order
                        </th>
                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Created
                        </th>
                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Action
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {
                        (sections && sections.length > 0) ? sections.map((s) => {
                            return <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                    <div className="flex items-center">
                                        <div className="ml-4">
                                            <div className="font-medium max-w-sm text-gray-900">
                                                {
                                                    s.names.PT
                                                }
                                            </div>
                                            <p className="font-medium text-gray-500">
                                                {
                                                    s.names.EN
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                    <strong>{s.order}</strong>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                    <Moment date={s.created} fromNow ago/> ago
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                            <Menu.Button
                                                className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                                <ChevronDownIcon className="h-4 w-4"
                                                                 aria-hidden="true"/>
                                            </Menu.Button>
                                        </div>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    <Menu.Item>
                                                        <a
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                console.log(s)
                                                                setSelectedSection(s)
                                                                setOpenEdit(true);
                                                            }}
                                                            className={classNames('text-gray-700',
                                                                'group flex items-center px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            <PencilAltIcon
                                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                            Edit item
                                                        </a>
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </td>
                            </tr>
                        }) : null
                    }
                    </tbody>
                </table>
                <EditSection key={selectedSection.id} section={selectedSection} open={openEdit} onClose={() => {setOpenEdit(false)}} postUpdate={() => {
                    fetchSections().then()
                }}/>
                <AddSection key="add-section-key" open={openCreate} onClose={() => {setOpenCreate(false)}} postCreate={() => {
                    fetchSections().then()
                }}/>
                {
                    (sections && sections.length == 0) ?
                        <div className="flex w-full p-10">
                            <EmptyState description="Sorry, no content to be shown"
                                        subDescription="No section items were found."/>
                        </div> : null
                }
            </div>
        </div>
    </>
}