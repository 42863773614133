


export interface EscPosBuilder {

    init(): this

    text(text: string): this

    newline(): this

    line(text: string): this

    align(align: Align): this

    bold(onOff: boolean): this

    textLg(onOff: boolean): this

    textSm(onOff: boolean): this

    partialCut(): this

    fullCut(): this

    build(): any[]

    clear(): this
}

export enum Align {
    CENTER, RIGHT, LEFT
}