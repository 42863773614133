import React, {useEffect, useState} from "react";
import {TextField} from "../../../components/Fields";
import {useNavigate} from "react-router-dom";
import {LoginService} from "../service/LoginService";
import {LoginServiceDefaultImpl} from "../service/impl/LoginServiceDefaultImpl";
import {Routes} from "../../../routes/const/Routes";
import {APPLICATION_URL} from "../../../common/const/Variables";
import {toast, ToastContainer} from "react-toastify";
import LoadingOverlay from "../../../components/LoadingOverlay";
import {JwtHelper} from "../../../common/helper/JwtHelper";
import {SessionHelper} from "../../../common/helper/SessionHelper";

export default function Login() {

    const loginService: LoginService = LoginServiceDefaultImpl.Instance

    const [email, setEmail] = useState<string>("");
    const [overlay, setOverlay] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");

    const navigate = useNavigate();

    useEffect(() => {
        if (JwtHelper.getJwt() != null) {
            navigate(Routes.ORDERS)
        }
    },[])

    async function login(e: any) {
        e.preventDefault()
        try {

            setOverlay(true);
            SessionHelper.wipeUserSession();

            const jwt = await loginService.login(email, password);
            JwtHelper.setJwt(jwt);
            await SessionHelper.setUserSession(jwt);

            navigate(Routes.ORDERS)
            setOverlay(false);
        } catch (e: any) {
            toast("Invalid login", {type: "error", position: "top-left"})
            setOverlay(false);
        }
    }

    return <div className="flex min-h-full">
        <ToastContainer/>
        <div
            className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
                <LoadingOverlay active={overlay}/>
                <div>
                    <img
                        className="h-12 w-auto"
                        src={APPLICATION_URL + "/img/conecta-logo.png"}
                        alt="Your Company"
                    />
                    <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Sign in to
                        your account</h2>
                    <p className="mt-2 text-sm text-gray-600">
                        Or{' '}
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                            start your 14-day free trial
                        </a>
                    </p>
                </div>

                <div className="mt-8">
                    <div className="mt-6">
                        <form onSubmit={login} className="mt-10 grid grid-cols-1 gap-y-5">
                            <div>
                                <TextField
                                    onChange={(e: any) => {
                                        setEmail(e.target.value)
                                    }}
                                    label="Email address"
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                />
                            </div>

                            <div className="space-y-1">
                                <TextField
                                    onChange={(e: any) => {
                                        setPassword(e.target.value)
                                    }}
                                    label="Password"
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                />
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <input
                                        id="remember-me"
                                        name="remember-me"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label htmlFor="remember-me"
                                           className="ml-2 block text-sm text-gray-900">
                                        Remember me
                                    </label>
                                </div>

                                <div className="text-sm">
                                    <a href="#"
                                       className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Forgot your password?
                                    </a>
                                </div>
                            </div>

                            <div>
                                <button
                                    onClick={login}
                                    type="submit"
                                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    Sign in
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
            <img
                className="absolute inset-0 h-screen w-full object-cover"
                src={APPLICATION_URL + "/img/login-back2.png"}
                alt=""
            />
        </div>
    </div>
}