import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useEffect, useState} from "react";
import {XIcon} from "@heroicons/react/solid";
import CatalogItem, {
    DEFAULT_TAGS_MULTI_SELECTION,
    fromTagToMultiSelection
} from "../data/CatalogItem";
import LoadingOverlay from "../../../components/LoadingOverlay";
import CatalogService from "../service/CatalogService";
import CatalogServiceDefaultImpl from "../service/impl/CatalogServiceDefaultImpl";
import {toast} from "react-toastify";
import InputText from "../../../components/InputText";
import InputTextArea from "../../../components/InputTextArea";
import InputCurrency from "../../../components/InputCurrency";
import {newError} from "../../../common/data/Error";
import GenericFileUpload from "../../../components/GenericFileUpload";
import Select, {Value} from "../../../components/Select";
import MultiSelect from "../../../components/MultiSelect";
import InputNumber from "../../../components/InputNumber";
import NullableInputNumber from "../../../components/NullableInputNumber";
import EmptyState from "../../../components/EmptyState";
import classNames from "../../../common/helper/CssHelper";
import Tab from "../../../common/data/Tab";
import CatalogOption, {Customization} from "../data/CatalogOption";
import AddCatalogOption from "./AddCatalogOption";
import EditCatalogOption from "./EditCatalogOption";

interface CatalogItemDetailsProps {
    open: boolean,

    onClose(): void,

    postUpdate(): void,

    catalogItem: CatalogItem,

    sections: Value[]
}

const initialTabs: Tab[] = [
    {name: 'Product', href: '#product', current: true},
    {name: 'Options', href: '#options', current: false},
]


export default function EditCatalogItem({
                                            open,
                                            onClose,
                                            postUpdate,
                                            catalogItem,
                                            sections
                                        }: CatalogItemDetailsProps) {

    const catalogService: CatalogService = CatalogServiceDefaultImpl.Instance;

    const [item, setItem] = useState<CatalogItem>(catalogItem);
    const [overlay, setOverlay] = useState(false);
    const [selectedTab, setSelectedTab] = useState<string>("#product");
    const [tabs, setTabs] = useState<Tab[]>(initialTabs);
    const [cropImgOpen, setCropImgOpen] = useState<boolean>(false);
    const [selectedSectionId, setSelectedSectionId] = useState<string>(catalogItem.catalogSectionId);
    const [editCatalogOption, setEditCatalogOption] = useState<CatalogOption | null>(null)
    const [editCatalogOptionModal, setEditCatalogItemModal] = useState<boolean>(false)
    const [catalogOptionModal, setCatalogItemModal] = useState<boolean>(false)

    const [errors, setErrors] = useState({
        titlePT: {
            error: false,
            message: ""
        },
        titleEN: {
            error: false,
            message: ""
        },
        descriptionPT: {
            error: false,
            message: ""
        },
        descriptionEN: {
            error: false,
            message: ""
        },
        price: {
            error: false,
            message: ""
        },
        discount: {
            error: false,
            message: ""
        },
        stock: {
            error: false,
            message: ""
        }
    })

    useEffect(() => {
        setEditCatalogItemModal(true)
    },[editCatalogOption])

    function onUpdate(e: any) {
        e.preventDefault();

        if (validate()) {
            toast("Error: Please fill all required fields",{type: "error"})
            return
        }

        setOverlay(true)
        catalogService.updateItem(item).then((catalogItem) => {
            setOverlay(false)
            toast("Product has been successfully updated");
            onClose()
            postUpdate()
        })
    }

    function getOptionsTab() {
        return <>
            <div className="items-end border-t flex">
                <div className="flex-1 mt-8">
                    <h2 className="text-lg font-medium leading-6 text-gray-900">Product Options</h2>
                    <p className="mt-1 text-sm text-gray-500">
                        Set multiple options for your product
                    </p>
                </div>
                <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        onClick={(e) => {
                            setCatalogItemModal(true)
                        }}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        New Option
                    </button>
                </div>
            </div>
            {
                (item.customization) ?
                    <ul role="list" className="divide-y border-t mt-5 divide-gray-200">
                        {
                            item.customization.items.map((i, index) => {
                                return <li
                                    className="px-6 py-4 text-sm flex items-center">
                                    <div className="flex-1">
                                        {`${i.names.PT} - ${i.names.EN}`}
                                        <p className="text-gray-500 text-sm">
                                            {
                                                `Minimum: ${i.minimum} · Maximum ${i.maximum} · Order ${i.order}`
                                            }
                                        </p>
                                    </div>
                                    <div>
                                        <button
                                            onClick={(e) => {
                                                i.pos = index
                                                setEditCatalogOption({...i})
                                            }}
                                            type="button"
                                            className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault()
                                                item.customization?.items.splice(index, 1)
                                                setItem({...item})
                                            }}
                                            type="button"
                                            className="rounded-md bg-white font-medium ml-4 text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </li>
                            })
                        }
                    </ul> : <div className="flex w-full p-10"><EmptyState description="You do not have options."
                                                                          subDescription="You do not have any options for this product"/>
                    </div>
            }
        </>
    }

    function getContentTab(selectedTab: string) {
        switch (selectedTab) {
            case "#product":
                return getProductTab()
            case "#options":
                return getOptionsTab()
        }
    }

    function validate(): boolean {

        let hasError = false;

        let copyError = {...errors}

        if (item.names.PT == "" || item.names.PT < 5) {
            copyError = {...copyError, titlePT: newError(true, "Invalid title PT")}
            hasError = true
        }

        if (item.names.EN == "" || item.names.EN < 5) {
            copyError = {...copyError, titleEN: newError(true, "Invalid title EN")}
            hasError = true
        }

        if (item.descriptions.PT == "" || item.descriptions.PT < 5) {
            copyError = {...copyError, descriptionPT: newError(true, "Invalid description PT")}
            hasError = true
        }

        if (item.descriptions.EN == "" || item.descriptions.EN < 5) {
            copyError = {...copyError, descriptionEN: newError(true, "Invalid description EN")}
            hasError = true
        }

        if(item.discount != null && item.discount && item.fullPrice != null && item.fullPrice > 0 && item.discount > item.fullPrice){
            copyError = {...copyError, discount: newError(true, "Invalid discount")}
            hasError = true
        }

        if(item.stock != null && item.stock < 0){
            copyError = {...copyError, stock: newError(true, "Invalid stock")}
            hasError = true
        }

        setErrors(copyError)

        return hasError
    }

    function getProductTab() {
        return <div className="space-y-6 sm:space-y-5">
            <div
                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="username"
                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Section
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select onChange={(e: string) => {
                        setSelectedSectionId(e);
                        setItem({...item, catalogSectionId: e})
                    }} values={sections} initialValue={
                        sections.filter((e) => {
                            return (e.id == selectedSectionId)
                        })[0]
                    }/>
                </div>
            </div>

            <div
                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="username"
                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Title PT
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div
                        className="flex max-w-lg rounded-md shadow-sm">
                        <InputText error={errors.titlePT.error}
                                   errorMessage={errors.titlePT.message}
                                   initialValue={item.names.PT}
                                   onChange={(e) => {
                                       const names = {
                                           "PT": e,
                                           "EN": item.names.EN
                                       }
                                       const newItem = {
                                           ...item,
                                           names: names
                                       }
                                       setItem(newItem)
                                   }}/>
                    </div>
                </div>
            </div>

            <div
                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="username"
                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Title EN
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div
                        className="flex max-w-lg rounded-md shadow-sm">
                        <InputText error={errors.titleEN.error}
                                   initialValue={item.names.EN}
                                   errorMessage={errors.titleEN.message}
                                   onChange={(e) => {
                                       const names = {
                                           "PT": item.names.PT,
                                           "EN": e
                                       }
                                       const newItem = {
                                           ...item,
                                           names: names
                                       }
                                       setItem(newItem)
                                   }}/>
                    </div>
                </div>
            </div>

            <div
                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="about"
                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Description PT
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <InputTextArea
                        error={errors.descriptionPT.error}
                        initialValue={item.descriptions.PT}
                        errorMessage={errors.descriptionPT.message}
                        rows={3}
                        onChange={(e) => {
                            const descriptions = {
                                "PT": e,
                                "EN": item.descriptions.EN
                            }
                            const newItem = {
                                ...item,
                                descriptions: descriptions
                            }
                            setItem(newItem)
                        }}
                    />
                    <p className="mt-2 text-sm text-gray-500">Write
                        a few sentences about product.</p>
                </div>
            </div>

            <div
                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="about"
                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Description EN
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <InputTextArea
                        error={errors.descriptionEN.error}
                        initialValue={item.descriptions.EN}
                        errorMessage={errors.descriptionEN.message}
                        rows={3}
                        onChange={(e) => {
                            const descriptions = {
                                "PT": item.descriptions.PT,
                                "EN": e
                            }
                            const newItem = {
                                ...item,
                                descriptions: descriptions
                            }
                            setItem(newItem)
                        }}
                    />
                    <p className="mt-2 text-sm text-gray-500">Write
                        a few sentences about product.</p>
                </div>
            </div>
            <div className="space-y-6 sm:space-y-5">
                <div
                    className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="username"
                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Price
                    </label>
                    <div className="mt-1 sm:col-span-1 sm:mt-0">
                        <div
                            className="flex max-w-lg rounded-md shadow-sm">
                            <InputCurrency
                                initialValue={item.fullPrice?.toString() ?? ""}
                                error={errors.price.error}
                                errorMessage={errors.price.message}
                                onChange={(e) => {
                                    const newItem = {
                                        ...item,
                                        fullPrice: (e) ? Number(e) : null
                                    }
                                    setItem(newItem)
                                }}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-y-6 sm:space-y-5">
                <div
                    className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="username"
                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Discount
                    </label>
                    <div className="mt-1 sm:col-span-1 sm:mt-0">
                        <div
                            className="flex max-w-lg rounded-md shadow-sm">
                            <InputCurrency
                                error={errors.discount.error}
                                errorMessage={errors.discount.message}
                                initialValue={item.discount?.toString() ?? ""}
                                onChange={(e) => {
                                    const newItem = {
                                        ...item,
                                        discount: (e) ? Number(e) : 0
                                    }
                                    setItem(newItem)
                                }}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-y-6 sm:space-y-5">
                <div
                    className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="username"
                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Stock
                    </label>
                    <div className="mt-1 sm:col-span-1 sm:mt-0">
                        <div
                            className="max-w-lg rounded-md w-full">
                            <NullableInputNumber
                                error={errors.stock.error}
                                errorMessage={errors.stock.message}
                                initialValue={item.stock}
                                onChange={(e) => {
                                    const newItem = {
                                        ...item,
                                        stock: (e) ? Number(e) : null
                                    }
                                    setItem(newItem)
                                }}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-y-6 sm:space-y-5">
                <div
                    className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="username"
                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Tag
                    </label>
                    <div className="mt-1 sm:col-span-3 sm:mt-0">
                        <div
                            className="max-w-lg rounded-md w-full">
                            <MultiSelect
                                onChange={(e) => {
                                    const newItem = {
                                        ...item,
                                        tags: e
                                    }
                                    setItem(newItem)
                                }}
                                values={fromTagToMultiSelection(item.tags)}
                                options={DEFAULT_TAGS_MULTI_SELECTION}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="sm:grid sm:grid-cols-5 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="photo"
                       className="block text-sm font-medium text-gray-700">
                    Photo
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex items-center">
                        <img className="h-12 w-12 rounded-full"
                             src={item.pictureThumb.url}
                             alt=""/>
                        <button
                            onClick={() => {
                                setCropImgOpen(true)
                            }}
                            type="button"
                            className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Change
                        </button>
                    </div>
                </div>
            </div>
            <div
                className="sm:grid sm:grid-cols-5 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="photo"
                       className="block text-sm font-medium text-gray-700">
                    VAT Applicable
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="max-w-lg">
                        <div className="mt-4 space-y-4">
                            <div className="flex items-center">
                                <input
                                    type="radio"
                                    value="enabled"
                                    onChange={(e) => {
                                        const newItem = {
                                            ...item,
                                            hasTaxes: true
                                        }
                                        setItem(newItem)
                                    }}
                                    checked={item.hasTaxes}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label htmlFor="push-everything"
                                       className="ml-3 block text-sm font-medium text-gray-700">
                                    Yes
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="disabled"
                                    name="disabled"
                                    type="radio"
                                    value="disabled"
                                    onChange={(e) => {
                                        const newItem = {
                                            ...item,
                                            hasTaxes: false
                                        }
                                        setItem(newItem)
                                    }}
                                    checked={!item.hasTaxes}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label htmlFor="push-email"
                                       className="ml-3 block text-sm font-medium text-gray-700">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-6 sm:pt-5 border-t">
                <div role="group" aria-labelledby="label-notifications">
                    <div
                        className="sm:grid sm:grid-cols-5 sm:items-baseline sm:gap-4">
                        <div>
                            <div
                                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                                id="label-notifications"
                            >
                                Enabled
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                    <div className="flex items-center">
                                        <input
                                            id="push-everything"
                                            name="push-notifications"
                                            type="radio"
                                            value="enabled"
                                            onChange={(e) => {
                                                const newItem = {
                                                    ...item,
                                                    enabled: true
                                                }
                                                setItem(newItem)
                                            }}
                                            checked={item.enabled}
                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label htmlFor="push-everything"
                                               className="ml-3 block text-sm font-medium text-gray-700">
                                            Enabled
                                        </label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            id="disabled"
                                            name="disabled"
                                            type="radio"
                                            value="disabled"
                                            onChange={(e) => {
                                                const newItem = {
                                                    ...item,
                                                    enabled: false
                                                }
                                                setItem(newItem)
                                            }}
                                            checked={!item.enabled}
                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label htmlFor="push-email"
                                               className="ml-3 block text-sm font-medium text-gray-700">
                                            Disabled
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        (catalogItem) ? <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform w-full overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-6xl  sm:p-6">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Edit
                                        Product</h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        Edit product details such as title, description,
                                        price and photo.
                                    </p>
                                </div>
                                <form onSubmit={onUpdate}>
                                    <div
                                        className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5">
                                            <div className="relative border-gray-200 pb-5 sm:pb-0">
                                                <div className="mt-10">
                                                    <div className="sm:hidden">
                                                        <label htmlFor="current-tab" className="sr-only">
                                                            Select a tab
                                                        </label>
                                                        <select
                                                            id="current-tab"
                                                            name="current-tab"
                                                            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                            onChange={(e) => {
                                                                setSelectedTab(e.target.value)
                                                            }}>
                                                            {tabs.map((tab) => (
                                                                <option value={tab.href}
                                                                        key={tab.name}>{tab.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="hidden sm:block">
                                                        <nav className="-mb-px flex space-x-8">
                                                            {tabs.map((tab, index) => (
                                                                <a
                                                                    onClick={(e) => {
                                                                        setSelectedTab(tab.href)
                                                                    }}
                                                                    key={tab.name}
                                                                    href={tab.href}
                                                                    className={classNames(
                                                                        selectedTab == tab.href
                                                                            ? 'border-indigo-500 text-indigo-600'
                                                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                                        'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-md'
                                                                    )}
                                                                    aria-current={selectedTab == tab.href ? 'page' : undefined}
                                                                >
                                                                    {tab.name}
                                                                </a>
                                                            ))}
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {getContentTab(selectedTab)}
                                    <div className="pt-5 border-t mt-5">
                                        <div className="flex justify-end">
                                            <button
                                                onClick={onClose}
                                                type="button"
                                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <GenericFileUpload open={cropImgOpen} onClose={() => {
                                    setCropImgOpen(false)
                                }} onCompleted={(uploadResponse) => {
                                    setItem({
                                        ...item,
                                        picture: uploadResponse.media,
                                        pictureThumb: uploadResponse.thumbnail
                                    });
                                }}/>
                                <AddCatalogOption key={"add-catalog"} open={catalogOptionModal} onClose={() => {
                                    setCatalogItemModal(false)
                                    setEditCatalogOption(null)
                                }} onAdd={(i) => {
                                    setCatalogItemModal(false)
                                    let customization = item.customization
                                    if (customization == null) {
                                        customization = {
                                            items: [i]
                                        } as Customization
                                    } else {
                                        customization.items.push(i)
                                    }
                                    setItem({
                                        ...item,
                                        customization: customization
                                    })
                                }}/>
                                {
                                    (editCatalogOption) ? <EditCatalogOption
                                        initialOption={editCatalogOption}
                                        key={editCatalogOption.names.PT}
                                        open={editCatalogOptionModal}
                                        onClose={() => {
                                            setEditCatalogItemModal(false)
                                            setEditCatalogOption(null)
                                        }} onEdit={(i: CatalogOption) => {
                                        setEditCatalogItemModal(false)
                                        let customization = item.customization
                                        if (customization != null) {
                                            customization.items[i.pos] = i
                                        }
                                        setItem({
                                            ...item,
                                            customization: customization
                                        })
                                    }}/> : null
                                }
                                <LoadingOverlay active={overlay}/>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root> : null
    )
}