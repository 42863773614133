import React, {useEffect, useState} from "react";
import Modal from "../../../components/Modal";
import clsx from "clsx";
import {OrderSelectable} from "../data/Order";
import Tab from "../../../common/data/Tab";
import RequestTab from "./delivery/RequestTab";
import OngoingTab from "./delivery/OngoingTab";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { DriverService } from "../../drivers/service/DriverService";
import { DriverServiceDefaultImpl } from "../../drivers/service/impl/DriverServiceDefaultImpl";
import Select, { Value } from "../../../components/Select";
import { SessionHelper } from "../../../common/helper/SessionHelper";

interface RequestDeliveryProps {
    open: boolean

    onClose(): void

    selectedOrders: OrderSelectable[]
}

const initialTabs = [
    {name: 'Request New', current: true, href: '#request-new'},
    {name: 'Ongoing', current: false, href: '#ongoing'},
] as Tab[]

export default function RequestDelivery({open, onClose, selectedOrders}: RequestDeliveryProps) {

    const driverService: DriverService = DriverServiceDefaultImpl.Instance;
    const [drivers, setDrivers] = useState<Value[]>([]);
    const [selectedDriver, setSelectedDriver] = useState<string>("");
    const [selectedTab, setSelectedTab] = useState<string>("#request-new");
    const [tabs, setTabs] = useState<Tab[]>(initialTabs);
    const [overlay, setOverlay] = useState<boolean>(false);
    const isAdmin = SessionHelper.retrieveUserSession()?.isAdminRole() || false;

    useEffect(() => {
        if (isAdmin) {
            fetchDrivers()
        }
    }, []);

    function getTabs() {
        if (selectedTab == "#request-new") {
            return <RequestTab key={"request-tab"} initialSelectedOrders={selectedOrders} selectedDriver={selectedDriver} onClose={() => {
                onClose()
            }}/>
        } else if (selectedTab == "#ongoing") {
            return <OngoingTab/>
        } else {
            return <></>
        }
    }

    async function fetchDrivers() {
        setOverlay(true)
        const drivers = await driverService.findAllDrivers(["ONLINE"]);
        const emptyFilter = {id: "", name: "Let the system choose"} as Value
        const values = drivers.content.map((s) => {
                return {id: s.id, name: `${s.firstName} ${s.lastName}`} as Value
        });
        const sorted = values.sort((a, b) => a.name.localeCompare(b.name));
        sorted.unshift(emptyFilter);
        setDrivers(sorted);
        setOverlay(false)
    }

    return (
        <Modal open={open} onClose={() => {
            onClose()
        }}>
            <div className="py-6 px-4 sm:p-6 lg:pb-8">
                <div>
                    <h2 className="text-lg font-medium leading-6 text-gray-900">Request Conecta Driver</h2>
                    <p className="mt-1 text-sm text-gray-500">
                        Select orders to request a driver.
                    </p>
                </div>
                {isAdmin && <div className="pt-5 border border-0 border-t">
                    <div>
                        You may select an specific
                    </div>
                    <div className="flex justify-end">
                        <Select onChange={(e: string) => {
                                setSelectedDriver(e);
                            }} values={drivers} initialValue={
                                {id: "", name: "Let the system choose"}
                            }/>
                    </div>
                </div>}
                {/*<div>*/}
                {/*    <div className="sm:hidden">*/}
                {/*        <label htmlFor="tabs" className="sr-only">*/}
                {/*            Select a tab*/}
                {/*        </label>*/}
                {/*        /!* Use an "onChange" listener to redirect the user to the selected tab URL. *!/*/}
                {/*        <select*/}
                {/*            id="tabs"*/}
                {/*            name="tabs"*/}
                {/*            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"*/}
                {/*            defaultValue={tabs.find((tab) => tab.current)?.name}*/}
                {/*        >*/}
                {/*            {tabs.map((tab) => (*/}
                {/*                <option key={tab.name}>{tab.name}</option>*/}
                {/*            ))}*/}
                {/*        </select>*/}
                {/*    </div>*/}
                {/*    <div className="hidden sm:block">*/}
                {/*        <div className="border-b border-gray-200">*/}
                {/*            <nav className="-mb-px flex" aria-label="Tabs">*/}
                {/*                {tabs.map((t) => (*/}
                {/*                    <a onClick={() => {*/}
                {/*                        setSelectedTab(t.href)*/}
                {/*                    }}*/}
                {/*                       key={t.name}*/}
                {/*                       href={t.href}*/}
                {/*                       className={clsx(*/}
                {/*                           t.href == selectedTab*/}
                {/*                               ? 'border-indigo-500 text-indigo-600'*/}
                {/*                               : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',*/}
                {/*                           'w-1/2 border-b-2 py-4 px-1 text-center text-sm font-medium'*/}
                {/*                       )}*/}
                {/*                       aria-current={t.href == selectedTab ? 'page' : undefined}>*/}
                {/*                        {t.name}*/}
                {/*                    </a>*/}
                {/*                ))}*/}
                {/*            </nav>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {getTabs()}
            </div>
            <LoadingOverlay active={overlay}/>
        </Modal>
    )
}