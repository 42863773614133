import {OrderStatus} from "./OrderStatus";
import User from "./User";
import Address from "./Address";
import OrderItem from "./OrderItem";
import Coupon from "./Coupon";
import {IBusiness} from "../../business/data/Business";
import TaskV2 from "../../tasks/data/TaskV2";

export interface Order {
    readonly id: string,
    readonly orderNumber: number,
    readonly businessId: string,
    readonly business: SimpleBusiness,
    readonly userId: string,
    readonly type: OrderType,
    readonly total: number,
    readonly discount: number,
    readonly totalBeforeDiscount: number,
    readonly subTotal: number,
    readonly deliveryPrice: number,
    readonly instructions: string,
    readonly created: Date,
    readonly updated: Date,
    readonly status: OrderStatus,
    readonly expectedCompletionTime: ExpectedCompletionTime,
    readonly expectedPreparationTime: ExpectedCompletionTime,
    readonly user: User
    readonly driver: User
    readonly paymentMethod: String,
    readonly deliveryType: String,
    readonly scheduleType: String
    readonly deliveryAddress: Address,
    readonly businessAddress: Address,
    readonly items: OrderItem[],
    readonly coupon?: Coupon,
    readonly deliveryTask?: TaskV2,
}

export interface OrderSelectable extends Order {
    selected: boolean
    position: number
}

export enum OrderType {
    PICKUP = "PICKUP",
    DELIVERY = "DELIVERY"
}

interface ExpectedCompletionTime {
    readonly start: Date,
    readonly end: Date
}

interface SimpleBusiness {
    readonly name: string
}