import React, {useEffect} from "react";

export default function Category() {

    useEffect(() => {
        document.body.style.backgroundColor = "#f5f5f5";
    }, [])

    return (
        <>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">What category do you identify your company?</h2>
                    <p className="mt-3 text-center text-gray-500">You can select up to 2 categories</p>
                </div>

                <div className="mt-8 mx-auto w-full max-w-sm md:max-w-xl">
                    <div className="bg-white py-8 px-8 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-4" action="#" method="POST">
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                <div className="relative flex items-center space-x-3 py-3 px-4 text-sm border-solid border rounded-md border-gray-200 shadow-sm">
                                    <div className="flex-shrink-0">
                                        <img src="https://static.conecta55.com/subcategory/icon/mechanic.png" alt="food" className="w-8 h-8 mx-auto"/>
                                    </div>
                                    <div className="flex-1">
                                        <p className="font-semibold">Automotive</p>
                                    </div>
                                </div>
                                <div className="relative flex items-center space-x-3 py-3 px-4 text-sm border-solid border rounded-md border-gray-200 shadow-sm">
                                    <div className="flex-shrink-0">
                                        <img src="https://static.conecta55.com/subcategory/icon/motorcycles.png" alt="food" className="w-8 h-8 mx-auto"/>
                                    </div>
                                    <div className="flex-1">
                                        <p className="font-semibold">Motorcycles and bikes</p>
                                    </div>
                                </div>
                                <div className="relative flex items-center space-x-3 py-3 px-4 text-sm border-solid border rounded-md border-gray-200 shadow-sm">
                                    <div className="flex-shrink-0">
                                        <img src="https://static.conecta55.com/subcategory/icon/hair_beauty.png" alt="food" className="w-8 h-8 mx-auto"/>
                                    </div>
                                    <div className="flex-1">
                                        <p className="font-semibold">Hair Style</p>
                                    </div>
                                </div>
                                <div className="relative flex items-center space-x-3 py-3 px-4 text-sm border-solid border rounded-md border-gray-200 shadow-sm">
                                    <div className="flex-shrink-0">
                                        <img src="https://static.conecta55.com/subcategory/icon/mechanic.png" alt="food" className="w-8 h-8 mx-auto"/>
                                    </div>
                                    <div className="flex-1">
                                        <p className="font-semibold">Automotive</p>
                                    </div>
                                </div>
                                <div className="relative flex items-center space-x-3 py-3 px-4 text-sm border-solid border rounded-md border-gray-200 shadow-sm">
                                    <div className="flex-shrink-0">
                                        <img src="https://static.conecta55.com/subcategory/icon/motorcycles.png" alt="food" className="w-8 h-8 mx-auto"/>
                                    </div>
                                    <div className="flex-1">
                                        <p className="font-semibold">Motorcycles and bikes</p>
                                    </div>
                                </div>
                                <div className="relative flex items-center space-x-3 py-3 px-4 text-sm border-solid border rounded-md border-gray-200 shadow-sm">
                                    <div className="flex-shrink-0">
                                        <img src="https://static.conecta55.com/subcategory/icon/hair_beauty.png" alt="food" className="w-8 h-8 mx-auto"/>
                                    </div>
                                    <div className="flex-1">
                                        <p className="font-semibold">Hair Style</p>
                                    </div>
                                </div>
                                <div className="relative flex items-center space-x-3 py-3 px-4 text-sm border-solid border rounded-md border-gray-200 shadow-sm">
                                    <div className="flex-shrink-0">
                                        <img src="https://static.conecta55.com/subcategory/icon/mechanic.png" alt="food" className="w-8 h-8 mx-auto"/>
                                    </div>
                                    <div className="flex-1">
                                        <p className="font-semibold">Automotive</p>
                                    </div>
                                </div>
                                <div className="relative flex items-center space-x-3 py-3 px-4 text-sm border-solid border rounded-md border-gray-200 shadow-sm">
                                    <div className="flex-shrink-0">
                                        <img src="https://static.conecta55.com/subcategory/icon/motorcycles.png" alt="food" className="w-8 h-8 mx-auto"/>
                                    </div>
                                    <div className="flex-1">
                                        <p className="font-semibold">Motorcycles and bikes</p>
                                    </div>
                                </div>
                                <div className="relative flex items-center space-x-3 py-3 px-4 text-sm border-solid border rounded-md border-gray-200 shadow-sm">
                                    <div className="flex-shrink-0">
                                        <img src="https://static.conecta55.com/subcategory/icon/hair_beauty.png" alt="food" className="w-8 h-8 mx-auto"/>
                                    </div>
                                    <div className="flex-1">
                                        <p className="font-semibold">Hair Style</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full mt-12 justify-center rounded-md border border-transparent bg-indigo-600 py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Next
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}