import React, {Fragment, useEffect, useState} from "react";
import {Menu, Transition} from "@headlessui/react";
import LoadingOverlay from "../../../components/LoadingOverlay";
import {ChevronDownIcon, PencilAltIcon, TrashIcon} from "@heroicons/react/solid";
import classNames from "../../../common/helper/CssHelper";
import Push, {PushStatus} from "../data/Push";
import {Pagination as PaginationData} from "../../../common/data/Pagination";
import PushServiceDefaultImpl from "../service/impl/PushServiceDefaultImpl";
import PushService from "../service/PushService";
import Moment from "react-moment";
import PushStatusComp from "./PushStatus";
import EditOrAddPush from "./EditOrAddPush";
import AlertConfirmation from "../../../components/AlertConfirmation";
import {toast} from "react-toastify";
import Pagination from "../../../components/Pagination";

export default function Pushs() {

    const pushService: PushService = PushServiceDefaultImpl.Instance;

    const [page, setPage] = useState<number>(0);
    const [overlay, setOverlay] = useState<boolean>(false);

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState<boolean>(false);
    const [addOrEditModal, setAddOrEditModal] = useState<boolean>(false);

    const [editPush, setEditPush] = useState<Push | null>(null);
    const [pushs, setPushs] = useState<PaginationData<Push[]>>({} as PaginationData<Push[]>);

    const pageSize = 10;

    useEffect(() => {
        findAll();
    }, [])

    useEffect(() => {
        if (editPush)
            setAddOrEditModal(true)
    }, [editPush])

    useEffect(() => {
        setOverlay(false)
    }, [pushs])

    useEffect(() => {
        findAll()
    },[page])

    function findAll(): void {
        setOverlay(true)
        pushService.findAll(page, pageSize).then((pushs) => {
            setPushs(pushs);
        })
    }

    function deletePush(push: Push) {
        setOverlay(true)
        pushService.delete(push.id).then(() => {
            toast("Push has been successfully deleted")
            setDeleteConfirmationModal(false)
            findAll()
        })
    }

    function getDeleteMenu(i: Push) {
        return <Menu.Item>
            {({active}) => (
                <a onClick={(e) => {
                    e.preventDefault();
                    setDeleteConfirmationModal(true)
                }}
                   href="#"
                   className={classNames(
                       active ? 'bg-red-100 text-red-700' : 'text-red-700',
                       'group flex items-center px-4 py-2 text-sm'
                   )}
                >
                    <TrashIcon
                        className="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
                        aria-hidden="true"
                    />
                    Delete Item
                </a>
            )}
        </Menu.Item>
    }

    function getEditMenu(i: Push) {
        return <Menu.Item>
            {({active}) => (
                <a onClick={(e) => {
                    e.preventDefault();
                    setEditPush(i)
                }}
                   href="#"
                   className={classNames(
                       active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                       'group flex items-center px-4 py-2 text-sm'
                   )}
                >
                    <PencilAltIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                    />
                    Edit item
                </a>
            )}
        </Menu.Item>
    }

    function getDeleteConfirmation(push: Push) {
        return <AlertConfirmation
            title="Do you confirm this operation"
            description="Are you sure you want to delete this item"
            open={deleteConfirmationModal}
            onConfirm={() => {
                deletePush(push)
            }}
            onClose={() => {
                setDeleteConfirmationModal(false)
            }}/>
    }

    return <>
        <div className="py-6 sm:px-6 lg:px-8 px-4">
            <LoadingOverlay active={overlay}/>
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Push notifications</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        The list of all push notifications.
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            setAddOrEditModal(true)
                        }}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Create new push
                    </button>
                </div>
            </div>
            <div
                className="shadow ring-1 ring-black md:overflow-visible overflow-x-auto ring-opacity-5 md:rounded-lg mt-5">
                <table className="min-w-full z-0 divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                        <th scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Title
                        </th>

                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Description
                        </th>
                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Status
                        </th>
                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Date
                        </th>
                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Action
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {
                        (pushs && pushs.content) ? pushs.content.map((i) => {
                            return <tr>
                                <td className="py-4 pl-4 pr-3 text-sm sm:pl-6">
                                    <div className="flex items-center">
                                        <div>
                                            <div className="font-bold text-gray-900">
                                                <p>{i.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td className="px-3 py-4 text-sm">
                                    <p>{i.message}</p>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                    <PushStatusComp status={i.status}/>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                    <Moment date={i.time} format={'MMMM Do YYYY, h:mm:ss a'}/>
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                            <Menu.Button
                                                className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                                <ChevronDownIcon className="h-4 w-4"
                                                                 aria-hidden="true"/>
                                            </Menu.Button>
                                        </div>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    {getEditMenu(i)}
                                                    {(i.status && i.status === PushStatus.PENDING) ? getDeleteMenu(i) : <></>}
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    {getDeleteConfirmation(i)}
                                </td>
                            </tr>
                        }) : null
                    }
                    </tbody>
                </table>
                {
                    (pushs && pushs.totalPages) ?
                        <Pagination totalPages={pushs.totalPages} currentPage={page}
                                    paginationControl={(page) => {
                                        console.log(page)
                                        setPage(page)
                                    }}
                        /> : <></>
                }
            </div>

            <EditOrAddPush key={editPush?.id} open={addOrEditModal} editPush={editPush}
                           onClose={() => {
                               setAddOrEditModal(false);
                               setEditPush(null);
                           }} onChange={(push: Push) => {
                findAll();
            }}/>
        </div>
    </>
}