import React, {Fragment, useEffect, useState} from "react";
import {Menu, Transition} from "@headlessui/react";
import Select, {Value} from "../../../components/Select";
import CatalogService from "../service/CatalogService";
import CatalogServiceDefaultImpl from "../service/impl/CatalogServiceDefaultImpl";
import CatalogItem from "../data/CatalogItem";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Moment from "react-moment";
import {CheckCircleIcon, ChevronDownIcon, PencilAltIcon, XCircleIcon} from "@heroicons/react/solid";
import classNames from "../../../common/helper/CssHelper";
import {toast} from "react-toastify";
import EmptyState from "../../../components/EmptyState";
import AddCatalogItem from "./AddCatalogItem";
import EditCatalogItem from "./EditCatalogItem";
import {Pagination as PaginationData} from "../../../common/data/Pagination";
import Pagination from "../../../components/Pagination";


export default function Catalog() {

    const catalogService: CatalogService = CatalogServiceDefaultImpl.Instance;

    const [sections, setSections] = useState<Value[]>([]);

    const [items, setItems] = useState<PaginationData<CatalogItem[]>>({} as PaginationData<CatalogItem[]>);
    const [selectedItem, setSelectedItem] = useState<CatalogItem | null>(null);

    const [sectionFilter, setSectionFilter] = useState<string | undefined>(undefined);
    const [enabledFilter, setEnabledFilter] = useState<string | undefined>(undefined);

    const [overlay, setOverlay] = useState<boolean>(true);

    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);

    const [page, setPage] = useState<number>(0);
    const pageSize = 20;

    const enabledFilterOptions = [
        {id: "", name: "All"},
        {id: "true", name: "Enabled"},
        {id: "false", name: "Disabled"}
    ] as Value[]

    useEffect(() => {
        catalogService.findSections().then((sections) => {
            const emptyFilter = {id: "", name: "All"} as Value
            const values = [emptyFilter]
            sections.map((s) => {
                values.push({id: s.id, name: s.names.EN} as Value)
            });
            setSections(values)
            setSectionFilter("")
        })
    }, [])

    useEffect(() => {
        setOverlay(true)
        fetchItems().then((items) => {
            setItems(items);
            setOverlay(false)
        })
    }, [sectionFilter, enabledFilter, page])

    useEffect(() => {
        setOverlay(true)
        fetchItems().then((items) => {
            setItems(items);
            setOverlay(false)
        })
    }, [sectionFilter, enabledFilter, page])

    async function fetchItems(): Promise<PaginationData<CatalogItem[]>> {
        console.log(enabledFilter)
        return await catalogService.findItems(
            page,
            pageSize,
            (sectionFilter == "") ? undefined : sectionFilter,
            (enabledFilter == "" || enabledFilter == undefined) ? undefined : (enabledFilter == "true")
        )
    }

    function enableDisableItem(item: CatalogItem, enable: boolean) {
        setOverlay(true)
        const copyItem = {...item, enabled: enable}
        catalogService.updateItem(copyItem).then((e) => {
            fetchItems().then((items) => {
                setItems(items);
                setOverlay(false)
                toast("Item has been successfully updated");
            })
        })
    }

    return <>
        <div className="py-6 sm:px-6 lg:px-8 px-4">
            <LoadingOverlay active={overlay}/>
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Catalog list</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        The list of catalog items.
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            setOpenCreate(true)
                        }}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Add new item
                    </button>
                </div>
            </div>
            <div
                className="flex gap-4 items-center py-4 px-2 border-b border-t border-gray-200 mt-5">
                <div className="flex-initial w-64 gap-4 shrink">
                    <label className="text-sm">Section</label>
                    {
                        sections && sections.length > 0
                            ? <Select onChange={(e: string) => {
                                setPage(0)
                                setSectionFilter(e);
                            }} values={sections}/>
                            : null
                    }
                </div>
                <div className="flex-initial w-64 gap-4 shrink">
                    <label className="text-sm">Active</label>
                    <Select onChange={(e: string) => {
                        setPage(0)
                        setEnabledFilter(e)
                    }} values={enabledFilterOptions}/>
                </div>
            </div>
            <div
                className="shadow ring-1 ring-black md:overflow-visible overflow-x-auto ring-opacity-5 md:rounded-lg mt-5">
                <table className="min-w-full z-0 divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                        <th scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Item
                        </th>

                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Price
                        </th>
                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Status
                        </th>
                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Tags
                        </th>
                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Created
                        </th>
                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Action
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {
                        (items && items.content) ? items.content.map((i) => {
                            return <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                    <div className="flex items-center">
                                        <div className="h-10 w-10 flex-shrink-0">
                                            <img className="h-10 w-10"
                                                 src={i.pictureThumb.url}
                                                 alt=""/>
                                        </div>
                                        <div className="ml-4">
                                            <div className="font-medium max-w-sm text-gray-900">
                                                {
                                                    i.names.PT ?? ''
                                                }
                                                {i.hasTaxes
                                                    ? <strong className="ml-2 text-emerald-600">(Vat Applicable)</strong> : <></>
                                                }
                                            </div>

                                            <div className="text-gray-500">
                                                {(i.descriptions.PT.length > 40) ? `${i.descriptions.PT.substring(0, 40)}...` : i.descriptions.PT}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                    <strong>
                                        {
                                            (i.fullPrice != null) ? `£${i.fullPrice}` : 'No price'
                                        }
                                    </strong>
                                    <div className="text-gray-500">
                                        {(i.stock && i.stock > 0) ? `${i.stock} items in stock` : "no stock"}
                                    </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                    <span className="text-indigo-400">
                                        {i.enabled
                                            ? <span
                                                className="inline-flex items-center rounded-full bg-cyan-100 px-2.5 py-0.5 text-xs font-medium text-cyan-800">enabled</span>
                                            : <span
                                                className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium text-red-800">disabled</span>
                                        }
                                    </span>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                    {
                                        i.tags.map((t) => {
                                            return <span
                                                className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 ml-2">
                                                {t.name.toLowerCase()}
                                            </span>
                                        })
                                    }
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                    <Moment date={i.created} fromNow ago/> ago
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                            <Menu.Button
                                                className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                                <ChevronDownIcon className="h-4 w-4"
                                                                 aria-hidden="true"/>
                                            </Menu.Button>
                                        </div>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    <Menu.Item>
                                                        <a
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setSelectedItem(i);
                                                                setOpenEdit(true);
                                                            }}
                                                            className={classNames('text-gray-700',
                                                                'group flex items-center px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            <PencilAltIcon
                                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                            Edit item
                                                        </a>
                                                    </Menu.Item>
                                                    {
                                                        (i.enabled) ? <Menu.Item>
                                                            <a
                                                                href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    enableDisableItem(i, false)
                                                                }}
                                                                className={classNames('text-gray-700',
                                                                    'group flex items-center px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                <XCircleIcon
                                                                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                    aria-hidden="true"
                                                                />
                                                                Disable item
                                                            </a>
                                                        </Menu.Item> : <Menu.Item>
                                                            <a
                                                                href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    enableDisableItem(i, true)
                                                                }}
                                                                className={classNames('text-gray-700',
                                                                    'group flex items-center px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                <CheckCircleIcon
                                                                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                    aria-hidden="true"
                                                                />
                                                                Enable item
                                                            </a>
                                                        </Menu.Item>
                                                    }

                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </td>
                            </tr>
                        }) : null
                    }
                    </tbody>
                </table>
                {
                    (items && items.totalPages) ?
                        <Pagination totalPages={items.totalPages} currentPage={page}
                                    paginationControl={(page) => {
                                        console.log(page)
                                        setPage(page)
                                    }}
                        /> : <></>
                }
                {
                    (selectedItem) ? <EditCatalogItem sections={sections} key={selectedItem.id} onClose={() => {
                        setOpenEdit(false)
                    }} postUpdate={() => {
                        fetchItems().then((items) => {
                            setItems(items);
                        })
                    }} catalogItem={selectedItem} open={openEdit}/> : <></>
                }
                {
                    (sections && sections.length > 0) ?
                        <AddCatalogItem open={openCreate} sections={sections} onClose={() => {
                            setOpenCreate(false)
                        }} postCreation={() => {
                            fetchItems().then((items) => {
                                setItems(items);
                            })
                        }}/> : null
                }
                {
                    (items && items.content && items.content.length == 0) ?
                        <div className="flex w-full p-10">
                            <EmptyState description="Sorry, no content to be shown"
                                        subDescription="No catalog items were found."/>
                        </div> : null
                }
            </div>
        </div>
    </>
}