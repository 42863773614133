import { API } from "../../../common/const/Routes";
import { Pagination } from "../../../common/data/Pagination";
import { httpHelper } from "../../../common/helper/HttpCommon";
import Earning from "../data/Earning";
import EarningService from "./EarningService";

export default class EarningServiceImpl implements EarningService {

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private static _instance: EarningServiceImpl;
    
    async findAll(page: number, size: number): Promise<Pagination<Earning[]>> {
        const params = new URLSearchParams();
        params.append("size", size.toString());
        params.append("page", page.toString());
        return await httpHelper.get<Pagination<Earning[]>>(API.EARNINGS, params);
    }

}