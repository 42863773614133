import {UserSession} from "../data/UserSession";
import jwt_decode from "jwt-decode";
import {IPartialBusiness} from "../../core/business/data/PartialBusiness";
import UserService from "../../core/settings/component/section/driver/service/UserService";
import UserServiceImpl
    from "../../core/settings/component/section/driver/service/impl/UserServiceImpl";
import {BusinessServiceImpl} from "../../core/business/service/impl/BusinessServiceImpl";
import {MarketplaceProfileServiceImpl} from "../../core/settings/service/impl/MarketplaceProfileServiceImpl";

export class SessionHelper {

    static userService: UserService = UserServiceImpl.Instance;
    static marketplaceProfileService = MarketplaceProfileServiceImpl.Instance;

    static async setUserSession(jwt: string): Promise<UserSession> {
        const user = UserSession.fromJWT(jwt_decode(jwt))
        user.user = await this.userService.findUserById()
        if (user.isAdminRole()) {
            user.businessIds = []
        }
        localStorage.setItem("userSession", JSON.stringify(user));
        this.marketplaceProfileService.find().then((marketplaceProfile) => {
            localStorage.setItem("printerName", (marketplaceProfile.printerName ?? "zebra"));
        })
        return user
    }

    static retrieveUserSession(): UserSession | null {

        const userSession = localStorage.getItem("userSession")

        if (userSession != null) {
            return UserSession.from(JSON.parse(userSession))
        }

        return null
    }

    static wipeUserSession(): void {
        localStorage.removeItem("userSession")
    }

    static setBusiness(business: IPartialBusiness[]): void {
        const user = this.retrieveUserSession()
        if(user != null) {
            
            user.businessIds = business.map(b => b.id)
            user.businessInfos = business

            user.businessId = user.businessIds.at(0)!!
            user.businessInfo = user.businessInfos!!.at(0)!!
           
            localStorage.setItem("userSession", JSON.stringify(user));
        }
    }

    static setCurrentBusiness(business: IPartialBusiness): void {
        const user = this.retrieveUserSession()
        if(user != null) {
            
            user.businessId = business.id
            user.businessInfo = business
           
            localStorage.setItem("userSession", JSON.stringify(user));
        }
    }
}