import { BusinessService } from "../../../core/business/service/BusinessService";
import { BusinessServiceImpl } from "../../../core/business/service/impl/BusinessServiceImpl";
import React, { useState } from "react";
import { FormatOptionLabelMeta, GroupBase, MultiValueGenericProps, components } from "react-select";
import AsyncSelect from "react-select/async";
import { IPartialBusiness } from "../../../core/business/data/PartialBusiness";
import { SessionHelper } from "../../../common/helper/SessionHelper";

interface BusinessSelectProps {
    onChange(business: IPartialBusiness): void
    defaultBusiness?: IPartialBusiness[] | null,
    isMulti: boolean,
    error?: boolean,
    errorMessage?: string,
}

export default function BusinessSelect({ onChange, defaultBusiness = null, error = false, errorMessage = "", isMulti}: BusinessSelectProps) {
    const reactSelectStyle = {
        control: (provided: any, state: any) => ({
            ...provided,
            border: "none",
            padding: "5px 0",
            boxShadow: "none",
            borderColor: (error) ? "red" : "#ccc"
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? "rgba(189,197,209,.4)" : "white",
        }),
        indicatorSeparator: (provided: any, state: any) => ({
            border: "none"
        })
    };

    const businessService: BusinessService = BusinessServiceImpl.Instance

    function loadOptions(inputValue: string, callback: any) {
        businessService.findAll(inputValue, "", "", null, null, 10, 0).then((resp) => {
            callback(resp.content)
        })
    }

    function noOptionsMessage(term: { inputValue: string }) {
        return term.inputValue.length > 3 ? (
            <span>No options</span>
        ) : (
            <span>Search input must be at least 3 characters</span>
        );
    }

    function formatOptionLabel(
        business: any, formatOptionLabelMeta: FormatOptionLabelMeta<any>) {

        return <>
            <div className="flex items-center">
                <div className="h-8 w-8 m-0.5 flex-shrink-0">
                    <img className="h-8 w-8 rounded-full"
                        src={business.logoThumbnail.url}
                        alt={business.logoThumbnail.alt} />
                </div>
                <div className="ml-1">
                    <div
                        className="font-medium w-20 text-ellipsis overflow-hidden ... text-xs text-gray-900">{business.name}</div>
                </div>
            </div>
        </>
    }

    const MultiValueLabel = (props: MultiValueGenericProps<IPartialBusiness, true, GroupBase<IPartialBusiness>>) => {
        return (
            <div onClick={(e) => handleMultiValueClick(e, props)}>
                <components.MultiValueLabel {...props} />
            </div>
        );
    };

    function handleMultiValueClick(e: any, props: MultiValueGenericProps<IPartialBusiness, true, GroupBase<IPartialBusiness>>) {
        var business = props.data
        SessionHelper.setCurrentBusiness(business);
        window.location.reload();
    }

    function onChangeValue(business: any) {
        onChange(business)
    }

    return <>
        <div className="w-full">
            {isMulti ? <AsyncSelect
                className="z-15 rounded"
                noOptionsMessage={noOptionsMessage}
                loadOptions={loadOptions} formatOptionLabel={formatOptionLabel}
                styles={reactSelectStyle}
                isMulti
                placeholder={"Select businesses.."}
                getOptionValue={(option: any) => {
                    return option.id;
                }}
                onChange={onChangeValue}
                defaultValue={defaultBusiness}
                components={{ MultiValueLabel }}
            /> : <AsyncSelect
                className="z-15 rounded"
                noOptionsMessage={noOptionsMessage}
                loadOptions={loadOptions} formatOptionLabel={formatOptionLabel}
                styles={reactSelectStyle}
                getOptionValue={(option: any) => {
                    return option.id;
                }}
                placeholder={"select business.."}
                onChange={onChangeValue}
                defaultValue={defaultBusiness}
                components={{ MultiValueLabel }}
            />}
            {
                (error && errorMessage != "") ? <p className="mt-2 text-sm text-red-600">
                    {errorMessage}
                </p> : <></>
            }
        </div>
    </>
}