import clsx from "clsx";
import React, {useEffect, useState} from "react";
import {Pagination as PaginationData} from "../../../../common/data/Pagination";
import TaskService from "../../../tasks/service/TaskService";
import TaskServiceImpl from "../../../tasks/service/TaskServiceImpl";
import TaskV2, {TaskStatus} from "../../../tasks/data/TaskV2";
import LoadingOverlay from "../../../../components/LoadingOverlay";

interface OngoingTabProps {

}

export default function OngoingTab() {

    const taskService: TaskService = TaskServiceImpl.Instance;
    const [tasks, setTasks] = useState<PaginationData<TaskV2[]>>({} as PaginationData<TaskV2[]>);
    const [overlay, setOverlay] = useState<boolean>(false);

    useEffect(() => {
        taskService.findAllV2(0, 50, [TaskStatus.CREATED,TaskStatus.SCHEDULED, TaskStatus.WAITING_ASSIGNMENT]).then((tasks) => {
            setTasks(tasks)
        })
    },[])

    return <>
        <div className="h-96 overflow-y-scroll border-t">
            <table className="min-w-full border-l divide-y divide-gray-300">
                <tbody className="divide-y divide-gray-200 bg-white">
                {tasks?.content ? tasks?.content.map((t) => (
                    <tr onClick={() => {
                        //onHandleSelectOrder(o)
                    }} key={t.id}>
                        <td className="whitespace-nowrap pl-4 pr-3 text-sm sm:pl-6">
                            <strong className="text-blue">
                                {t.status}
                            </strong>
                        </td>
                        <td className="whitespace-nowrap text-sm">
                            <div className="flex items-center">
                                <div className="ml-4">
                                    <div
                                        className="text-gray-500">{t.distance}</div>
                                </div>
                            </div>
                        </td>
                        <td className="whitespace-nowrap px-1 py-4 text-sm">
                            {
                                (t.routes) ? t.routes.map((r) => {
                                    return (r.orders) ? r.orders.map((o) => {
                                        return <a>{o.orderNumber}</a>
                                    }) : null
                                }) : null
                            }
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm ">
                            <strong className="text-blue">
                                {t.status}
                            </strong>
                        </td>
                        <td>
                            <button onClick={() => {

                            }} type="button"
                                    className={
                                        clsx(
                                            "bg-indigo-500 hover:bg-indigo-500", "rounded-full", "block", "px-2.5", "py-1", "text-xs", "font-semibold", "text-white", " shadow-sm", "focus-visible:outline", "focus-visible:outline-2", "focus-visible:outline-offset-2"
                                        )
                                    }>
                                test
                            </button>
                        </td>
                    </tr>
                )) :  <></>}
                </tbody>
            </table>
            <LoadingOverlay active={overlay}/>
        </div>
    </>
}
