import {PushStatus as Status} from "../data/Push";
import clsx from "clsx";

interface PushStatusProps {
    status: Status
}

export default function PushStatus({status}: PushStatusProps) {
    return <>
        <span className={clsx((status === Status.PENDING) ? "bg-emerald-100 text-emerald-800" : "bg-indigo-100 text-indigo-800", "inline-flex","items-center","rounded-full","px-2.5","py-0.5","text-xs","font-medium")}>
                            <svg className={clsx((status === Status.PENDING) ? 'text-emerald-400' : 'text-indigo-400', "-ml-0.5 mr-1.5 h-2 w-2")}
                                 fill="currentColor" viewBox="0 0 8 8">
                              <circle cx={4} cy={4} r={3}/>
                            </svg>
            {status.toLocaleLowerCase()}
                        </span>
    </>
}