import { useEffect, useState } from "react";
import TaskService from "./service/TaskService";
import TaskServiceImpl from "./service/TaskServiceImpl";
import Task from "./data/Task";
import { Pagination as PaginationData } from "../../common/data/Pagination";
import DataTable from 'react-data-table-component';
import moment from "moment";
import TaskV2, { RouteType } from "./data/TaskV2";

export default function Tasks() {

    const taskService: TaskService = TaskServiceImpl.Instance;
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [currentStatus, setCurrentStatus] = useState<string>();
    const [overlay, setOverlay] = useState<boolean>(false);
    const [tasks, setTasks] = useState<PaginationData<TaskV2[]>>({} as PaginationData<TaskV2[]>);
    const [status] = useState(["SCHEDULED", "CREATED", "ASSIGNED", "WAITING_ASSIGNMENT", "COLLECTED", "DELIVERED", "CANCELLED"]);

    useEffect(() => {
        findAll(page, pageSize,currentStatus);
    }, [])

    function findAll(page: number, pageSize: number, status: string | undefined): void {
        setOverlay(true)
        taskService.findAllV2(page - 1, pageSize, (status) ? [status] : undefined).then((tasks) => {
            setTasks(tasks);
            setOverlay(false);
        })
    }

    
const columns = [
    {
        name: 'Business',
        selector: (row: TaskV2) => row.routes.flatMap(i => i.pickup?.business).filter(i => i != null)
        .map(i => i.name)
        .join(","),
    },
    {
        name: 'Status',
        selector: (row: TaskV2) => row.status,
    },
    {
        name: 'Orders',
        selector: (row: TaskV2) => row.routes.flatMap(i => i.pickup?.orders).filter(i => i != null)
            .map(i => i.orderNumber)
            .join(",")
    },
    {
        name: 'Pickups',
        selector: (row: TaskV2) => row.routes.flatMap(i => i.pickup?.address).filter(i => i != null)
            .map(i => i.postalCode)
            .join(",")
    },
    {
        name: 'Deliveries',
        selector: (row: TaskV2) => row.routes.flatMap(i => i.delivery?.address).filter(i => i != null)
            .map(i => i.postalCode)
            .join(",")
    },
    {
        name: 'Delivery Day',
        selector: (row: TaskV2) => moment(row.expectedDeliveryTime.start).format("YYYY-MM-DD")
    },
    {
        name: 'Expected Delivery',
        selector: (row: TaskV2) => moment(row.expectedDeliveryTime.start).format("HH:mm") + " - " +
        moment(row.expectedDeliveryTime.end).format("HH:mm")
    },
    // {
    //     name: 'Pickup',
    //     selector: (row: Task) => row.pickupAddress.postalCode,
    // },
    // {
    //     name: 'Delivery',
    //     selector: (row: Task) => row.deliveryAddress.postalCode,
    // },
    {
        name: 'Price',
        selector: (row: TaskV2) => row.deliveryPrice.driver,
    }
];


    const handlePerRowsChange =  (pageSize: number, page: number) => {
        setPageSize(pageSize);
        findAll(page, pageSize, currentStatus);
	};

    const handlePageChange = (page: number) => {
        setPage(page);
        findAll(page, pageSize, currentStatus);
	};

    return (
        <DataTable
			columns={columns}
			data={tasks.content}
			progressPending={overlay}
			pagination
			paginationServer
			paginationTotalRows={tasks.totalElements}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
		/>
    );
}