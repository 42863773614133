import {MarketplaceProfileService} from "../MarketplaceProfileService";
import MarketplaceProfile from "../../data/MarketplaceProfile";
import {httpHelper} from "../../../../common/helper/HttpCommon";
import {API} from "../../../../common/const/Routes";

export class MarketplaceProfileServiceImpl implements MarketplaceProfileService {

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private static _instance: MarketplaceProfileServiceImpl

    async find(): Promise<MarketplaceProfile> {
        return await httpHelper.get<MarketplaceProfile>(API.MARKETPLACE_PROFILE);
    }

    async update(marketPlaceProfile: MarketplaceProfile): Promise<MarketplaceProfile> {
        return await httpHelper.put<MarketplaceProfile>(API.MARKETPLACE_PROFILE, null, marketPlaceProfile);
    }

    async create(marketPlaceProfile: MarketplaceProfile): Promise<MarketplaceProfile> {
        return await httpHelper.post<MarketplaceProfile>(API.ACTIVATE_PROFILE, null, marketPlaceProfile);
    }

}