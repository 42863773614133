import {IPartialBusiness} from "../../core/business/data/PartialBusiness";
import User from "./User";

export default interface IUserSession {
    readonly userId: string,
    readonly sessionId: string,
    readonly role: string,
    readonly businessId: string,
    readonly businessIds?: string[],
    readonly businessInfo?: IPartialBusiness
    readonly businessInfos?: IPartialBusiness[]
    readonly user?: User
}


export class UserSession implements IUserSession {

    static fromJWT(jwt: any): UserSession {
        return new UserSession(jwt["user-id"], jwt["session-id"], jwt["role"], jwt["business-id"], jwt["business-ids"])
    }

    static from(user: IUserSession): UserSession {
        return new UserSession(user.userId, user.sessionId, user.role, user.businessId, user.businessIds, user.businessInfo,user.businessInfos,  user.user)
    }

    readonly userId: string
    readonly sessionId: string
    readonly role: string
    businessIds?: string[]
    businessId: string
    businessInfo?: IPartialBusiness
    businessInfos?: IPartialBusiness[]
    user?: User

    constructor(userId: string, sessionId: string, role: string, businessId: string, businessIds?: string[], businessInfo?: IPartialBusiness,
        businessInfos?: IPartialBusiness[], user?: User) {
        this.userId = userId
        this.sessionId = sessionId
        this.role = role
        this.businessId = businessId
        this.businessIds = businessIds
        this.businessInfo = businessInfo
        this.businessInfos = businessInfos
        this.user = user
    }

    isBusinessRole(): boolean {
        return this.role === "business"
    }

    isAdminRole(): boolean {
        return this.role === "admin"
    }

    isDriverRole(): boolean {
        return this.role === "driver"
    }

    isMultipleBusinesses(): boolean {
        return this.businessIds != undefined && this.businessIds.length > 1
    }
}