import React from "react";
import {SessionHelper} from "../../common/helper/SessionHelper";
import BusinessSelect from "../../components/select/dynamic/BusinessSelect";

export default function AdminPerspective() {

    function onChange(business: any) {
        SessionHelper.setBusiness(business)
        window.location.reload();
    }

    return <>
        <BusinessSelect onChange={onChange}
                        isMulti={true}
                        defaultBusiness={SessionHelper.retrieveUserSession()?.businessInfos ?? null}/>

    </>
}