import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useEffect, useState} from "react";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import InputText from "../../../../../components/InputText";
import {toast} from "react-toastify";
import UserService from "./service/UserService";
import UserServiceImpl from "./service/impl/UserServiceImpl";
import error = toast.error;

interface AddCatalogItemProps {
    open: boolean,
    onClose(): void,
    postCreation(): void
}

export default function AddDriver({
                                           open, onClose, postCreation
                                       }: AddCatalogItemProps) {

    const [email, setEmail] = useState<String>("");
    const [overlay, setOverlay] = useState(false)
    const userService: UserService = UserServiceImpl.Instance;


    useEffect(() => {
    }, [open])

    function validate(): boolean {

        let hasError = false;
        return hasError
    }

    function onCreate(e: any) {

        e.preventDefault()

        if (validate())
            return;

        setOverlay(true)

        userService.addDriverRole(email).then((item) => {
            setOverlay(false)
            onClose();
            postCreation();
            toast("Driver has been successfully added")
        }).catch(e => {
            setOverlay(false)
            onClose();
            postCreation();
            error("Failed to add driver, please check driver email")
        })
    }


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {
                onClose()
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform w-full overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-6xl  sm:p-6">
                                <form onSubmit={onCreate}
                                      className="space-y-8 divide-y divide-gray-200">
                                    <div
                                        className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5">
                                            <div>
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Add Driver</h3>
                                            </div>

                                            <div className="space-y-6 sm:space-y-5">
                                                <div
                                                    className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                    <label htmlFor="about"
                                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Email
                                                    </label>
                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                        <InputText
                                                            onChange={(e: String) => {
                                                                setEmail(e)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pt-5">
                                        <div className="flex justify-end">
                                            <button
                                                onClick={onClose}
                                                type="button"
                                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <LoadingOverlay active={overlay}/>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}