import Push, {PushHelper, PushStatus} from "../data/Push";
import React, {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XIcon} from "@heroicons/react/solid";
import InputText from "../../../components/InputText";
import LoadingOverlay from "../../../components/LoadingOverlay";
import InputTextArea from "../../../components/InputTextArea";
import Flatpickr from "react-flatpickr";
import {PushRequest} from "../data/PushRequest";
import BusinessSelect from "../../../components/select/dynamic/BusinessSelect";
import {Error, ErrorHelper} from "../../../common/data/Error";
import PushService from "../service/PushService";
import PushServiceDefaultImpl from "../service/impl/PushServiceDefaultImpl";
import {toast} from "react-toastify";
import {BusinessService} from "../../business/service/BusinessService";
import {BusinessServiceImpl} from "../../business/service/impl/BusinessServiceImpl";
import clsx from "clsx";

interface EditOrAddPushProps {
    open: boolean;
    onClose: () => void;
    editPush?: Push | null;
    onChange: (push: Push) => void;
}

export default function EditOrAddPush(
    {open, onClose, editPush = null, onChange}: EditOrAddPushProps) {

    const pushService: PushService = PushServiceDefaultImpl.Instance
    const businessService: BusinessService = BusinessServiceImpl.Instance

    const [overlay, setOverlay] = useState<boolean>(false);
    const [pushRequest, setPushRequest] = useState<PushRequest>((editPush) ? PushHelper.from(editPush) : PushHelper.newPushRequest)

    const [errors, setErrors] = useState<Map<String, Error>>(new Map<string, Error>())

    useEffect(() => {
        if (editPush && open) {
            setOverlay(true)
            businessService.findById(editPush.data.id).then((partialBusiness) => {
                const newPushRequest = PushHelper.from(editPush);
                newPushRequest.partialBusiness = partialBusiness
                setPushRequest(newPushRequest)
                setOverlay(false)
            })
        } else if(!editPush) {
            setPushRequest(PushHelper.newPushRequest)
        }
    }, [open])

    function validate(): boolean {

        const newErrors = new Map<string, Error>();

        if (!pushRequest.title)
            newErrors.set("title", ErrorHelper.newError(true, "Title should be defined"))

        if (!pushRequest.message)
            newErrors.set("message", ErrorHelper.newError(true, "Message should be defined"))

        if (pushRequest.title && pushRequest.title.length > 60)
            newErrors.set("title", ErrorHelper.newError(true, "Title should be less or equals 60 characters"))

        if (pushRequest.message && pushRequest.message.length > 140)
            newErrors.set("message", ErrorHelper.newError(true, "Message should be less or equals 140 characters"))

        if (!pushRequest.data.id)
            newErrors.set("business", ErrorHelper.newError(true, "Business should be defined"))

        setErrors(newErrors)

        return newErrors.size === 0
    }

    function createPush() {
        pushService.create(pushRequest).then((createdPush: Push) => {
            toast("Push has been successfully added");
            setOverlay(false);
            onChange(createdPush);
            onClose();
        }).catch((error) => {
            toast("Something went wrong trying to add push", {type: "error"});
            setOverlay(false);
        })
    }

    function updatePush() {
        pushService.update(pushRequest?.id ?? '', pushRequest).then((updatedPush: Push) => {
            toast("Push has been successfully updated");
            setOverlay(false);
            onChange(updatedPush);
            onClose();
        }).catch((error) => {
            toast("Something went wrong trying to edit push", {type: "error"});
            setOverlay(false);
        })
    }


    function onSubmit(event: React.FormEvent<HTMLFormElement>) {

        event.preventDefault();
        setOverlay(true);

        const isPushValid = validate();

        if (isPushValid && !editPush)
            createPush();
        else if (isPushValid && editPush) {
            updatePush();
        } else {
            setOverlay(false);
        }
    }

    function getBusinessRow() {
        return <div
            className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="title"
                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Business
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md">
                    <BusinessSelect
                        isMulti={false}
                        error={errors.get('business')?.error ?? false}
                        errorMessage={errors.get('business')?.message ?? ""}
                        onChange={(business) => {
                            console.log(business)
                            setPushRequest({
                                ...pushRequest,
                                data: {
                                    ...pushRequest.data,
                                    id: business.id
                                }
                            })
                        }}/>
                </div>
            </div>
        </div>
    }

    function getTimeRow() {
        return <div
            className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="title"
                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Time
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md">
                    <Flatpickr
                        disabled={pushRequest.status && pushRequest.status === PushStatus.SENT}
                        data-enable-time={true}
                        data-date-format="Y-m-d H:i"
                        className={clsx((pushRequest.status && pushRequest.status === PushStatus.SENT) ? 'bg-gray-100' : '', 'rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm')}
                        value={pushRequest.time}
                        onChange={(date: any) => {
                            setPushRequest(
                                {...pushRequest, time: date[0]}
                            )
                        }}
                    />
                </div>
            </div>
        </div>
    }

    function getTitleRow() {
        return <div
            className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="title"
                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Title
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div
                    className="flex max-w-lg rounded-md">
                    <InputText
                        disabled={pushRequest.status && pushRequest.status === PushStatus.SENT}
                        initialValue={pushRequest?.title}
                        error={errors.get("title")?.error ?? false}
                        errorMessage={errors.get("title")?.message ?? ""}
                        onChange={(e: string) => {
                            setPushRequest({
                                ...pushRequest, title: e
                            })
                        }}/>
                </div>
            </div>
        </div>
    }

    function getDescriptionRow() {
        return <div
            className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="description"
                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Description
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div
                    className="flex max-w-lg rounded-md">
                    <InputTextArea
                        disabled={pushRequest.status && pushRequest.status === PushStatus.SENT}
                        initialValue={pushRequest?.message}
                        error={errors.get("message")?.error ?? false}
                        errorMessage={errors.get("message")?.message ?? ""}
                        onChange={(e: string) => {
                            setPushRequest({
                                ...pushRequest,
                                message: e
                            })
                        }} rows={3}/>
                </div>
            </div>
        </div>
    }

    function getBusinessDisplay() {
        return <div
            className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="description"
                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Business
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="grid grid-cols-2 gap-2">
                    <div className="flex items-center">
                        <div
                            className="h-10 w-10 flex-shrink-0">
                            <img
                                className="h-10 w-10 rounded-full"
                                src={pushRequest?.partialBusiness?.logo?.url ?? ''}
                                alt=""/>
                        </div>
                        <div className="ml-4">
                            <div
                                className="font-medium text-sm text-gray-900">teste
                            </div>
                            <div
                                className="text-gray-500 text-sm">teste
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return <>
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform w-full overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-6xl  sm:p-6">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <form onSubmit={onSubmit}
                                      className="space-y-8 divide-y divide-gray-200">
                                    <div
                                        className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5">
                                            <div>
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                                    {(editPush) ? 'Edit' : 'Add'} Push</h3>
                                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                    {(editPush) ? 'Edit' : 'Add'} scheduled
                                                    push.
                                                </p>
                                            </div>
                                            {(!editPush) ? getBusinessRow() : getBusinessDisplay()}
                                            {getTimeRow()}
                                            {getTitleRow()}
                                            {getDescriptionRow()}
                                        </div>
                                    </div>
                                    <div className="pt-5">
                                        <div className="flex justify-end">
                                            <button
                                                onClick={onClose}
                                                type="button"
                                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Cancel
                                            </button>
                                            {
                                                (pushRequest.status && pushRequest.status === PushStatus.SENT) ? null : <button
                                                    type="submit"
                                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                >
                                                    Save
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </form>
                                <LoadingOverlay active={overlay}/>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>
}