import Lottie from "lottie-react"
import lottieEmptyState from "../common/lottie/astronaut-light-theme.json"


interface EmptyStateProps {
    description: string,
    subDescription: string | null
}

export default function EmptyState ({description, subDescription} : EmptyStateProps) {
    return <>
        <div className="m-auto text-center">
            <Lottie style={{width: "300px"}} animationData={lottieEmptyState} loop={true} />
            <h3 className="text-xl font-bold mt-4">{description}</h3>
            <h3 className="text-medium font-medium text-gray-400 mt-2">{subDescription}</h3>
        </div>
    </>
}