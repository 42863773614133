import {Range} from "../../../common/data/Range";
import {Measure, RangeDistance} from "../../../common/data/RangeMeasure";
import {TimeRange} from "../../../common/data/TimeRange";
import {RangeNumber} from "../../../common/data/RangeNumber";


export default interface MarketplaceProfile {
    readonly businessId: string,
    readonly enabled: boolean,
    readonly deliveryEnabled: boolean,
    readonly pickupEnabled: boolean,
    readonly email: string,
    readonly scheduleEnabled: boolean,
    readonly deliveryNowEnabled: boolean,
    readonly minimumOrderAmount: number,
    readonly allowedPaymentMethods: string[],
    readonly orderConfiguration: OrderConfiguration,
    readonly deliveryConfiguration: DeliveryConfiguration,
    readonly billingOnboardUrl: string,
    readonly billingStatus: string,
    readonly features: string[],
    readonly printerName: string | null
}


export enum Feature {
    PARTNERS_DELIVERY="PARTNERS_DELIVERY", 
    BASKET="BASKET", 
    DELIVERY="DELIVERY"
}

export interface OrderConfiguration {
    readonly preparationRange: RangeNumber
}

export interface DeliveryConfiguration {
    readonly maxDistanceInMiles: number,
    readonly acceptedPostCodes: string[],
    readonly items: DeliveryConfigurationItem[]
}

export interface DeliveryConfigurationItem {
    readonly distanceRange: RangeDistance,
    readonly timeRange: TimeRange,
    readonly price: number,
    readonly position?: number
}


export function initDeliveryConfigurationItem() {
    return {
        distanceRange : {
            start: 0,
            end: 0,
            measure: Measure.MILES
        },
        timeRange : {
            start: 0,
            end: 0
        },
        price : 0,
        position : 0
    } as DeliveryConfigurationItem
}