export class Routes {
  static readonly LOGIN = "/";
  static readonly ORDERS = "/secure/orders/";
  static readonly BUSINESSES = "/secure/businesses/";
  static readonly CATALOG = "/secure/catalog";
  static readonly SECTIONS = "/secure/sections";
  static readonly PUSHS = "/secure/pushs";
  static readonly DRIVERS = "/secure/drivers";
  static readonly TASKS = "/secure/delivery-tasks";
  static readonly EARNINGS = "/secure/driver-earnings";
  static readonly BANNERS = "/secure/banners";
  static readonly SETTINGS = "/secure/settings";
  static readonly SIGNUP = "/signup";
  static readonly ONBOARDING_PRIMARY_INFO = "/secure/onboarding/primary/info";

  static readonly ONBOARDING_ADDRESS = "/secure/onboarding/address/info";
  static readonly ONBOARDING_CATEGORY = "/secure/onboarding/category/info";
}