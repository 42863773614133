import {OrderService} from "../../service/OrderService";
import {OrderServiceDefaultImpl} from "../../service/impl/OrderServiceDefaultImpl";
import {OrderStatus} from "../../data/OrderStatus";
import React, {Fragment, ReactNode, useContext, useEffect, useState} from "react";
import OrdersList from "../OrdersList";
import {Pagination} from "../../../../common/data/Pagination";
import {Order} from "../../data/Order";
import {Menu, Transition} from "@headlessui/react";
import classNames from "../../../../common/helper/CssHelper";
import {CheckCircleIcon, ChevronDownIcon, HomeIcon, PrinterIcon, XIcon} from "@heroicons/react/solid";
import {toast} from "react-toastify";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import PrinterHelper from "../../helper/PrinterHelper";
import {PrinterConfigContext} from "../../../../context/printer/PrinterConfigContext";
import { SessionHelper } from "../../../../common/helper/SessionHelper";

interface InTransitProps {
    onChangeState(): void
}

export default function InTransit({onChangeState} : InTransitProps) {

    const orderService: OrderService = OrderServiceDefaultImpl.Instance;
    const isAdmin = SessionHelper.retrieveUserSession()?.isAdminRole() || false;

    const {printerConfig, setPrinterConfig} = useContext(PrinterConfigContext);
    const printerHelper = new PrinterHelper(setPrinterConfig, printerConfig)

    const [orders, setOrders] = useState<Pagination<Order[]> | null>(null);
    const [overlay, setOverlay] = useState<boolean>(false);

    async function findAllOrders(): Promise<Pagination<Order[]>> {
        return await orderService.findAll([OrderStatus.IN_ROUTE], 0, 50);
    }

    async function markAsDelivered(orderId: string) {
        await orderService.markOrderAsDelivered(orderId)
    }

    async function rejectOrder(order: Order) {
        await orderService.rejectOrder(order.id)
    }

    useEffect(() => {
        findAllOrders().then((orders) => {
            setOrders(orders)
            onChangeState();
        })

        const interval = setInterval(function () {
            findAllOrders().then((orders) => {
                setOrders(orders)
                onChangeState();
            })
        }, 3000);

        return () => {
            clearInterval(interval)
        }
    }, [])

    function getMenuMarkAsDelivered(orderId: string) {
        return <button
            type="button"
            onClick={(e) => {
                e.preventDefault();
                setOverlay(true);
                markAsDelivered(orderId).then(() => {
                    findAllOrders().then(() => {
                        toast("Order successfully updated!");
                        setOverlay(false)
                        onChangeState()
                    })
                })
            }}
            className="rounded-l-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Mark as Delivered
        </button>
    }

    function getPrintOrder(order: Order) {
        return <Menu.Item>
            {({active}) => (
                <a onClick={(e) => {
                    e.preventDefault();
                    setOverlay(true);
                    printerHelper.printOrder(order).then(() => {
                        toast("Order successfully printed!");
                        setOverlay(false)
                    })
                }}
                   href="#"
                   className={classNames(
                       active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                       'group flex items-center px-4 py-2 text-sm'
                   )}
                >
                    <PrinterIcon
                        className="mr-3 h-8 w-8 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                    />
                    Print order
                </a>
            )}
        </Menu.Item>
    }

    function getRejectOrderAction(order: Order) {
        return <Menu.Item>
            {({active}) => (
                <a onClick={(e) => {
                    e.preventDefault();
                    setOverlay(true);
                    rejectOrder(order).then(() => {
                        findAllOrders().then(() => {
                            toast("Order successfully cancelled!");
                            setOverlay(false)
                            onChangeState()
                        })
                    })
                }}
                   href="#"
                   className={classNames(
                       active ? 'bg-red-100 text-red-900' : 'text-red-700',
                       'group flex items-center px-4 py-2 text-sm'
                   )}
                >
                    <XIcon
                        className="mr-3 h-8 w-8 text-red-400 group-hover:text-red-500"
                        aria-hidden="true"
                    />
                    Cancel order
                </a>
            )}
        </Menu.Item>
    }

    function getPrimaryAction(order: Order): JSX.Element {
        return getMenuMarkAsDelivered(order.id)
    }

    function getSecondaryActions(order: Order) {
        // if (isAdmin) {
        //     return [getPrintOrder(order), getRejectOrderAction(order)]
        // } else {
            return [getPrintOrder(order)]
        // }
    }

    function getActions(order: Order, seeDetailsAction: ReactNode) {
        return <div className="inline-flex rounded-md shadow-sm">
            {getPrimaryAction(order)}
            <Menu as="div" className="relative -ml-px block">
                <Menu.Button className="rounded-r-md bg-indigo-600 border-l-2 border-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <span className="sr-only">Open options</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            {getSecondaryActions(order)}
                            {seeDetailsAction}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    }

    return <>
        <LoadingOverlay active={overlay}/>
        <OrdersList orders={orders} actions={getActions}/>
    </>

}