import classNames from "../../../common/helper/CssHelper";

interface DeliveryTypeProps {
    deliveryType: String
}

export default function DeliveryType({deliveryType}: DeliveryTypeProps) {
    return <>
        <span
            className={classNames((deliveryType === "MARKETPLACE") ? "text-amber-800" : "text-purple-800", "text-sm font-bold")}>
            {deliveryType?.toLocaleLowerCase()}
        </span>
    </>
}