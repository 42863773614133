import React, {Fragment, useState} from "react";
import {Dialog, Switch, Transition} from "@headlessui/react";
import {XIcon} from "@heroicons/react/solid";
import {IWorkingHours} from "../../../../business/data/WorkingHours";
import clsx from "clsx";
import InputTime from "react-input-time";
import {getTimeStr, setTime, setTimeUTC} from "../../../../../common/helper/DateHelper";
import {Range} from "../../../../../common/data/Range";


interface EditWorkingHoursProps {
    onClose(): void

    open: boolean
    initialItem: IWorkingHours

    onChange(item: IWorkingHours): void
}

export default function EditWorkingHours({
                                             onClose,
                                             open,
                                             initialItem,
                                             onChange
                                         }: EditWorkingHoursProps) {

    const [workingTime, setWorkingTime] = useState<IWorkingHours>(initialItem)

    function onSubmit(e: any) {
        e.preventDefault();
        onChange(workingTime)
    }

    return <>
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform w-full overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-md  sm:p-6">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <form onSubmit={onSubmit}
                                      className="space-y-8 divide-y divide-gray-200">
                                    <div
                                        className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5">
                                            <div>
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Edit
                                                    Working Time</h3>
                                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                    Edit working time configuration
                                                </p>
                                            </div>
                                            <div>
                                                <ul role="list"
                                                    className={clsx("mt-2", "divide-y", "divide-gray-200", "border", "border-l-0", "border-r-0", (workingTime.closed || !workingTime.provided) ? "border-b-0" : "")}>
                                                    <Switch.Group as="li"
                                                                  className="flex items-center justify-between py-4">
                                                        <div className="flex flex-col">
                                                            <Switch.Label as="p"
                                                                          className="text-sm font-medium text-gray-900"
                                                                          passive>
                                                                Closed
                                                            </Switch.Label>
                                                            <Switch.Description
                                                                className="text-sm text-gray-500">
                                                                This option marks the
                                                                business as closed
                                                            </Switch.Description>
                                                        </div>
                                                        <Switch
                                                            checked={workingTime.closed}
                                                            onChange={() => {

                                                                const times = (workingTime.closed) ? [{
                                                                    open: setTimeUTC("08:00"),
                                                                    close: setTimeUTC("17:00")
                                                                } as Range] : null

                                                                setWorkingTime({
                                                                    ...workingTime,
                                                                    times: times,
                                                                    closed: (!workingTime.closed)
                                                                })
                                                            }}
                                                            className={clsx(
                                                                workingTime.closed ? 'bg-teal-500' : 'bg-gray-200',
                                                                'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                                            )}
                                                        >
                                                              <span
                                                                  aria-hidden="true"
                                                                  className={clsx(
                                                                      workingTime.closed ? 'translate-x-5' : 'translate-x-0',
                                                                      'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                  )}
                                                              />
                                                        </Switch>
                                                    </Switch.Group>
                                                    <Switch.Group as="li"
                                                                  className="flex items-center justify-between py-4">
                                                        <div className="flex flex-col">
                                                            <Switch.Label as="p"
                                                                          className="text-sm font-medium text-gray-900"
                                                                          passive>
                                                                Provided
                                                            </Switch.Label>
                                                            <Switch.Description
                                                                className="text-sm text-gray-500">
                                                                Business working time as
                                                                provided
                                                            </Switch.Description>
                                                        </div>
                                                        <Switch
                                                            checked={workingTime.provided}
                                                            onChange={() => {

                                                                const times = (!workingTime.provided) ? [{
                                                                    open: setTimeUTC("08:00"),
                                                                    close: setTimeUTC("17:00")
                                                                } as Range] : null

                                                                setWorkingTime({
                                                                    ...workingTime,
                                                                    times : times,
                                                                    provided: (!workingTime.provided)
                                                                })
                                                            }}
                                                            className={clsx(
                                                                workingTime.provided ? 'bg-teal-500' : 'bg-gray-200',
                                                                'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                                            )}
                                                        >
                                                              <span
                                                                  aria-hidden="true"
                                                                  className={clsx(
                                                                      workingTime.provided ? 'translate-x-5' : 'translate-x-0',
                                                                      'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                  )}
                                                              />
                                                        </Switch>
                                                    </Switch.Group>
                                                </ul>
                                                <div>
                                                    {
                                                        !workingTime.closed && workingTime.provided ? <div
                                                            className="grid grid-cols-2 gap-6 mt-4">
                                                            <div>
                                                                <label className="text-sm">Open
                                                                </label>
                                                                {
                                                                    (workingTime.times) ? <InputTime
                                                                        className={clsx("border-gray-300 ", "block", " w-full", "min-w-0", "flex-1", "rounded-md", "focus:border-indigo-500", "focus:ring-indigo-500", "sm:text-sm", "mt-2")}
                                                                        initialTime={getTimeStr(workingTime.times[0].open)}
                                                                        onChange={(event) => {
                                                                            const times = workingTime.times
                                                                            if (times != null) {
                                                                                times[0].open = setTime(times[0].open, event.target.value)
                                                                                setWorkingTime({
                                                                                    ...workingTime,
                                                                                    times: times
                                                                                })
                                                                            }
                                                                        }}
                                                                    /> : <></>
                                                                }
                                                            </div>
                                                            <div>
                                                                <label
                                                                    className="text-sm">Close</label>
                                                                {
                                                                    (workingTime.times) ?
                                                                        <InputTime
                                                                            className={clsx("border-gray-300 ", "block", " w-full", "min-w-0", "flex-1", "rounded-md", "focus:border-indigo-500", "focus:ring-indigo-500", "sm:text-sm", "mt-2")}
                                                                            initialTime={getTimeStr(workingTime.times[0].close)}
                                                                            onChange={(event) => {
                                                                                const times = workingTime.times

                                                                                if (times != null) {
                                                                                    times[0].close = setTime(times[0].close, event.target.value)

                                                                                    setWorkingTime({
                                                                                        ...workingTime,
                                                                                        times: times
                                                                                    })
                                                                                }
                                                                            }}
                                                                        /> : <></>
                                                                }
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div
                                                className={clsx("pt-5", "border", "border-0", "border-t")}>
                                                <div className="flex justify-end">
                                                    <button
                                                        onClick={onClose}
                                                        type="button"
                                                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>
}