import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useEffect, useState} from "react";
import {XIcon} from "@heroicons/react/solid";
import LoadingOverlay from "../../../components/LoadingOverlay";
import {toast} from "react-toastify";
import InputText from "../../../components/InputText";
import CatalogSection from "../../catalog/data/CatalogSection";
import CatalogServiceDefaultImpl from "../../catalog/service/impl/CatalogServiceDefaultImpl";
import CatalogService from "../../catalog/service/CatalogService";
import {newError} from "../../../common/data/Error";
import InputNumber from "../../../components/InputNumber";

interface EditSectionProps {
    open: boolean,

    onClose(): void,

    postUpdate(): void,

    section: CatalogSection
}

export default function EditSection({
                                            open,
                                            onClose,
                                            postUpdate,
                                            section
                                        }: EditSectionProps) {

    const catalogService: CatalogService = CatalogServiceDefaultImpl.Instance;

    const [item, setItem] = useState<CatalogSection>(section);
    const [overlay, setOverlay] = useState(false);

    const [errors, setErrors] = useState({
        namePT: {
            error: false,
            message: ""
        },
        nameEN: {
            error: false,
            message: ""
        },
        order: {
            error: false,
            message: ""
        },
    })

    function onUpdate(e: any) {

        e.preventDefault();

        if(validate())
            return;

        setOverlay(true)

        catalogService.updateSection(item).then((section) => {
            setOverlay(false)
            toast("Section has been successfully updated");
            onClose()
            postUpdate()
        })
    }

    function validate(): boolean {

        let hasError = false;

        let copyError = {...errors}

        if (item.names.PT == "" || item.names.PT < 5) {
            copyError = {...copyError, namePT: newError(true, "Invalid name PT")}
            hasError = true
        }

        if (item.names.EN == "" || item.names.EN < 5) {
            copyError = {...copyError, nameEN: newError(true, "Invalid name EN")}
            hasError = true
        }

        setErrors(copyError)

        return hasError
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform w-full overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-6xl  sm:p-6">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <form onSubmit={onUpdate}
                                      className="space-y-8 divide-y divide-gray-200">
                                    <div
                                        className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5">
                                            <div>
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Edit
                                                    Section</h3>
                                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                    Edit section details.
                                                </p>
                                            </div>

                                            <div className="space-y-6 sm:space-y-5">
                                                <div
                                                    className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                    <label htmlFor="username"
                                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Name PT
                                                    </label>
                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                        <div
                                                            className="flex max-w-lg rounded-md shadow-sm">
                                                            <InputText error={errors.namePT.error}
                                                                       errorMessage={errors.namePT.message}
                                                                       initialValue={(item.names) ? item.names.PT : ""}
                                                                       onChange={(e) => {
                                                                           const names = {
                                                                               "PT": e,
                                                                               "EN": (item.names) ? item.names.EN : ""
                                                                           }
                                                                           const newItem = {
                                                                               ...item,
                                                                               names: names
                                                                           }
                                                                           setItem(newItem)
                                                                       }}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                    <label htmlFor="username"
                                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Title EN
                                                    </label>
                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                        <div
                                                            className="flex max-w-lg rounded-md shadow-sm">
                                                            <InputText error={errors.nameEN.error}
                                                                       initialValue={(item.names) ? item.names.EN : ""}
                                                                       errorMessage={errors.nameEN.message}
                                                                       onChange={(e) => {
                                                                           const names = {
                                                                               "PT": (item.names) ? item.names.PT : "",
                                                                               "EN": e
                                                                           }
                                                                           const newItem = {
                                                                               ...item,
                                                                               names: names
                                                                           }
                                                                           setItem(newItem)
                                                                       }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Order
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <div
                                                        className="flex max-w-lg rounded-md shadow-sm">
                                                        <InputNumber error={errors.nameEN.error}
                                                                   initialValue={(item) ? item.order : 0}
                                                                   errorMessage={errors.nameEN.message}
                                                                   onChange={(e) => {
                                                                       const newItem = {
                                                                           ...item,
                                                                           order: e
                                                                       }
                                                                       setItem(newItem)
                                                                   }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pt-5">
                                        <div className="flex justify-end">
                                            <button
                                                onClick={onClose}
                                                type="button"
                                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <LoadingOverlay active={overlay}/>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}