import { useEffect, useState } from "react";
import EarningService from "./service/EarningService";
import EarningServiceImpl from "./service/EarningServiceImpl";
import Earning from "./data/Earning";
import { Pagination as PaginationData } from "../../common/data/Pagination";
import DataTable from 'react-data-table-component';
import moment from "moment";

export default function Earnings() {

    const earningService: EarningService = EarningServiceImpl.Instance;
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [overlay, setOverlay] = useState<boolean>(false);
    const [earnings, setEarnings] = useState<PaginationData<Earning[]>>({} as PaginationData<Earning[]>);

    useEffect(() => {
        findAll(page, pageSize);
    }, [])

    function findAll(page: number, pageSize: number): void {
        setOverlay(true)
        earningService.findAll(page - 1, pageSize).then((Earnings) => {
            setEarnings(Earnings);
            setOverlay(false);
        })
    }

    
const columns = [
    {
        name: 'Driver',
        selector: (row: Earning) => row.driver.firstName + " " + row.driver.lastName,
    },
    {
        name: 'Status',
        selector: (row: Earning) => row.status,
    },
    {
        name: 'Start',
        selector: (row: Earning) => moment(row.start).format("YYYY-MM-DD"),
    },
    {
        name: 'End',
        selector: (row: Earning) => moment(row.end).format("YYYY-MM-DD"),
    },
    {
        name: 'Orders',
        selector: (row: Earning) => row.count,
    },
    {
        name: 'Total',
        selector: (row: Earning) => row.total,
    }
];


    const handlePerRowsChange =  (pageSize: number, page: number) => {
        setPageSize(pageSize);
        findAll(page, pageSize);
	};

    const handlePageChange = (page: number) => {
        setPage(page);
        findAll(page, pageSize);
	};

    return (
        <DataTable
			columns={columns}
			data={earnings.content}
			progressPending={overlay}
			pagination
			paginationServer
			paginationTotalRows={earnings.totalElements}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
		/>
    );
}