export interface IAddress {
    readonly city: string,
    readonly street: string,
    readonly country: string,
    readonly postCode: string,
    readonly partialCity: string,
    readonly addressDefined: boolean
}

export function getPrimaryAddress(address: IAddress): string {
    if (address.street != null && address.postCode != null)
        return `${address.street}, ${address.postCode}`
    return "Address not defined"
}





