import React, {useEffect, useState} from "react";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import {OrderService} from "../../../../orders/service/OrderService";
import {OrderServiceDefaultImpl} from "../../../../orders/service/impl/OrderServiceDefaultImpl";
import User from "../../../../orders/data/User";
import AddDriver from "./AddDriver";

export default function Driver() {

    const orderService: OrderService = OrderServiceDefaultImpl.Instance;
    const [drivers, setDrivers] = useState<User[]>([]);
    const [overlay, setOverlay] = useState<boolean>(true);
    const [openCreate, setOpenCreate] = useState<boolean>(false);


    useEffect(() => {
        fetchDrivers().then()
    }, [])


    async function fetchDrivers() {
        const drivers = await orderService.getDrivers()
        setDrivers(drivers)
        setOverlay(false)
    }

    useEffect(() => {
        return
    })

    function onSubmit(e: any) {
        e.preventDefault()
    }

    return <>
        <div className="py-6 sm:px-6 lg:px-8 px-4">
            <LoadingOverlay active={overlay}/>
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Drivers</h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            setOpenCreate(true)
                        }}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Add new driver
                    </button>
                </div>
            </div>
            <div
                className=" shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-5">
                <table className="min-w-full z-0 divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                        <th scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Name
                        </th>
                        <th scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Email
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {
                        (drivers) ? drivers.map((i) => {
                            return <tr>
                                <td className="whitespace-nowrap px-6 py-4 text-sm">
                                    <strong>{i.firstName} {i.lastName}</strong>
                                </td>
                                <td className="whitespace-nowrap px-6 py-4 text-sm">
                                    <strong>{i.email}</strong>
                                </td>
                            </tr>
                        }) : null
                    }
                    </tbody>
                </table>
            </div>
        </div>
        {
            <AddDriver open={openCreate} onClose={() => {
                setOpenCreate(false)
            }} postCreation={() => {
                fetchDrivers().then()
            }}/>
        }
    </>

}