import {OrderService} from "../OrderService";
import {OrderStatus} from "../../data/OrderStatus";
import {Pagination} from "../../../../common/data/Pagination";
import {Order} from "../../data/Order";
import {httpHelper} from "../../../../common/helper/HttpCommon";
import {API} from "../../../../common/const/Routes";
import User from "../../data/User";
import OrderAttachmentRequest from "../../data/OrderAttachmentRequest";
import DriverOrder from "../../data/DriverOrder";
import {OrdersStats} from "../../data/OrdersStats";

export class OrderServiceDefaultImpl implements OrderService {

    async getOrdersStats(statuses: OrderStatus[], timeFrame?: string, customDate?: string): Promise<OrdersStats> {

        const params = new URLSearchParams();
        params.append("status", statuses.join(","));

        if (timeFrame)
            params.append("timeFrame", timeFrame);

        if (customDate)
            params.append("customDate", customDate);

        return await httpHelper.get<OrdersStats>(API.ORDERS_STATS, params);
    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private static _instance: OrderServiceDefaultImpl;

    async findAll(statuses: OrderStatus[], page: number, size: number, timeFrame?: string, customDate?: string): Promise<Pagination<Order[]>> {

        const params = new URLSearchParams();
        params.append("size", size.toString());
        params.append("page", page.toString());
        params.append("status", statuses.join(","));

        if (timeFrame)
            params.append("timeFrame", timeFrame);

        if (customDate)
            params.append("customDate", customDate);

        return await httpHelper.get<Pagination<Order[]>>(API.ORDERS, params);
    }

    async findAllByDriver(driverId: string, statuses: OrderStatus[], page: number, size: number): Promise<Pagination<Order[]>> {

        const params = new URLSearchParams();
        params.append("size", size.toString());
        params.append("page", page.toString());
        params.append("status", statuses.join(","));
        params.append("driverId", driverId);

        return await httpHelper.get<Pagination<Order[]>>(API.ORDERS, params);
    }

    async findAllDriverActive(driverId: string): Promise<DriverOrder[]> {
        const resource = API.ORDER_DRIVERS.replace('{driverId}', driverId)
        return await httpHelper.get<DriverOrder[]>(resource);
    }

    async acceptOrder(orderId: string): Promise<Order> {
        const resource = API.ORDER_ACCEPT.replace("{orderId}", orderId);
        return await httpHelper.put<any>(resource, null, {});
    }

    async rejectOrder(orderId: string): Promise<Order> {
        const resource = API.ORDER_REJECT.replace("{orderId}", orderId);
        return await httpHelper.put<any>(resource, null, {});
    }

    async getSummary(): Promise<Map<string, number>> {
        const resp = await httpHelper.get<any>(API.ORDER_SUMMARY);
        return new Map<string, number>(Object.entries(resp));
    }

    async markOrderAsCollected(orderId: string): Promise<Order> {
        const resource = API.ORDER_AS_COLLECTED.replace("{orderId}", orderId);
        return await httpHelper.put<any>(resource, null, {});
    }

    async markOrderAsCompleted(orderId: string): Promise<Order> {
        const resource = API.ORDER_AS_COMPLETED.replace("{orderId}", orderId);
        return await httpHelper.put<any>(resource, null, {});
    }

    async markOrderAsDelivered(orderId: string): Promise<Order> {
        const resource = API.ORDER_AS_DELIVERED.replace("{orderId}", orderId);
        return await httpHelper.put<any>(resource, null, {});
    }

    async markOrderAsInTransit(orderId: string): Promise<Order> {
        const resource = API.ORDER_AS_IN_ROUTE.replace("{orderId}", orderId);
        return await httpHelper.put<any>(resource, null, {});
    }

    async markOrderAsReady(orderId: string): Promise<Order> {
        const resource = API.ORDER_AS_READY.replace("{orderId}", orderId);
        return await httpHelper.put<any>(resource, null, {});
    }

    async requestPartnerDelivery(orderId: string): Promise<any> {
        const resource = API.DELIVERY_TASK.replace("{orderId}", orderId);
        return await httpHelper.post<any>(resource, null, {});
    }

    async getDrivers(): Promise<User[]> {
        return await httpHelper.get<User[]>(API.DRIVERS)
    }

    async attachDriver(orderAttachmentRequest: OrderAttachmentRequest): Promise<Order[]> {
        return await httpHelper.put<any>(API.ORDER_ATTACHMENT, null, orderAttachmentRequest);
    }

    async detachDriver(orderAttachmentRequest: OrderAttachmentRequest): Promise<Order[]> {
        return await httpHelper.put<any>(API.ORDER_DETACHMENT, null, orderAttachmentRequest);
    }
}