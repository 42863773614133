import CatalogService from "../CatalogService";
import CatalogSection from "../../data/CatalogSection";
import {httpHelper} from "../../../../common/helper/HttpCommon";
import {API} from "../../../../common/const/Routes";
import CatalogItem from "../../data/CatalogItem";
import {Pagination} from "../../../../common/data/Pagination";
import {Blob} from "buffer";
import UploadResponse from "../../data/UploadResponse";

export default class CatalogServiceDefaultImpl implements CatalogService {

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private static _instance: CatalogServiceDefaultImpl

    async findSections(): Promise<CatalogSection[]> {
        return await httpHelper.get<CatalogSection[]>(API.CATALOG_SECTIONS);
    }

    async findItems(page: number, size: number, sectionId?: string, enabled?: boolean): Promise<Pagination<CatalogItem[]>> {

        const params = new URLSearchParams();

        if (sectionId)
            params.append("sectionId", sectionId);

        if (enabled != null)
            params.append("enabled", (enabled) ? "true" : "false");

        params.append("size", size.toString());
        params.append("page", page.toString());
        params.append("paged", "true");

        return await httpHelper.get<Pagination<CatalogItem[]>>(API.CATALOG_ITEMS, params);
    }

    async updateSection(section: CatalogSection): Promise<CatalogSection> {
        const resource = API.CATALOG_SECTIONS_FIND.replace("{sectionId}", section.id)
        return await httpHelper.put<CatalogSection>(resource, null, section);
    }

    async createSection(section: CatalogSection): Promise<CatalogSection> {
        return await httpHelper.post<CatalogSection>(API.CATALOG_SECTIONS, null, section);
    }

    async updateItem(catalogItem: CatalogItem): Promise<CatalogItem> {
        const resource = API.CATALOG_ITEMS_FIND.replace("{itemId}", catalogItem.id)
        return await httpHelper.put<CatalogItem>(resource, null, catalogItem);
    }

    async createItem(catalogItem: CatalogItem): Promise<CatalogItem> {
        return await httpHelper.post<CatalogItem>(API.CATALOG_ITEMS, null, catalogItem);
    }
}