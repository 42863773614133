import React, {useState} from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import DashboardBase from "./layout/base/DashboardBase";
import BusinessList from "./core/business/component/BusinessList";
import BusinessEdit from "./core/business/component/edit/BusinessEdit";
import Login from "./core/login/component/Login";
import Orders from "./core/orders/components/Orders";
import {Routes as RoutesConst} from "./routes/const/Routes";
import {PrivateRoutes} from "./routes/PrivateRoutes";
import Menu from "./core/catalog/components/Catalog";
import Section from "./core/sections/components/Section";
import Settings from "./core/settings/component/Settings";
import Signup from "./core/public/signup/components/Signup";
import PrimaryInfo from "./core/onboarding/components/PrimaryInfo";
import Address from "./core/onboarding/components/Address";
import Category from "./core/onboarding/components/Category";
import {PrinterConfigContext} from './context/printer/PrinterConfigContext';
import Push from "./core/push/components/Pushs";
import Drivers from './core/drivers/components/Drivers';
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";                                       
import Tasks from './core/tasks/Tasks';
import Earnings from './core/earnings/Earnings';
import Banners from './core/banners/Banners';
import NewDashboardBase from "./layout/base/NewDashboardBase";
         

export default function App() {

    const [printerConfig, setPrinterConfig] = useState(null);
    const [userSession, setUserSession] = useState(null);

    return (
        <PrinterConfigContext.Provider value={{printerConfig, setPrinterConfig}}>
                <Routes>
                    <Route path="/signup" element={
                        <Signup/>}/>
                    <Route path="/secure/onboarding/primary/info" element={
                        <PrimaryInfo/>}/>
                    <Route path="/secure/onboarding/address/info" element={
                        <Address/>}/>
                    <Route path="/secure/onboarding/category/info" element={
                        <Category/>}/>
                    <Route path={RoutesConst.BUSINESSES} element={
                        <NewDashboardBase title="Businesses">
                            <BusinessList/>
                        </NewDashboardBase>}/>
                    <Route path={RoutesConst.ORDERS} element={
                        <NewDashboardBase title="Marketplace Orders">
                            <PrivateRoutes>
                                <Orders/>
                            </PrivateRoutes>
                        </NewDashboardBase>}/>
                    <Route path={RoutesConst.PUSHS} element={
                        <NewDashboardBase title="Push Notifications">
                            <PrivateRoutes>
                                <Push/>
                            </PrivateRoutes>
                        </NewDashboardBase>}/>
                    <Route path={RoutesConst.DRIVERS} element={
                        <NewDashboardBase title="Drivers">
                            <PrivateRoutes>
                                <Drivers/>
                            </PrivateRoutes>
                        </NewDashboardBase>}/>
                    <Route path={RoutesConst.TASKS} element={
                        <NewDashboardBase title="Tasks">
                            <PrivateRoutes>
                                <Tasks/>
                            </PrivateRoutes>
                        </NewDashboardBase>}/>
                        <Route path={RoutesConst.EARNINGS} element={
                        <NewDashboardBase title="Earnings">
                            <PrivateRoutes>
                                <Earnings/>
                            </PrivateRoutes>
                        </NewDashboardBase>}/>
                        <Route path={RoutesConst.BANNERS} element={
                        <NewDashboardBase title="Banners">
                            <PrivateRoutes>
                                <Banners/>
                            </PrivateRoutes>
                        </NewDashboardBase>}/>
                    <Route path={RoutesConst.CATALOG} element={
                        <NewDashboardBase title="Catalog">
                            <PrivateRoutes>
                                <Menu/>
                            </PrivateRoutes>
                        </NewDashboardBase>}/>
                    <Route path={RoutesConst.SECTIONS} element={
                        <NewDashboardBase title="Section">
                            <PrivateRoutes>
                                <Section/>
                            </PrivateRoutes>
                        </NewDashboardBase>}/>
                    <Route path={RoutesConst.SETTINGS} element={
                        <NewDashboardBase title="Settings">
                            <PrivateRoutes>
                                <Settings/>
                            </PrivateRoutes>
                        </NewDashboardBase>}/>
                    <Route path={RoutesConst.LOGIN} element={
                        <Login/>
                    }/>
                </Routes>
        </PrinterConfigContext.Provider>
    )
}
