import React, {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {MenuIcon, XIcon} from "@heroicons/react/solid";
import {OrderService} from "../service/OrderService";
import {OrderServiceDefaultImpl} from "../service/impl/OrderServiceDefaultImpl";
import User from "../data/User";
import {OrderStatus} from "../data/OrderStatus";
import {Order} from "../data/Order";
import DraggableList from "react-draggable-list";
import LoadingOverlay from "../../../components/LoadingOverlay";
import SimpleEmptyState from "../../../components/SimpleEmptyState";
import Moment from "react-moment";
import OrderAttachmentRequest, {OrderAttachmentItem} from "../data/OrderAttachmentRequest";

interface AttachDriverSelectorProps {
    open: boolean,

    onClose(): void,
}

export interface ItemCommonProps {
    open: boolean

    onClick(order: Order): void
}

export default function DriverAttachment({open, onClose}: AttachDriverSelectorProps) {

    const orderService: OrderService = OrderServiceDefaultImpl.Instance;
    const [drivers, setDrivers] = useState<User[]>([]);
    const [selectedDriver, setSelectedDriver] = useState<string>("");
    const [openOrders, setOpenOrders] = useState<Order[]>([]);
    const [attachedOrders, setAttachedOrders] = useState<Order[]>([]);
    const [overlay, setOverlay] = useState<boolean>(true);

    useEffect(() => {
        if (open) {
            setSelectedDriver("")
        }
    }, [open])

    useEffect(() => {

        setOverlay(true)

        if (selectedDriver == "") {
            fetchDrivers().then()
        } else {
            fetchOrders().then()
        }
    }, [selectedDriver])

    async function fetchDrivers() {
        const drivers = await orderService.getDrivers()
        const driver = drivers[0]
        setDrivers(drivers)
        setSelectedDriver(driver.id)
    }

    async function fetchOrders() {
        const openOrders = await orderService.findAllByDriver("null", [OrderStatus.IN_PREPARATION, OrderStatus.WAITING_DELIVERY, OrderStatus.IN_ROUTE], 0, 40)

        if (selectedDriver != null && selectedDriver != '') {
            const attachedOrders = await orderService.findAllDriverActive(selectedDriver)
            setAttachedOrders(attachedOrders.map((o) => {
                return o.order
            }))
        }

        setOpenOrders(openOrders.content)

        setOverlay(false)
    }

    async function attachOrders(order?: Order) {

        const orderPriorities = attachedOrders.map((o, i) => {
            return {
                orderId: o.id,
                priority: i
            } as OrderAttachmentItem
        })

        if (order)
            orderPriorities.push({
                orderId: order.id,
                priority: orderPriorities.length + 1
            })

        const orderAttachment = {
            driverId: selectedDriver,
            items: orderPriorities
        } as OrderAttachmentRequest

        setOverlay(true)

        orderService.attachDriver(orderAttachment).then((orders) => {
            fetchOrders().then(() => {
                setOverlay(false)
            })
        })
    }

    function onSubmit(e: any) {
        e.preventDefault()
    }

    function getOpenOrderComponent() {
        return (openOrders && openOrders.length > 0) ?
            openOrders.map((o) => {
                const commonProps = {
                    open: false,
                    onClick: (order: Order, i: number) => {
                        attachOrders(order).then()
                    }
                } as ItemCommonProps
                return <Item commonProps={commonProps} open={true} item={o} itemSelected={0}
                             dragHandleProps={{}}/>
            }) : null
    }

    function getAttachedOrderComponent() {
        const commonProps = {
            open: false,
            onClick: (order: Order, i: number) => {

                const orderDetachment = {
                    driverId: selectedDriver,
                    items: [{
                        orderId: order.id,
                        priority: 1
                    }]
                } as OrderAttachmentRequest

                setOverlay(true)

                orderService.detachDriver(orderDetachment).then((orders) => {
                    fetchOrders().then(() => {
                        setOverlay(false)
                    })
                })
            }
        } as ItemCommonProps

        return <DraggableList<Order, ItemCommonProps, Item>
            itemKey="id"
            commonProps={commonProps}
            template={Item}
            list={attachedOrders}
            onMoveEnd={(newList) => onListChange(newList)}
            container={() =>
                document.body
            }
        />
    }

    function onListChange(newList: any) {

        setAttachedOrders(newList)

        const orderPriorities = newList.map((o: any, i: number) => {
            return {
                orderId: o.id,
                priority: i
            } as OrderAttachmentItem
        })

        const orderAttachment = {
            driverId: selectedDriver,
            items: orderPriorities
        } as OrderAttachmentRequest

        setOverlay(true)

        orderService.attachDriver(orderAttachment).then((orders) => {
            fetchOrders().then(() => {
                setOverlay(false)
            })
        })
    }

    return <>
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="transform container overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6 ">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <main className="lg:min-h-full mb-20">
                                    <h1 className="mb-4 text-xl font-bold">Driver Attachment</h1>
                                    <div className="border-t py-4 px-2 border-gray-200">
                                        <select
                                            onChange={(e) => {
                                                setSelectedDriver(e.target.value)
                                            }}
                                            value={selectedDriver}
                                            id="country"
                                            name="country"
                                            autoComplete="country-name"
                                            className="block w-full w-60 rounded-sm border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm"
                                        >
                                            {
                                                drivers.map((driver: User) => {
                                                    return <option
                                                        value={driver.id}>{driver.firstName} {driver.lastName}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="flex divide-x divide-dashed ">
                                        <div className="flex-1">
                                            <div
                                                className="border-gray-200 bg-gray-50 border-y border-dashed py-2 font-bold text-sm">
                                                Open Orders
                                            </div>
                                            <ul role="list"
                                                className="relative z-0">
                                                {
                                                    (openOrders == null || openOrders.length == 0) ?
                                                        <div className="mt-4"><SimpleEmptyState
                                                            description="No open orders found"
                                                            subDescription="There are no open orders found"/>
                                                        </div> : getOpenOrderComponent()
                                                }
                                            </ul>
                                        </div>
                                        <div className="flex-1">
                                            <div
                                                className="border-gray-200 bg-gray-50 border-y border-dashed py-2 font-bold pl-3 text-sm">
                                                Orders Attached
                                            </div>
                                            <ul role="list"
                                                className="relative z-0 divide-y divide-gray-200">
                                                {
                                                    (attachedOrders == null || attachedOrders.length == 0) ?
                                                        <div className="mt-4"><SimpleEmptyState
                                                            description="No attached orders found"
                                                            subDescription="There are no attached orders found"/>
                                                        </div> : getAttachedOrderComponent()
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </main>
                            </Dialog.Panel>
                        </Transition.Child>
                        <LoadingOverlay active={overlay}/>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>
}

interface ItemProps {
    item: Order;
    commonProps: any;
    itemSelected: number;
    dragHandleProps: object;
    open: boolean,
}

class Item extends React.Component<ItemProps, {}> {
    render() {
        return <>
            <div>
                <div {...this.props.dragHandleProps}
                     className="absolute z-30 top-7 left-2 dragHandle">
                    <MenuIcon className="w-5 text-gray-300"/>
                </div>
                <li onClick={(e) => {
                    this.props.commonProps.onClick(this.props.item, this.props.itemSelected)
                }} className="bg-white border-b border-dashed">

                    <div
                        className="relative flex items-center space-x-3 px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:bg-gray-50">
                        <div className="ml-8 flex-shrink-0">
                            <img className="h-10 w-10 rounded-full"
                                 src={this.props?.item?.user?.avatar ?? ''}
                                 alt=""/>
                        </div>
                        <div className="min-w-0 flex-1">
                            <a href="#"
                               className="focus:outline-none">
                                {/* Extend touch target to entire panel */}
                                <span className="absolute inset-0"
                                      aria-hidden="true"/>
                                <p className="text-sm font-medium text-gray-900">
                                    <span className="text-indigo-700">
                                        #{this.props?.item?.orderNumber ?? ''}
                                    </span> {" "}
                                    {this.props?.item?.user?.firstName ?? ''} {" "}
                                    {this.props?.item?.user?.lastName ?? ''}
                                </p>
                                <p className="truncate text-sm text-gray-500">
                                    {this.props?.item?.deliveryAddress?.street ?? ''} {" "}
                                    {this.props?.item?.deliveryAddress?.flat ?? ''} {" "}
                                    {this.props?.item?.deliveryAddress?.postalCode ?? ''}
                                </p>
                            </a>
                        </div>
                        <div>

                            <div className="inline-flex text-sm items-center">
                                <Moment date={this.props?.item?.expectedCompletionTime?.start ?? ''}
                                        format="H:mm"/> - <Moment
                                date={this.props?.item?.expectedCompletionTime?.end ?? ''}
                                format="H:mm"/>
                            </div>
                            <p className="text-gray-500 text-sm">
                                <Moment date={this.props?.item?.expectedCompletionTime?.start ?? ''}
                                        format="YYYY-MM-DD"/>
                            </p>
                        </div>
                    </div>
                </li>
            </div>
        </>
    }
}