import {Media} from "../../../common/data/Media";
import {IAddress} from "./Address";
import {ILocation} from "./Location";
import {Category} from "./Category";
import {IContact} from "./Contact";
import {defaultWorkingHours, IWorkingHours} from "./WorkingHours";

export interface IBusiness {
    readonly id: string,
    readonly status: BusinessStatus,
    readonly businessPlan: BusinessPlan,
    readonly name: string,
    readonly categoryId: string,
    readonly subCategoryIds: string[],
    readonly descriptions: any,
    readonly about: any,
    readonly services: any,
    readonly tags: string[],
    readonly profileCovers: Media[],
    readonly logoThumbnail: Media,
    readonly contacts: IContact[] | null,
    readonly logo: Media,
    readonly address: IAddress,
    readonly location?: ILocation,
    readonly category: Category,
    readonly subCategories: Category[],
    readonly workingHours: IWorkingHours[],
    readonly updateCover?: boolean,
}

export enum BusinessStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}

export enum BusinessPlan {
    NONE = "NONE", STANDARD = "STANDARD", PROFESSIONAL = "PROFESSIONAL", BUSINESS = "BUSINESS"
}

export function getBusinessPlan(businessPlan: string): BusinessPlan {
    switch (businessPlan) {
        case "NONE":
            return BusinessPlan.NONE;
        case "STANDARD":
            return BusinessPlan.STANDARD;
        case "PROFESSIONAL":
            return BusinessPlan.PROFESSIONAL;
        case "BUSINESS":
            return BusinessPlan.BUSINESS;
        default:
            return BusinessPlan.NONE;
    }
}

export function getBusinessStatus(status: string): BusinessStatus {
    switch (status) {
        case "ACTIVE":
            return BusinessStatus.ACTIVE;
        case "INACTIVE":
            return BusinessStatus.INACTIVE;
        default:
            return BusinessStatus.INACTIVE;
    }
}

export function newBusiness(): IBusiness {
    return {
        id: '',
        status: BusinessStatus.ACTIVE,
        businessPlan: BusinessPlan.NONE,
        name: '',
        categoryId: '',
        subCategoryIds: [],
        descriptions: {},
        about: {},
        services: {},
        tags: [],
        profileCovers: [],
        logoThumbnail: {
            url: '',
            alt: '',
            blurHash: '',
        },
        contacts: [],
        logo: {
            url: '',
            alt: '',
            blurHash: '',
        },
        address: {
            street: '',
            city: '',
            country: '',
            postCode: '',
            partialCity: '',
            addressDefined: true
        },
        workingHours: defaultWorkingHours(),
        category: {} as Category,
        subCategories: [],
    }
}