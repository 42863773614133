import {BusinessService} from "../BusinessService";
import {httpHelper} from "../../../../common/helper/HttpCommon";
import {Pagination} from "../../../../common/data/Pagination";
import {API} from "../../../../common/const/Routes";
import {IPartialBusiness} from "../../data/PartialBusiness";
import {BusinessPlan, BusinessStatus, IBusiness} from "../../data/Business";

export class BusinessServiceImpl implements BusinessService {

    private static _instance: BusinessServiceImpl;

    async findAll(term: string | null, category: string, subcategory: string, status: BusinessStatus | null, businessPlan: BusinessPlan | null, size: number = 10, page: number = 0): Promise<Pagination<IPartialBusiness[]>> {

        const params = new URLSearchParams();
        if (term != null) {
            params.append("name", term ?? "");
        }

        if (category && category !== "") {
            params.append("categoryId", category ?? "");
        }

        if (subcategory && subcategory !== "") {
            params.append("subCategoryId", subcategory ?? "");
        }

        if (status) {
            params.append("status", status.toString() ?? "");
        }

        if (businessPlan) {
            params.append("businessPlan", businessPlan.toString() ?? "");
        }

        params.append("size", size.toString());
        params.append("page", page.toString());
        params.append("showInactive", true.toString());

        console.log(params.toString());

        return await httpHelper.get<Pagination<IPartialBusiness[]>>(API.BUSINESS_SEARCH, params);
    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    async find(): Promise<IBusiness> {
        return await httpHelper.get<IBusiness>(API.BUSINESS_FIND)
    }

    async findById(businessId: string): Promise<IPartialBusiness> {
        return await httpHelper.get<IBusiness>(`${API.BUSINESS_PUBLIC_FIND}/${businessId}`)
    }

    async update(business: IBusiness): Promise<IBusiness> {
        return await httpHelper.put<IBusiness>(API.BUSINESS_FIND, null, business)
    }

    async create(business: IBusiness): Promise<IBusiness> {
        return await httpHelper.post<IBusiness>(API.BUSINESS_CREATE, null, business)
    }

}