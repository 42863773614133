import {LoginService} from "../LoginService";
import {httpHelper} from "../../../../common/helper/HttpCommon";
import {API} from "../../../../common/const/Routes";
import {LoginRequest} from "../../data/LoginRequest";
import {RegisterSessionRequest} from "../../data/RegisterSessionRequest";

export class LoginServiceDefaultImpl implements LoginService {

    private static _instance: LoginServiceDefaultImpl;

    async login(email: string, password: string): Promise<string> {

        const registerSessionRequest : RegisterSessionRequest = {
            userAgent : "web-admin",
            deviceId : "no-device-id",
            firebaseId : "",
            deviceType : "IOS"
        }

        const sessionJwt = await this.registerSession(registerSessionRequest)

        const headers = {
            Authorization : `Bearer ${sessionJwt}`
        }

        const loginRequest: LoginRequest = {
            email: email,
            password: password,
            confirmPassword: password
        }

        return await httpHelper.post<string>(API.LOGIN, null, loginRequest, headers)
    }

    async registerSession(registerSessionRequest: RegisterSessionRequest): Promise<string> {
        return await httpHelper.post<string>(API.REGISTER_SESSION, null, registerSessionRequest)
    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

}