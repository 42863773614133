import React, {useEffect, useState} from "react";
import clsx from "clsx";

interface InputNumberProps {
    onChange(newValue: number): void

    initialValue?: number
    error?: boolean,
    placeholder?: string,
    errorMessage?: string
}

export default function InputNumber({
                                        onChange,
                                        initialValue,
                                        error = false,
                                        placeholder = "",
                                        errorMessage = ""
                                    }: InputNumberProps) {

    const [value, setValue] = useState<number>((initialValue) ? initialValue : 0);

    useEffect(() => {
        onChange(value)
    }, [value])

    return <div className="relative mt-1 rounded-md">
        <input onChange={(e) => {
            setValue(Number(e.target.value))
        }}
               value={value}
               type="number"
               placeholder={placeholder}
               className={clsx((error) ? "border-red-600" : "border-gray-300 ", "block", " w-full", "min-w-0", "flex-1", "rounded-md", "focus:border-indigo-500", "focus:ring-indigo-500", "sm:text-sm")}/>

        {
            (error && errorMessage != "") ? <p className="mt-2 text-sm text-red-600">
                {errorMessage}
            </p> : <></>
        }
    </div>
}