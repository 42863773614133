export interface Error {
    error: boolean,
    message: string
}

export function newError(error: boolean, message: string) {
    return {
        error: error,
        message: message
    } as Error
}

export class ErrorHelper {

    public static newError(error: boolean, message: string): Error {
        return {
            error: error,
            message: message
        } as Error
    }

    public static emptyError(): Error {
        return {
            error: false,
            message: ""
        } as Error
    }
}