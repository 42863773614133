import {Order, OrderType} from "../data/Order";
import printf from "printf";
import {Align, EscPosBuilder} from "../../../common/helper/escpos/EscPosBuilder";
import moment from "moment";
import {EscPosBuilderImpl} from "../../../common/helper/escpos/EscPosBuilderImpl";


export default class PrinterHelper {

    private readonly qz = require("qz-tray")
    private readonly divider = "------------------------------------------------"

    private readonly printerConfig: any
    private readonly setPrinterConfig: (printerConfig: any) => void;

    private readonly escPOSBuilder: EscPosBuilder = new EscPosBuilderImpl()

    constructor(setPrinterConfig: (printerConfig: any) => void, printerConfig: any) {
        this.printerConfig = printerConfig
        this.setPrinterConfig = setPrinterConfig
    }

    async startPrinter(businessId?: string): Promise<string> {
        await this.qz.websocket.connect();
        //TODO make dynamic conf for printers admin
        const printerName = localStorage.getItem("printerName") ?? "zebra"
        const printerFindKey = (businessId && businessId === "637cff467b5e5901d0a95e80") ? "Star" : printerName
        const foundPrinter = await this.qz.printers.find(printerFindKey);
        const config = this.qz.configs.create(foundPrinter)
        await this.setPrinterConfig(config);
        return config
    }

    getDeliveryLine(order: Order): string {
        if (order.type === OrderType.PICKUP) {
            return 'PICKUP';
        } else {
            return `Delivery to ${order.deliveryAddress.street ?? ''} ${order.deliveryAddress.flat ?? ''} ${order.deliveryAddress.country ?? ''}`;
        }
    }

    async printOrder(order: Order) {

        let config = null

        try {
            if (!this.qz.websocket.isActive()) {
                config = await this.startPrinter(order.businessId)
                console.log("printer has been initialized")
            } else {
                config = this.printerConfig
            }
        } catch (e) {
            console.log("it was not possible to connect the printer")
            return
        }

        const tableHeader = printf('%-32s', 'Item Description') + printf('%6s', 'Qty') + printf('%10s', 'Price')

        const builder = this.escPOSBuilder
            .clear()
            .init()
            .align(Align.CENTER)
            .textLg(true)
            .text(order.business.name)
            .textLg(false)
            .newline()
            .newline()
            .line(`${order.businessAddress.street ?? ''} ${order.businessAddress.flat ?? ''}`)
            .line(`${order.businessAddress.postalCode ?? ''}`)
            .line(`${order.businessAddress.locality ?? ''} ${order.businessAddress.country ?? ''}`)
            .newline()
            .newline()
            .newline()
            .align(Align.LEFT)
            .textLg(true)
            .text(`Order: ${order.orderNumber} `)
            .textLg(false)
            .newline()
            .newline()
            .line(printf(`Order created ${moment(order.created).format("YYYY-MM-DD H:mm")}`))
            .line(this.getDeliveryLine(order))
            .textLg(true)
            .line(order.type === OrderType.PICKUP ? 'PICKUP' : `Postcode: ${order.deliveryAddress.postalCode}`)
            .line(`Payment method: ${order.paymentMethod}`)
            .textLg(false)
            .newline()
            .newline()
            .newline()
            .line(this.divider)
            .line(tableHeader)
            .line(this.divider)

        let totalItems = 0;

        order.items.forEach((o) => {
            builder.line(printf('%-32s', this.maxLength(o.item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 31)) + printf('%6s', o.quantity + "x") + printf('%10s', o.item.price.toFixed(2)))
            if (o.customization != null && o.customization.items.length > 0) {
                o.customization.items.forEach((c) => {
                    c.options.forEach((o) => {
                        builder.line(printf('%-32s', "  (" + this.maxLength(`${o.name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}${(o.description) ? " " + o.description?.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : ""})`  ?? "", 31)) + printf('%6s', `${o.quantity}x`) + printf('%10s', o.price?.toFixed(2) ?? ""))
                    })
                })
            }
            totalItems += o.quantity
        })

        builder.align(Align.CENTER)
            .line(this.divider)
            .line(printf('%-32s', 'Subtotal'))
            .bold(true)
            .text(printf('%6s', totalItems + "x"))
            .text(printf('%10s', order.subTotal.toFixed(2)))
            .bold(false)
            .line(printf('%-32s', "Delivery") + printf('%6s', "1x") + printf('%10s', order.deliveryPrice === null ? 0 : order.deliveryPrice.toFixed(2)))
            .line(printf('%-32s',(order.coupon) ? `Discount (${order.coupon?.code ?? "NO-CODE"})` : "Discount") + printf('%6s', "1x") + printf('%10s', order.discount === null ? -0 : -order.discount.toFixed(2)))
            .line(this.divider)
            .align(Align.RIGHT)
            .textLg(true)
            .line("TOTAL " + order.total.toFixed(2))
            .textLg(false)
            .newline()
            .line(this.divider)
            .align(Align.CENTER)
            .newline()
            .newline()

        if (order.instructions != null && order.instructions != "")
            builder
                .line(order.instructions)
                .newline()

        builder.line(`Order made by Conecta55 - Download the App`)
            .newline()
            .newline()
            .bold(true)
            .line(`Thank you for your order! We from ${order.business.name} hope to see you again soon!`)
            .bold(false)
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()
            .fullCut()
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()

        //kitchen order
        builder
            .align(Align.CENTER)
            .textLg(true)
            .line(`Kitchen Order - ${order.orderNumber}`)
            .newline()
            .line(order.type === OrderType.PICKUP ? 'PICKUP' : `POSTCODE: ${order.deliveryAddress.postalCode}`)
            .textLg(false)
            .newline()
            .newline()
            .newline()
            .newline()
            .align(Align.LEFT)
            .line(`Customer name: ${order.user.fullName ?? 'Not defined'}`)
            .line(`Customer expectation: ${moment(order.expectedCompletionTime.start).format("H:mm")} - ${moment(order.expectedCompletionTime.end).format("H:mm")}`)
            .line(`Order Created at: ${moment(order.created).format("YYYY-MM-DD H:mm")}`)
            .newline()
            .newline()
            .line(this.divider)

        order.items.forEach((o) => {
            builder.bold(true)
            builder.line(printf('%-6s', o.quantity + "x") + printf('%-42s', this.maxLength(o.item.name.toUpperCase(), 41)))
            builder.bold(false)
            if (o.customization != null && o.customization.items.length > 0) {
                o.customization.items.forEach((c) => {
                    c.options.forEach((o) => {
                        builder.line(printf('%-6s', o.quantity + "x") + printf('%-42s',  "(" + this.maxLength(`${o.name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}${(o.description) ? " " + o.description?.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : ""})`  ?? "", 31)))
                    })
                })
            }
            if (o.instructions != null && o.instructions != "") {
                builder.line(o.instructions)
            }
            builder.line(this.divider)
        })

        if (order.instructions != null && order.instructions != "")
            builder
                .newline()
                .newline()
                .newline()
                .bold(true)
                .line(order.instructions)
                .bold(false)
                .newline()

        builder.newline()
            .newline()
            .newline()
            .newline()
            .align(Align.CENTER)
            .textLg(true)
            .line(`Kitchen deadline`)
            .line(moment(order.expectedPreparationTime.start).format("H:mm"))
            .newline()
            .newline()
            .textLg(false)
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()
            .fullCut()

        const commands = builder.build();
        await this.qz.print(config, commands)
    }

    private maxLength(input: string, maxLength: number): string {
        if (input.length > maxLength)
            return input.substring(0, maxLength)
        else
            return input
    }

}