import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";
import clsx from "clsx";


interface PaginationProps {
    paginationControl?: (page: number) => void | null
    totalPages: number;
    currentPage: number;
}

export default function Pagination(
    {paginationControl, totalPages, currentPage}: PaginationProps) {

    const activeItemCss = clsx("relative", "z-10", "inline-flex", "items-center", "border", "border-indigo-500","bg-indigo-50", "px-4", "py-2", "text-sm", "font-medium", "text-indigo-600", "focus:z-20")
    const inactiveItemCss = clsx("relative", "inline-flex", "items-center", "border", "border-gray-300", "bg-white", "px-4", "py-2", "text-sm", "font-medium", "text-gray-500", "hover:bg-gray-50" ,"focus:z-20")

    function getPagination() {
        return Array.from({length: totalPages}).map((_, i) => {
            return <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    if (paginationControl != null)
                        paginationControl(i)
                }}
                aria-current="page"
                className={(i == currentPage) ? activeItemCss : inactiveItemCss}
            >{i+1}</a>
        })
    }

    return (paginationControl) ? (
        <div
            className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        if (paginationControl != null && currentPage != 0)
                            paginationControl(currentPage - 1)
                    }}
                    href="#"
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Previous
                </a>
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        if (paginationControl != null && currentPage != totalPages - 1)
                            paginationControl(currentPage + 1)
                    }}
                    href="#"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Next
                </a>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{currentPage + 1}</span> out of <span
                        className="font-medium">{totalPages} pages</span>
                        {/*of{' '}*/}
                        {/*<span className="font-medium">97</span> results*/}
                    </p>
                </div>
                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                         aria-label="Pagination">
                        <a
                            onClick={(e) => {
                                e.preventDefault();
                                if (paginationControl != null && currentPage != 0)
                                    paginationControl(currentPage - 1)
                            }}
                            href="#"
                            className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                        </a>
                        {getPagination()}
                        <a
                            onClick={(e) => {
                                e.preventDefault();
                                if (paginationControl != null && currentPage != totalPages-1)
                                    paginationControl(currentPage + 1)
                            }}
                            href="#"
                            className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                        >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    ) : null
}