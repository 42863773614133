import {API} from "../../../common/const/Routes";
import {Pagination} from "../../../common/data/Pagination";
import {httpHelper} from "../../../common/helper/HttpCommon";
import Task, {Quote} from "../data/Task";
import TaskV2 from "../data/TaskV2";
import TaskService from "./TaskService";

export default class TaskServiceImpl implements TaskService {
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private static _instance: TaskServiceImpl;

    async findAll(page: number, size: number, currentStatus: string | undefined): Promise<Pagination<Task[]>> {
        const params = new URLSearchParams();

        params.append("size", size.toString());
        params.append("page", page.toString());

        if (currentStatus) {
            params.append("status", currentStatus);
        }

        return await httpHelper.get<Pagination<Task[]>>(API.TASKS, params);
    }

    async findAllV2(page: number, size: number, currentStatus: string[] | undefined): Promise<Pagination<TaskV2[]>> {
        const params = new URLSearchParams();

        params.append("size", size.toString());
        params.append("page", page.toString());

        if (currentStatus) {
            params.append("status", currentStatus.join(","));
        }

        return await httpHelper.get<Pagination<TaskV2[]>>(API.TASKS_V2, params);
    }

    async cancelTask(taskId: string): Promise<any> {
        return await httpHelper.delete<Quote>(API.TASKS_FIND.replace(":taskId",taskId), null, {});
    }

    async requestPartnerDelivery(orderIds: string[], preferredAssignee: string | null): Promise<any> {
        const payload = {
            orderIds: orderIds, 
            preferredAssignee: preferredAssignee
        }
        const resource = API.DELIVERY_MULTI_ASK
        return await httpHelper.post<any>(resource, null, payload);
    }

    async getQuote(orderIds: string[]): Promise<Quote> {
        return await httpHelper.post<Quote>(API.TASKS_QUOTES, null, {
            orderIds: orderIds
        });
    }

    async find(taskId: string): Promise<TaskV2> {
        return await httpHelper.get<TaskV2>(API.TASKS_V2_FIND.replace(":taskId",taskId));
    }
}