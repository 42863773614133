import {OrderService} from "../../service/OrderService";
import {OrderServiceDefaultImpl} from "../../service/impl/OrderServiceDefaultImpl";
import {OrderStatus} from "../../data/OrderStatus";
import React, {Fragment, ReactNode, useContext, useEffect, useState} from "react";
import OrdersList from "../OrdersList";
import {Pagination} from "../../../../common/data/Pagination";
import {Order} from "../../data/Order";
import {Menu, Transition} from "@headlessui/react";
import classNames from "../../../../common/helper/CssHelper";
import {ChevronDownIcon, PrinterIcon} from "@heroicons/react/solid";
import PrinterHelper from "../../helper/PrinterHelper";
import {toast} from "react-toastify";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import {OrdersStats} from "../../data/OrdersStats";
import Flatpickr from "react-flatpickr";

import "flatpickr/dist/themes/light.css";
import moment from "moment/moment";
import {PrinterConfigContext} from "../../../../context/printer/PrinterConfigContext";

interface CompletedProps {
    onChangeState(): void
}

export default function Completed({onChangeState}: CompletedProps) {

    const orderService: OrderService = OrderServiceDefaultImpl.Instance;

    const {printerConfig, setPrinterConfig} = useContext(PrinterConfigContext);
    const printerHelper = new PrinterHelper(setPrinterConfig, printerConfig)

    const [orders, setOrders] = useState<Pagination<Order[]> | null>(null);
    const [stats, setStats] = useState<OrdersStats | null>(null);
    const [overlay, setOverlay] = useState<boolean>(false);

    const [page, setPage] = useState<number>(0);

    const [timeFrame, setTimeFrame] = useState<string>("TODAY");
    const [customDate, setCustomDate] = useState(new Date());

    async function findAllOrders(): Promise<Pagination<Order[]>> {
        return await orderService.findAll([OrderStatus.COMPLETED], page, 20, timeFrame, (timeFrame == "CUSTOM") ? moment(customDate).format('YYYY-MM-DD') : undefined);
    }

    async function getOrdersStats(): Promise<OrdersStats> {
        return await orderService.getOrdersStats([OrderStatus.COMPLETED], timeFrame, (timeFrame == "CUSTOM") ? moment(customDate).format('YYYY-MM-DD') : undefined);
    }

    useEffect(() => {
        findAllOrders().then((orders) => {
            getOrdersStats().then((stats) => {
                setOrders(orders);
                setStats(stats);
                onChangeState();
            })
        })
    }, [])

    useEffect(() => {
        setOverlay(true);
        findAllOrders().then((orders) => {
            getOrdersStats().then((stats) => {
                setOrders(orders)
                setStats(stats)
                onChangeState();
                setOverlay(false);
            })
        })
    }, [timeFrame,customDate])

    useEffect(() => {
        setOverlay(true);
        findAllOrders().then((orders) => {
            setOrders(orders)
            onChangeState();
            setOverlay(false);
        })
    }, [page])

    function getPrintOrder(order: Order) {
        return <Menu.Item>
            {({active}) => (
                <a onClick={(e) => {
                    e.preventDefault();
                    setOverlay(true);
                    printerHelper.printOrder(order).then(() => {
                        toast("Order successfully printed!");
                        setOverlay(false)
                    })
                }}
                   href="#"
                   className={classNames(
                       active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                       'group flex items-center px-4 py-2 text-sm'
                   )}
                >
                    <PrinterIcon
                        className="mr-3 h-8 w-8 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                    />
                    Print order
                </a>
            )}
        </Menu.Item>
    }

    function getSecondaryActions(order: Order) {
        return  [getPrintOrder(order)]
    }
    function getPrimaryAction(order: Order): JSX.Element {
        return getPrintOrderAsPrimary(order)
    }

    function getPrintOrderAsPrimary(order: Order) {
        return <button
            type="button"
            onClick={(e) => {
                e.preventDefault();
                setOverlay(true);
                printerHelper.printOrder(order).then(() => {
                    toast("Order successfully printed!");
                    setOverlay(false)
                })
            }}
            className="rounded-l-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Print order
        </button>
    }

    function getActions(order: Order, seeDetailsAction: ReactNode) {
        return <div className="inline-flex rounded-md shadow-sm">
            {getPrimaryAction(order)}
            <Menu as="div" className="relative -ml-px block">
                <Menu.Button className="rounded-r-md bg-indigo-600 border-l-2 border-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <span className="sr-only">Open options</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            {getSecondaryActions(order)}
                            {seeDetailsAction}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    }

    return <>
        <LoadingOverlay active={overlay}/>
        <div>
            <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 gap-3 mt-5">
                <div className="py-2 px-4 border border-dashed bg-gray-50 border-gray-300">
                    <p className="truncate text-sm font-medium text-gray-500">Orders</p>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-indigo-600">
                        {stats?.orders ?? 0}
                    </dd>
                </div>
                <div className="py-2 px-4 border border-gray-300 bg-gray-50 border-dashed">
                    <p className="truncate text-sm font-medium text-gray-500">Total</p>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-indigo-600">
                        £{stats?.total ?? 0}
                    </dd>
                </div>
                <div className="py-2 px-4 border border-dashed bg-gray-50 border-gray-300">
                    <p className="truncate text-sm font-medium text-gray-500">Card</p>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                        £{stats?.card ?? 0}
                    </dd>
                </div>
                <div className="py-2 px-4 border border-dashed bg-gray-50 border-gray-300">
                    <p className="truncate text-sm font-medium text-gray-500">Cash</p>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                        £{stats?.cash ?? 0}
                    </dd>
                </div>
                <div className="py-2 px-4 border border-dashed bg-gray-50 border-gray-300">
                    <p className="truncate text-sm font-medium text-gray-500">Delivery fee</p>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                        £{stats?.delivery ?? 0}
                    </dd>
                </div>
            </div>
        </div>
        <div className="flex gap-4 items-center py-4 px-2 border-b border-t border-gray-200 mt-5">
            <div className="flex">
                <select
                    value={timeFrame}
                    onChange={(e) => {
                        setTimeFrame(e.target.value)
                    }}
                    className="rounded-sm border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm">
                    <option value="TODAY">today</option>
                    <option value="YESTERDAY">yesterday</option>
                    <option value="WEEK">week</option>
                    <option value="MONTH">month</option>
                    <option value="CUSTOM">custom</option>
                </select>
            </div>
            {
                (timeFrame == "CUSTOM") ? <div className="flex-initial">
                    <Flatpickr
                        data-enable-time={false}
                        data-date-format="Y-m-d"
                        className="rounded-sm border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm"
                        value={customDate}
                        onChange={(date : any) => {
                            setCustomDate(date[0])
                        }}
                    />
                </div> : <></>
            }
        </div>

        <OrdersList orders={orders} actions={getActions} paginationControl={(page: number) => {
            setPage(page)
        }}/>
    </>

}