export interface IContact {
    readonly type: ContactType,
    readonly originalLink: string
    readonly link: string
}

export enum ContactType {
    EMAIL = "EMAIL",
    PHONE = "PHONE",
    WEBSITE = "WEBSITE",
    FACEBOOK = "FACEBOOK",
    INSTAGRAM = "INSTAGRAM",
    LINKEDIN = "LINKEDIN",
    WHATSAPP = "WHATSAPP",
    MENU = "MENU",
}