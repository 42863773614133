import MediaResponse from "../../data/MediaResponse";
import {Blob} from "buffer";

export interface MediaService {
    upload(type: MediaType, blob: Blob): Promise<MediaResponse>;
}

export enum MediaType {
    MARKETPLACE_CATALOG = "MARKETPLACE_CATALOG",
    BANNER = "BANNER",
    LOGO = "LOGO",
}