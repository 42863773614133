import PushService from "../PushService";
import {Pagination} from "../../../../common/data/Pagination";
import Push from "../../data/Push";
import {httpHelper} from "../../../../common/helper/HttpCommon";
import {API} from "../../../../common/const/Routes";
import {PushRequest} from "../../data/PushRequest";
import {PushRequestUpdate} from "../../data/PushRequestUpdate";

export default class PushServiceDefaultImpl implements PushService {

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private static _instance: PushServiceDefaultImpl;

    async findAll(page: number, size: number): Promise<Pagination<Push[]>> {

        const params = new URLSearchParams();

        params.append("size", size.toString());
        params.append("page", page.toString());

        return await httpHelper.get<Pagination<Push[]>>(API.PUSHS, params);
    }

    async create(pushRequest: PushRequest): Promise<Push> {
        return await httpHelper.post<Push>(API.PUSHS, null, pushRequest);
    }

    async update(id: string, pushRequestUpdate: PushRequestUpdate): Promise<Push> {
        return await httpHelper.put<Push>(`${API.PUSHS}/${id}`, null, pushRequestUpdate);
    }

    async delete(id: string): Promise<void> {
        return await httpHelper.delete<void>(`${API.PUSHS}/${id}`, null);
    }

}