import React, { useEffect, useState } from "react";
import Tab from "../../../common/data/Tab";
import classNames from "../../../common/helper/CssHelper";
import WaitingAcceptance from "./section/WaitingAcceptance";
import Preparing from "./section/Preparing";
import WaitingDelivery from "./section/WaitingDelivery";
import WaitingPickup from "./section/WaitingPickup";
import InTransit from "./section/InTransit";
import { OrderStatus } from "../data/OrderStatus";
import { OrderService } from "../service/OrderService";
import { OrderServiceDefaultImpl } from "../service/impl/OrderServiceDefaultImpl";
import Delivered from "./section/Delivered";
import Completed from "./section/Completed";
import DriverAttachment from "./DriverAttachment";
import RequestDelivery from "./RequestDelivery";
import DeliveryTaskDetails from "./DeliveryTaskDetails";

const initialTabs: Tab[] = [
    { name: 'Waiting Acceptance', href: '#waiting-acceptance', current: true },
    { name: 'Preparing', href: '#preparing', current: false },
    { name: 'Waiting Delivery', href: '#waiting-delivery', current: false },
    { name: 'Waiting Pickup', href: '#waiting-pickup', current: false },
    { name: 'In transit', href: '#in-transit', current: false },
    { name: 'Delivered', href: '#delivered', current: false },
    { name: 'Completed', href: '#completed', current: false },
]

export default function Orders() {

    const orderService: OrderService = OrderServiceDefaultImpl.Instance;

    const [tabs, setTabs] = useState<Tab[]>(initialTabs);
    const [summary, setSummary] = useState<Map<string, number>>(new Map<string, number>());
    const [selectedTab, setSelectedTab] = useState<string>("#waiting-acceptance");
    const [driverAttachmentModal, setDriverAttachmentModal] = useState<boolean>(false)
    const [deliveryTaskModal, setdeliveryTaskModal] = useState<boolean>(false)
    const [deliveryTaskId, setdeliveryTaskId] = useState<string>("")

    let i = 0

    let firstRequest = true

    //const notification = new Audio('https://static.staging.conecta55.com/sounds/smile-ringtone.mp3')
    const notification = new Audio('https://static.conecta55.com/sounds/conecta-audio2.mp3')

    function onDeliveryTaskDetails(taskId: string) {
        setdeliveryTaskId(taskId);
        setdeliveryTaskModal(true);
    }

    function getTabContent() {
        switch (selectedTab) {
            case "#waiting-acceptance":
                return <WaitingAcceptance onChangeState={() => {
                    getSummary().then()
                }} />
            case "#preparing":
                return <Preparing onChangeState={() => {
                    getSummary().then()
                }} />
            case "#waiting-delivery":
                return <WaitingDelivery onChangeState={() => {
                    getSummary().then()
                }}
                    onDeliveryTaskDetails={onDeliveryTaskDetails} />
            case "#waiting-pickup":
                return <WaitingPickup onChangeState={() => {
                    getSummary().then()
                }} />
            case "#in-transit":
                return <InTransit onChangeState={() => {
                    getSummary().then()
                }} />
            case "#delivered":
                return <Delivered onChangeState={() => {
                    getSummary().then()
                }} />
            case "#completed":
                return <Completed onChangeState={() => {
                    getSummary().then()
                }} />
        }
    }

    async function getSummary() {

        const newSummary = await orderService.getSummary();
        const newCount = newSummary.get(OrderStatus.WAITING_ACCEPTANCE.toString()) ?? 0

        if (newCount > i && !firstRequest)
            notification.play().then()

        i = newCount
        firstRequest = false

        await setSummary(newSummary)
    }

    async function ordersNotification() {
        return setTimeout(function () {
            orderService.getSummary().then(summary => {
                const waitingAcceptance = summary.get(OrderStatus.WAITING_ACCEPTANCE.toString()) ?? 0
                if (waitingAcceptance > 0)
                    notification.play().then()
                ordersNotification().then()
            })
        }, 10000);
    }

    function getStatusByTab(href: string): OrderStatus {
        switch (href) {
            case "#waiting-acceptance":
                return OrderStatus.WAITING_ACCEPTANCE
            case "#preparing":
                return OrderStatus.IN_PREPARATION
            case "#waiting-delivery":
                return OrderStatus.WAITING_DELIVERY
            case "#waiting-pickup":
                return OrderStatus.WAITING_PICKUP
            case "#in-transit":
                return OrderStatus.IN_ROUTE
            case "#delivered":
                return OrderStatus.DELIVERED
            case "#completed":
                return OrderStatus.COMPLETED
            default:
                return OrderStatus.COMPLETED
        }
    }

    useEffect(() => {
        ordersNotification().then()
    }, [])


    return <>
        <div className="py-6 sm:px-6 lg:px-8 px-4">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Order List</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all orders separated by statuses allowing a smooth order
                        management.
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            setDriverAttachmentModal(true);
                        }}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Driver Attachment
                    </button>
                </div>
            </div>
            <DriverAttachment open={driverAttachmentModal} onClose={() => {
                setDriverAttachmentModal(false)
            }} />
            {/* <DeliveryTaskDetails open={deliveryTaskModal}
                taskId={deliveryTaskId}
                onClose={() => { setdeliveryTaskModal(false) }} /> */}
            <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
                <div className="mt-10">
                    <div className="sm:hidden">
                        <label htmlFor="current-tab" className="sr-only">
                            Select a tab
                        </label>
                        <select
                            id="current-tab"
                            name="current-tab"
                            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) => {
                                setSelectedTab(e.target.value)
                            }}>
                            {tabs.map((tab) => (
                                <option value={tab.href}
                                    key={tab.name}>{tab.name} {(summary && summary.has(getStatusByTab(tab.href).toString())) ? `(${summary.get(getStatusByTab(tab.href).toString())})` : null}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <nav className="-mb-px flex space-x-8">
                            {tabs.map((tab, index) => (
                                <a
                                    onClick={(e) => {
                                        setSelectedTab(tab.href)
                                    }}
                                    key={tab.name}
                                    href={tab.href}
                                    className={classNames(
                                        selectedTab == tab.href
                                            ? 'border-indigo-500 text-indigo-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-md'
                                    )}
                                    aria-current={selectedTab == tab.href ? 'page' : undefined}
                                >
                                    {tab.name}

                                    {
                                        (summary && summary.has(getStatusByTab(tab.href).toString())) ?
                                            <span
                                                className={classNames(
                                                    true ? 'bg-red-100 text-red-600' : 'bg-gray-100 text-gray-900',
                                                    'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                )}>{summary.get(getStatusByTab(tab.href).toString())}</span> : null
                                    }

                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            {getTabContent()}
        </div>
    </>

}