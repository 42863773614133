import {AddressService} from "../AddressService";
import {Address, AddressOption, Items} from "../../data/AddressOption";
import {httpHelper} from "../../../../common/helper/HttpCommon";
import {API} from "../../../../common/const/Routes";

export class AddressServiceDefaultImpl implements AddressService {

    private static _instance: AddressServiceDefaultImpl;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    async autocomplete(term: string): Promise<Items> {
        const params = new URLSearchParams();
        params.append("term", term ?? "");
        return await httpHelper.get<Items>(API.ADDRESS_AUTOCOMPLETE, params)
    }

    async findAddressByReferenceId(referenceId: string): Promise<Address> {
        return await httpHelper.get<Address>(`${API.ADDRESS_AUTOCOMPLETE}/${referenceId}`)
    }
}