import Address from "../data/Address"
import React from "react";


interface OrderAddressProps {
    readonly address: Address
}

export default function OrderAddress({address}: OrderAddressProps) {

    if (address != null) {

        if(address.flat != null && address.flat.length > 10) {
            return <>
                <div className="font-medium">
                    {address.street ?? ''}
                </div>
                <div className="font-medium">
                    {address.flat ?? ''}
                </div>
                <div className="text-gray-500">
                    {address.postalCode ?? ''}
                </div>
            </>
        } else {
            return <>
                <div className="font-medium">
                    <a className="underline" target="_blank" href={"http://maps.google.com/?q="+address.postalCode}>
                        {
                            `${address.street ?? ''}, ${address.flat ?? ''}`
                        }
                    </a>
                </div>
                <div className="text-gray-500">
                    {address.postalCode ?? ''}
                </div>
            </>
        }

    } else return <></>
}