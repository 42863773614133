export function toUTC(date: Date): any {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}


export function getTime(date: string): string[] {
    const split = date.split("T")
    const time = split[1].split(":")
    if(time.length > 1)
        return [time[0], time[1]];
    else
        return [time[0], "00"];
}

export function setTime(date: string, time: string): string {
    const split = date.split("T")
    const splitTime = split[1].split(":")
    return `${split[0]}T${time}:00Z`;
}

export function setTimeUTC(time: string): string {
    return `1970-01-01T${time}:00Z`;
}

export function getTimeStr(date: string) {
    const time  = getTime(date)
    return `${time[0]}:${time[1]}`
}