/* This example requires Tailwind CSS v2.0+ */
import React, {useEffect, useState} from "react";
import {BusinessService} from "../service/BusinessService";
import {BusinessServiceImpl} from "../service/impl/BusinessServiceImpl";
import PaginationComp from "../../../components/Pagination";
import {Pagination} from "../../../common/data/Pagination";
import {CategoryService} from "../service/CategoryService";
import CategoryServiceImpl from "../service/impl/CategoryServiceImpl";
import Select, {Value} from "../../../components/Select";
import InputText from "../../../components/InputText";
import Modal from "../../../components/Modal";
import Settings from "../../settings/component/Settings";
import {SessionHelper} from "../../../common/helper/SessionHelper";
import BusinessPlan from "./BusinessPlan";
import BusinessStatus from "./BusinessStatus";
import {getPrimaryAddress} from "../data/Address";
import {IPartialBusiness} from "../data/PartialBusiness";
import {
    BusinessPlan as Plan,
    BusinessStatus as Status,
    getBusinessPlan,
    getBusinessStatus
} from "../data/Business";
import General from "../../settings/component/section/General";

const EMPTY_CATEGORIES: Value[] = [{id: "", name: "Select category.."} as Value]
const EMPTY_SUBCATEGORIES: Value[] = [{id: "", name: "Select subcategory.."} as Value]
const BUSINESS_PLANS: Value[] = [{
    id: "",
    name: "Select plan.."
} as Value].concat(Object.values(Plan).map((plan) => {
    return {id: plan, name: plan.toString()}
}))
const BUSINESS_STATUSES: Value[] = [{
    id: "",
    name: "Select status.."
} as Value].concat(Object.values(Status).map((status) => {
    return {id: status, name: status}
}))

export default function BusinessList() {

    const businessService: BusinessService = BusinessServiceImpl.Instance
    const categoryService: CategoryService = CategoryServiceImpl.Instance

    const [businesses, setBusinesses] = useState<Pagination<IPartialBusiness[]>>({} as Pagination<IPartialBusiness[]>)
    const [categories, setCategories] = useState<Value[]>(EMPTY_CATEGORIES)
    const [editBusiness, setEditBusiness] = useState(false)
    const [createBusiness, setCreateBusiness] = useState(false)
    const [subCategories, setSubCategories] = useState<Value[]>(EMPTY_SUBCATEGORIES)
    const [categoryFilter, setCategoryFilter] = useState<string>("")
    const [subCategoryFilter, setSubCategoryFilter] = useState<string>("")
    const [businessPlanFilter, setBusinessPlanFilter] = useState<Plan | null>(null)
    const [businessStatusFilter, setBusinessStatusFilter] = useState<Status | null>(null)
    const [term, setTerm] = useState<string>("");
    const [page, setPage] = useState<number>(0);

    async function findAllBusiness() {
        const resp = await businessService.findAll((term == "") ? null : term, categoryFilter, subCategoryFilter, businessStatusFilter, businessPlanFilter, 30, page)
        setBusinesses(resp)
    }

    async function findAllCategories() {
        const resp = await categoryService.findAll()
        const categories: Value[] = [{
            id: "",
            name: "Select category.."
        } as Value].concat(resp.map((c) => {
            return {name: c.name, id: c.id}
        }))
        setCategories(categories)
    }

    async function findAllSubcategories() {
        const resp = await categoryService.findAllSubCategories()
        const subCategories: Value[] = [{
            id: "",
            name: "Select subcategory.."
        } as Value].concat(resp.map((c) => {
            return {name: c.name, id: c.id}
        }))
        setSubCategories(subCategories)
    }

    function onPageControl(page: number) {
        setPage(page)
    }

    useEffect(() => {
        findAllBusiness().then()
    }, [page, categoryFilter, subCategoryFilter, term, businessPlanFilter, businessStatusFilter])

    useEffect(() => {
        findAllBusiness().then();
        findAllCategories().then()
        findAllSubcategories().then()
    }, [])

    return (
        <div className="py-6 sm:px-6 lg:px-8 px-4">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Businesses
                        ({businesses.totalElements})</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all businesses in the system.
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        onClick={() => setCreateBusiness(true)}
                    >
                        Create business
                    </button>
                </div>
            </div>
            <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 gap-3 mt-5">
                <div className="mt-4">
                    <label className="text-sm">Name</label>
                    <InputText placeholder={
                        "search by name..."
                    } initialValue={term} onChange={(term) => {
                        setTerm(term)
                    }}/>
                </div>
                <div className="mt-4">
                    <label className="text-sm">Category</label>
                    <Select values={categories} onChange={(id) => {
                        if(id == "") {
                            setCategoryFilter("")
                        } else {
                            setCategoryFilter(id)
                        }
                    }}/>
                </div>
                <div className="mt-4">
                    <label className="text-sm">Subcategory</label>
                    <Select values={subCategories} onChange={(id) => {
                        if(id == "") {
                            setSubCategoryFilter("")
                        } else {
                            setSubCategoryFilter(id)
                        }
                    }}/>
                </div>
                <div className="mt-4">
                    <label className="text-sm">Plan</label>
                    <Select values={BUSINESS_PLANS} onChange={(id) => {
                        if(id === "") {
                            setBusinessPlanFilter(null)
                        } else {
                            setBusinessPlanFilter(getBusinessPlan(id))
                        }
                    }}/>
                </div>
                <div className="mt-4">
                    <label className="text-sm">Status</label>
                    <Select values={BUSINESS_STATUSES} onChange={(id) => {
                        if(id === "") {
                            setBusinessStatusFilter(null)
                        } else {
                            setBusinessStatusFilter(getBusinessStatus(id))
                        }
                    }}/>
                </div>
            </div>
            <div className="mt-5 flex flex-col">
                <div className="md:overflow-visible overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                        <tr>
                            <th scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Business
                            </th>
                            <th scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Address
                            </th>
                            <th scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Status
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {(businesses && businesses.content) ? businesses.content.map((business) => (
                            <tr key={business.name}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                    <div className="flex items-center">
                                        <div className="h-10 w-10 flex-shrink-0">
                                            <img className="h-10 w-10 rounded-full"
                                                 src={business.logoThumbnail.url}
                                                 alt={business.logoThumbnail.alt}/>
                                        </div>
                                        <div className="ml-4">
                                            <div className="text-sm font-medium">
                                                {business.name}
                                            </div>
                                            <div
                                                className="text-gray-500">
                                                {business.category.names.PT}
                                                {business.subCategories.map((s: any) => {
                                                    return <span
                                                        className={`text-gray-500`}>
                                                        {' '} - {s.names.PT}
                                                    </span>
                                                })}
                                            </div>

                                        </div>
                                    </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div
                                        className="text-gray-900 truncate max-w-xs">{(business.location?.address != null) ? getPrimaryAddress(business.location.address) : ''}</div>
                                    <div
                                        className="text-gray-500">{business.location?.address?.partialCity ?? ''}</div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <BusinessPlan plan={business.businessPlan}/>
                                    <BusinessStatus status={business.status}/>
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <a onClick={(e) => {
                                        e.preventDefault()
                                        SessionHelper.setBusiness([business])
                                        setEditBusiness(true)
                                    }} href="#"
                                       className="text-indigo-600 hover:text-indigo-900">
                                        Edit<span
                                        className="sr-only">, {business.name}</span>
                                    </a>
                                </td>
                            </tr>
                        )) : <></>}
                        </tbody>
                    </table>
                    <PaginationComp totalPages={businesses.totalPages} currentPage={page}
                                    paginationControl={onPageControl}/>
                    <Modal open={editBusiness} onClose={() => setEditBusiness(false)}>
                        <Settings/>
                    </Modal>
                    <Modal onClose={() => {
                        setCreateBusiness(false)
                    }} open={createBusiness}>
                        <General isCreate={true} onCreated={() => {
                            setCreateBusiness(false)
                        }}/>
                    </Modal>
                </div>
            </div>
        </div>
    )
}


