export enum OrderStatus {
    CREATED = "CREATED",
    WAITING_PAYMENT = "WAITING_PAYMENT",
    PAYMENT_FAILED = "PAYMENT_FAILED",
    WAITING_ACCEPTANCE = "WAITING_ACCEPTANCE",
    IN_PREPARATION = "IN_PREPARATION",
    WAITING_DELIVERY = "WAITING_DELIVERY",
    WAITING_PICKUP = "WAITING_PICKUP",
    COLLECTED = "COLLECTED",
    IN_ROUTE = "IN_ROUTE",
    DELIVERED = "DELIVERED",
    CANCELLED = "CANCELLED",
    COMPLETED = "COMPLETED"
}