import { API } from "../../../common/const/Routes";
import { Pagination } from "../../../common/data/Pagination";
import { httpHelper } from "../../../common/helper/HttpCommon";
import Banner from "../data/Banner";
import BannerService from "./BannerService";

export default class BannerServiceImpl implements BannerService {

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private static _instance: BannerServiceImpl;
    
    async findAll(page: number, size: number): Promise<Pagination<Banner[]>> {
        const params = new URLSearchParams();
        params.append("size", size.toString());
        params.append("page", page.toString());
        return await httpHelper.get<Pagination<Banner[]>>(API.BANNERS, params);
    }

}