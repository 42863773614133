
export class API {
    static readonly LOGIN = "/public/v1/auth/login"
    static readonly REGISTER_SESSION = "/open/v1/sessions"
    static readonly BUSINESS_SEARCH = "/private/v1/admin/business/search"
    static readonly BUSINESS_FIND = "/private/v1/admin/businesses"
    static readonly BUSINESS_CREATE = "/private/v1/businesses"
    static readonly BUSINESS_PUBLIC_FIND = "public/v1/businesses"
    static readonly ORDERS = "private/v1/business/marketplaces/orders"
    static readonly ORDER_ACCEPT = "/private/v1/marketplaces/orders/{orderId}/actions/acceptance"
    static readonly ORDER_REJECT = "/private/v1/marketplaces/orders/{orderId}/actions/reject"
    static readonly ORDER_AS_READY = "/private/v1/marketplaces/orders/{orderId}/actions/ready"
    static readonly ORDER_AS_IN_ROUTE = "/private/v1/marketplaces/orders/{orderId}/actions/in-route"
    static readonly ORDER_AS_DELIVERED = "/private/v1/marketplaces/orders/{orderId}/actions/delivered"
    static readonly ORDER_AS_COLLECTED = "/private/v1/marketplaces/orders/{orderId}/actions/collected"
    static readonly ORDER_AS_COMPLETED = "/private/v1/marketplaces/orders/{orderId}/actions/completed"
    static readonly DELIVERY_TASK = "/private/v1/delivery/tasks/orders/{orderId}"
    static readonly DELIVERY_MULTI_ASK = "/private/v1/delivery/tasks/orders"
    static readonly ORDER_SUMMARY = "/private/v1/business/marketplaces/orders/summary"
    static readonly DRIVERS = "/private/v1/business/drivers"
    static readonly DRIVERS_ADMIN = "/private/v1/admin/delivery/drivers"
    static readonly PLACES = "/public/v1/places"
    static readonly ORDER_ATTACHMENT = "/private/v1/business/marketplaces/orders/attachments"
    static readonly ORDER_DETACHMENT = "/private/v1/business/marketplaces/orders/detachments"
    static readonly ORDER_DRIVERS = "private/v1/business/marketplaces/drivers/{driverId}/orders"
    static readonly ORDERS_STATS = "private/v1/business/marketplaces/orders/stats"
    static readonly CATALOG_SECTIONS = "private/v1/business/marketplaces/catalogs/sections"
    static readonly CATALOG_SECTIONS_FIND = "/private/v1/business/marketplaces/catalogs/sections/{sectionId}"
    static readonly CATALOG_ITEMS = "private/v1/business/marketplaces/catalogs/items"
    static readonly CATALOG_ITEMS_FIND = "/private/v1/business/marketplaces/catalogs/items/{itemId}"
    static readonly MEDIA_UPLOAD = "/public/v2/medias"
    static readonly MARKETPLACE_PROFILE = "/private/v1/business/marketplaces/profiles"
    static readonly ACTIVATE_PROFILE = "/private/v1/marketplaces/profiles"
    static readonly PUSHS = "/private/v1/notifications/scheduled-pushes"
    static readonly TASKS = "/private/v1/admin/delivery/tasks"
    static readonly TASKS_FIND = "/private/v1/delivery/tasks/:taskId"
    static readonly TASKS_V2_FIND = "/private/v2/delivery/tasks/:taskId"
    static readonly TASKS_V2 = "/private/v2/admin/delivery/tasks"
    static readonly TASKS_QUOTES = "/private/v1/delivery/tasks/orders/quotes"
    static readonly EARNINGS = "/private/v1/admin/delivery/earnings"
    static readonly BANNERS = "/private/v1/admin/banners"
    static readonly DRIVERS_WEBSOCKET = "https://socket.staging.conecta55.com"
    static readonly DRIVER_ROLE = "/private/v1/roles/drivers/{email}"
    static readonly CATEGORIES = "/public/v1/categories/"
    static readonly SUBCATEGORIES = "/public/v1/subcategories/"
    static readonly ADDRESS_AUTOCOMPLETE = "/public/v1/addresses/autocomplete"
    static readonly USERS = "/private/v1/users"
}
