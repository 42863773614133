import React, {Fragment, ReactNode} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XIcon} from "@heroicons/react/solid";

interface AlertConfirmationProps {
    title: string,
    description: string,
    open: boolean

    onConfirm(): void

    onClose(): void

    color?: string
    confirmText?: string
    customContent?: ReactNode
}

export default function AlertConfirmation({
                                              title,
                                              description,
                                              open,
                                              onConfirm,
                                              onClose,
                                              color = "red",
                                              confirmText = "Delete",
                                              customContent
                                          }: AlertConfirmationProps) {

    function onSubmit(e: any) {
        e.preventDefault()
        onConfirm()
    }

    return <>
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform w-full overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-md  sm:p-6">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <form onSubmit={onSubmit}
                                      className="space-y-8 divide-y divide-gray-200">
                                    <div
                                        className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5">
                                            <div>
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                                    {title}</h3>
                                                {
                                                    (customContent) ? customContent : null
                                                }
                                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                    {description}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="pt-5 border border-0 border-t">
                                            <div className="flex justify-end">
                                                <button
                                                    onClick={onClose}
                                                    type="button"
                                                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-${color}-600 hover:bg-${color}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500`}
                                                >
                                                    {confirmText}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>
}