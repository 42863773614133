import {Align, EscPosBuilder} from "./EscPosBuilder";

export class EscPosBuilderImpl implements EscPosBuilder {

    protected commands: any[] = []

    init(): this {

        this.commands = []
        this.commands.push('\x1B' + '\x40')

        return this;
    }

    text(text: string): this {
        this.commands.push(text)
        return this
    }

    newline(): this {
        this.commands.push('\x0A')
        return this
    }

    line(text: string): this {
        this.newline().text(text)
        return this
    }

    align(align: Align): this {
        switch (align) {
            case Align.CENTER:
                this.commands.push('\x1B' + '\x61' + '\x31');
                break;
            case Align.RIGHT:
                this.commands.push('\x1B' + '\x61' + '\x32');
                break;
            case Align.LEFT:
                this.commands.push('\x1B' + '\x61' + '\x30');
                break;
        }
        return this;
    }

    bold(onOff: boolean): this {

        if (onOff)
            this.commands.push('\x1B' + '\x45' + '\x0D')
        else
            this.commands.push('\x1B' + '\x45' + '\x0A')

        return this
    }

    textLg(onOff: boolean): this {

        if (onOff)
            this.commands.push('\x1B' + '\x21' + '\x30')
        else
            this.commands.push('\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45')

        return this
    }

    textSm(onOff: boolean): this {

        if (onOff)
            this.commands.push('\x1B' + '\x4D' + '\x31');
        else
            this.commands.push('\x1B' + '\x4D' + '\x30');

        return this
    }

    fullCut(): this {
        this.commands.push('\x1D' + '\x56' + '\x30')
        // this.line("test cut 3")
        // this.newline()
        // this.newline()
        // this.newline()
        // this.newline()
        // this.commands.push('\x1D' + '\x56'  + '\x01')
        // this.line("test cut 4")
        // this.newline()
        // this.newline()
        // this.newline()
        // this.newline()
        // this.commands.push( '\x1D' + '\x56'  + '\x31')

        return this
    }

    partialCut(): this {
        this.commands.push('\x1D' + '\x56' + '\x01')
        return this
    }

    build(): any[] {
        return this.commands
    }

    clear(): this {
        this.commands = []
        return this
    }
}