import {MediaService, MediaType} from "../MediaService";
import MediaResponse from "../../../data/MediaResponse";
import {httpHelper} from "../../../helper/HttpCommon";
import {Pagination} from "../../../data/Pagination";
import CatalogItem from "../../../../core/catalog/data/CatalogItem";
import {API} from "../../../const/Routes";
import UploadResponse from "../../../../core/catalog/data/UploadResponse";

export default class MediaServiceDefaultImpl implements MediaService {

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private static _instance: MediaServiceDefaultImpl

    async upload(type: MediaType, blob: Blob): Promise<MediaResponse> {

        const params = new URLSearchParams();
        params.append("target", type.toString());

        const headers = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        const formData = new FormData();

        const file = new File([blob], "file.png",{
            type: "image/png",
        });

        formData.append('file', file)

        return await httpHelper.post<UploadResponse>(API.MEDIA_UPLOAD, params, formData, headers);
    }

}