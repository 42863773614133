import {Range} from "../../../common/data/Range";

export interface IWorkingHours {
    readonly day: string,
    readonly closed: boolean,
    readonly provided: boolean,
    readonly times?: Range[] | null,
    readonly timeZone: string,
    readonly position?: number,
}

export function defaultWorkingHours(): IWorkingHours[] {
    return [
        {
            day: "MONDAY",
            closed: true,
            provided: true,
        } as IWorkingHours,
        {
            day: "TUESDAY",
            closed: true,
            provided: true,
        } as IWorkingHours,
        {
            day: "WEDNESDAY",
            closed: true,
            provided: true,
        } as IWorkingHours,
        {
            day: "THURSDAY",
            closed: true,
            provided: true,
        } as IWorkingHours,
        {
            day: "FRIDAY",
            closed: true,
            provided: true,
        } as IWorkingHours,
        {
            day: "SATURDAY",
            closed: true,
            provided: true,
        } as IWorkingHours,
        {
            day: "SUNDAY",
            closed: true,
            provided: true,
        } as IWorkingHours,
        {
            day: "HOLIDAY",
            closed: true,
            provided: true,
        } as IWorkingHours,
    ]
}