import {API} from "../../../../common/const/Routes";
import {httpHelper} from "../../../../common/helper/HttpCommon";
import { PlaceService } from "../PlaceService";
import { Place } from "../../data/Place";

export class PlaceServiceDefaultImpl implements PlaceService {

    private static _instance: PlaceServiceDefaultImpl

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    async findAllPlaces(): Promise<Place[]> {
        const params = new URLSearchParams();
        params.append("size", "100");
        return await httpHelper.get<Place[]>(API.PLACES, params);
    }

}