import {Dialog, Disclosure, Menu, Transition} from "@headlessui/react";
import React, {Fragment, ReactNode, useState} from "react";
import {BellIcon, ChevronDownIcon, XIcon} from "@heroicons/react/solid";
import {classNames} from "primereact/utils";
import {BarsIcon} from "primereact/icons/bars";
import {APPLICATION_URL} from "../../common/const/Variables";
import {BookOpenIcon, DeviceMobileIcon, PhotographIcon, ShoppingCartIcon, TagIcon} from "@heroicons/react/outline";
import {BiBuilding} from "@react-icons/all-files/bi/BiBuilding";
import {MdMotorcycle} from "@react-icons/all-files/md/MdMotorcycle";
import {BsBag} from "@react-icons/all-files/bs/BsBag";
import {MdAttachMoney} from "@react-icons/all-files/md/MdAttachMoney";
import {SessionHelper} from "../../common/helper/SessionHelper";
import AdminPerspective from "./AdminPerspective";
import {Routes} from "../../routes/const/Routes";
import {useLocation, useNavigate} from "react-router-dom";
import Lottie from "lottie-react";
import signalLottie from "../../common/lottie/signal.json";

const navigation = [
    {name: 'Orders', path: Routes.ORDERS, current: true, icon: ShoppingCartIcon,},
    {name: 'Catalog', path: Routes.CATALOG, current: false, icon: BookOpenIcon,},
    {name: 'Sections', path: Routes.SECTIONS, current: false, icon: TagIcon,}
]

const adminNavigation = [
    {
        name: 'Pushes',
        path: Routes.PUSHS,
        icon: DeviceMobileIcon,
        current: false
    },
    {
        name: 'Businesses',
        path: Routes.BUSINESSES,
        icon: BiBuilding,
        current: false
    },
    {
        name: 'Drivers',
        path: Routes.DRIVERS,
        icon: MdMotorcycle,
        current: false
    },
    {
        name: 'Tasks',
        path: Routes.TASKS,
        icon: BsBag,
        current: false
    },
    {
        name: 'Drivers Earnings',
        path: Routes.EARNINGS,
        icon: MdAttachMoney,
        current: false
    },
    {
        name: 'Banners',
        path: Routes.BANNERS,
        icon: PhotographIcon,
        current: false,
    }

]

if (SessionHelper.retrieveUserSession()?.isAdminRole()) {
    navigation.push(...adminNavigation);
}

interface Props {
    children: ReactNode,
    title: string
}

export default function NewDashboardBase({children, title}: Props) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80"/>
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5"
                                                onClick={() => setSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </Transition.Child>

                                <div
                                    className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-800 px-6 pb-2 ring-1 ring-white/10">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <img
                                            className="h-8 w-auto"
                                            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                                            alt="Your Company"
                                        />
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul role="list" className="-mx-2 flex-1 space-y-1">
                                            {navigation.map((item) => (
                                                <li key={item.name}>
                                                    <a
                                                        key={item.name}
                                                        href={item.path}
                                                        className={classNames(
                                                            item.path == location.pathname
                                                                ? 'bg-indigo-700 text-white'
                                                                : 'text-gray-400 hover:text-white hover:bg-indigo-700',
                                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                        )}
                                                    >
                                                        <item.icon className="h-6 w-6 shrink-0" aria-hidden="true"/>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div
                className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-10 lg:block lg:w-20 lg:overflow-y-auto lg:bg-blue lg:pb-4">
                <div className="flex h-16 shrink-0 items-center justify-center">
                    <img
                        className="h-12"
                        src={APPLICATION_URL + '/img/conecta-logo.png'}
                        alt="Your Company"
                    />
                </div>
                <nav className="mt-8">
                    <ul role="list" className="flex flex-col items-center space-y-1">
                        {navigation.map((item) => (
                            <li key={item.name}>
                                <a
                                    key={item.name}
                                    href={item.path}
                                    className={classNames(
                                        item.path == location.pathname ? 'bg-indigo-600 text-white' : 'text-gray-400 hover:text-white hover:bg-indigo-600',
                                        'group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold'
                                    )}
                                >
                                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true"/>
                                    <span className="sr-only">{item.name}</span>
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>

            <div className="lg:pl-20">
                <div
                    className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                    <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                            onClick={() => setSidebarOpen(true)}>
                        <span className="sr-only">Open sidebar</span>
                        <BarsIcon className="h-6 w-6" aria-hidden="true"/>
                    </button>
                    {/* Separator */}
                    <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true"/>

                    <div className="flex flex-1 gap-x-4 w-full lg:gap-x-6">
                        <div className="flex md:block flex-1 sm: mx-2 md:mx-0 invisible md:visible">
                        {(SessionHelper.retrieveUserSession()?.isAdminRole() == true) ?
                            <AdminPerspective/> : <></>}
                        </div>
                        <div className="flex items-center gap-x-4 lg:gap-x-6">
                            <button type="button" onClick={() => window.location.reload()}
                                    className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                                <div className="flex">
                                    <span className="mt-1 mr-1 text-sm underline font-medium">Refresh</span>
                                    <span><Lottie style={{width: "30px"}} animationData={signalLottie}
                                                  loop={true}/></span>
                                </div>
                            </button>
                            {/* Separator */}
                            <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true"/>
                            {/* Profile dropdown */}
                            <Menu as="div" className="relative">
                                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                    <span className="sr-only">Open user menu</span>
                                    <img
                                        className="h-8 w-8 rounded-full bg-gray-50"
                                        src={SessionHelper.retrieveUserSession()?.user?.avatar ?? "Name not provided"}
                                        alt=""
                                    />
                                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        {SessionHelper.retrieveUserSession()?.user?.firstName ?? "Name not provided"}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </span>
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        <Menu.Item key={"settings-key"}>
                                            <a
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    navigate(Routes.SETTINGS)
                                                }}
                                                className={classNames(
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                )}
                                                key={"settings-key"}
                                                href={Routes.SETTINGS}
                                            >
                                                Settings
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item key={"settings-key"}>
                                            <a
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    localStorage.removeItem("jwt")
                                                    navigate(Routes.LOGIN)
                                                }}
                                                className={classNames(
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                )}
                                                key={"logout-key"}
                                                href={Routes.SETTINGS}
                                            >
                                                Logout
                                            </a>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>

                <main>
                    {(SessionHelper.retrieveUserSession()?.isAdminRole() == true) ?
                        <div className="flex block md:hidden flex-1 sm: p-2 mx-2 md:mx-0">
                            <div className="visible w-full md:invisible mt-2 border"><AdminPerspective/></div>
                        </div> : <></>}
                    <div>{children}</div>
                </main>
            </div>
        </div>
    )
}