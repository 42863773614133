import clsx from "clsx";
import {BiMapAlt} from "@react-icons/all-files/bi/BiMapAlt";
import OrderAddress from "../OrderAddress";
import Moment from "react-moment";
import React, {useEffect, useState} from "react";
import {OrderSelectable} from "../../data/Order";
import {Pagination} from "../../../../common/data/Pagination";
import Spinner from "../../../../components/Spinner";
import {OrderService} from "../../service/OrderService";
import {OrderServiceDefaultImpl} from "../../service/impl/OrderServiceDefaultImpl";
import {OrderStatus} from "../../data/OrderStatus";
import TaskService from "../../../tasks/service/TaskService";
import TaskServiceImpl from "../../../tasks/service/TaskServiceImpl";
import {toast} from "react-toastify";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { DriverService } from "../../../drivers/service/DriverService";
import { DriverServiceDefaultImpl } from "../../../drivers/service/impl/DriverServiceDefaultImpl";
import Select, {Value} from "../../../../components/Select";

interface RequestTabProps {
    onClose(): void

    initialSelectedOrders: OrderSelectable[]

    selectedDriver: string
}

export default function RequestTab({onClose, initialSelectedOrders, selectedDriver}: RequestTabProps) {

    const orderService: OrderService = OrderServiceDefaultImpl.Instance;
    const taskService: TaskService = TaskServiceImpl.Instance;
    const driverService: DriverService = DriverServiceDefaultImpl.Instance;

    const [orders, setOrders] = useState<Pagination<OrderSelectable[]> | null>(null);
    const [selectedOrders, setSelectedOrders] = useState<OrderSelectable[]>(initialSelectedOrders);
    const [overlay, setOverlay] = useState<boolean>(false);

    useEffect(() => {
        fetchOrders()
    }, []);

    async function fetchOrders() {
        setOverlay(true)
        const orders = await orderService.findAll([OrderStatus.IN_PREPARATION, OrderStatus.WAITING_DELIVERY], 0, 50) as Pagination<OrderSelectable[]>;
        orders.content.map((order) => {
            order.selected = selectedOrders.some((o) => o.id == order.id)
            order.position = selectedOrders.findIndex((o) => o.id == order.id) + 1
        })
        setOrders(orders as Pagination<OrderSelectable[]>);
        setOverlay(false)
    }

    async function requestDelivery() {
        var assignee = selectedDriver == "" ? null : selectedDriver;
        setOverlay(true)
        const orderIds = selectedOrders.map((o) => o.id)
        try {
            await taskService.requestPartnerDelivery(orderIds, assignee)
            setOverlay(false)
            toast("Delivery successfully requested")
            onClose()
        } catch (e) {
            console.error(e)
            toast.error("Error while requesting delivery")
            setOverlay(false)
            onClose()
        }
    }

    function onSelect(o: OrderSelectable): void {
        if (o.selected && orders) {
            setOrders({
                ...orders, content: orders?.content?.map((order) => {
                    if (o.id == order.id) {
                        return {...order, selected: false, position: 0}
                    }

                    if (order.position > o.position) {
                        return {...order, position: order.position - 1}
                    }

                    return order;
                })
            })
            setSelectedOrders(selectedOrders.filter((order) => o.id != order.id))
        } else if (orders) {
            const order = {...o, selected: true, position: selectedOrders.length + 1}
            setOrders({
                ...orders, content: orders?.content?.map((o) => {
                    if (o.id == order.id) {
                        return order;
                    }
                    return o;
                })
            })
            setSelectedOrders([...selectedOrders, order])
        }
    }

    return <>{
        (selectedOrders && selectedOrders?.length > 0) ?
            <div className="grid grid-cols-1 bg-gray-50 lg:grid-cols-3 mt-5">
                <div
                    className={clsx(
                        'border-l gap-1 border-t py-6 px-4 sm:px-6 lg:px-8'
                    )}>
                    <p className="text-sm font-medium leading-6 text-gray-500">Selected Orders</p>
                    <p className="mt-2 flex items-baseline gap-x-2">
                                        <span
                                            className="text-4xl font-semibold tracking-tight">{selectedOrders?.length}</span>
                    </p>
                </div>
                <div
                    className={clsx(
                        'border-l border-t py-6 px-4 sm:px-6 lg:px-8'
                    )}>
                    <p className="text-sm font-medium leading-6 text-gray-500">Postcode(s)</p>
                    <p className="mt-2 flex items-baseline gap-x-2">
                                        <span
                                            className="text-2xl font-semibold tracking-tight">{selectedOrders.map((o) => {
                                            return o.deliveryAddress.postalCode
                                        }).join(", ")}</span>
                    </p>
                </div>
                <div
                    className={clsx(
                        'border-l border-r border-t py-6 px-4 sm:px-6 lg:px-8'
                    )}>
                    <p className="text-sm font-medium leading-6 text-gray-500">Orders</p>
                    <p className="mt-2 flex items-baseline gap-x-2">
                                        <span
                                            className="text-2xl text-indigo-700 font-semibold tracking-tight">
                                            {
                                                selectedOrders?.map((o) => o.orderNumber).join(", ")
                                            }
                                        </span>
                    </p>
                </div>
            </div> : <div className="text-center mb-10 mt-5">
                <BiMapAlt className="mx-auto h-40 w-20 text-gray-400"/>
                <h2 className="mt-2 font-semibold text-gray-900">No orders and routes have been added.</h2>
                <p className="mt-1 text-sm text-gray-500">Get started by creating selecting orders down below on
                    the table.</p>
            </div>
    }
        <form onSubmit={(e) => {
            e.preventDefault()
            requestDelivery()
        }}>
            <div className="h-96 overflow-y-scroll border-t">
                <table className="min-w-full border-l divide-y divide-gray-300">
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {orders?.content ? orders?.content.filter((o) => o.deliveryType == "MARKETPLACE").map((o) => (
                        <tr onClick={() => {
                            onSelect(o)
                        }} className={clsx((o.selected ? "bg-indigo-50" : ""), "cursor-pointer")} key={o.id}>
                            <td className="whitespace-nowrap pl-4 pr-3 text-sm sm:pl-6">
                                <strong className="text-blue">
                                    {o.orderNumber}
                                </strong>
                                <div>
                                    <p className="font-medium text-gray-900">({o.business.name})</p>
                                </div>
                            </td>
                            <td className="whitespace-nowrap text-sm">
                                <div className="flex items-center">
                                    <div className="h-10 w-10 flex-shrink-0">
                                        <img className="h-10 w-10 rounded-full"
                                             src={o.user.avatar} alt=""/>
                                    </div>
                                    <div className="ml-4">
                                        <div
                                            className="font-medium text-gray-900">{(o.user.fullName != "") ? o.user.fullName : "Unknown"}</div>
                                        <div
                                            className="text-gray-500">{(o.user.phoneNumber != "") ? o.user.phoneNumber : "Phone not defined"}</div>
                                    </div>
                                </div>
                            </td>
                            <td className="whitespace-nowrap px-1 py-4 text-sm">
                                <OrderAddress address={o.deliveryAddress}/>
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm ">
                                <strong
                                    className={clsx("pt-2", (o.scheduleType == 'NOW' ? 'text-cyan-700' : 'text-blue'))}>
                                    {o.scheduleType.toLowerCase()}
                                </strong>
                                <div>
                                    <strong>Kitchen Time: </strong>
                                    <Moment date={o.expectedPreparationTime.start}
                                            format="H:mm"/>
                                </div>
                                <div>
                                    <strong>Delivery Time: </strong>
                                    <Moment date={o.expectedCompletionTime.start}
                                            format="H:mm"/> - <Moment
                                    date={o.expectedCompletionTime.end} format="H:mm"/>
                                </div>
                            </td>
                            <td>
                                <button onClick={() => {
                                    onSelect(o)
                                }} type="button"
                                        className={
                                            clsx(
                                                (o.selected) ? "bg-red-600 hover:bg-red-500" : "bg-indigo-500 hover:bg-indigo-500", "rounded-full", "block", "px-2.5", "py-1", "text-sm", "font-semibold", "text-white", " shadow-sm", "focus-visible:outline", "focus-visible:outline-2", "focus-visible:outline-offset-2"
                                            )
                                        }>
                                    {
                                        (o.selected) ? "Remove Route " + o.position : "Select"
                                    }
                                </button>
                            </td>
                        </tr>
                    )) : <Spinner/>}
                    </tbody>
                </table>
            </div>
            <div className="pt-5 border border-0 border-t">
                <div className="flex justify-end">
                    <button
                        onClick={() => {
                            onClose()
                        }}
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Cancel
                    </button>
                    <button
                        disabled={selectedOrders.length == 0}
                        type="submit"
                        className={clsx("ml-3", "inline-flex", "justify-center", "py-2", "px-4", "border", "border-transparent", "shadow-sm", "text-sm", "font-medium", "rounded-md", "text-white", "focus:outline-none", "focus:ring-2", "focus:ring-offset-2", "focus:ring-indigo-500", "bg-indigo-600 hover:bg-indigo-700", (selectedOrders.length == 0) ? "disabled:opacity-50" : "")}
                    >
                        Request Driver
                    </button>
                </div>
            </div>
        </form>
        <LoadingOverlay active={overlay}/>
    </>
}
