import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useEffect, useState} from "react";
import {XIcon} from "@heroicons/react/solid";
import LoadingOverlay from "../../../components/LoadingOverlay";
import InputText from "../../../components/InputText";
import InputTextArea from "../../../components/InputTextArea";
import CatalogOption, {initCatalogOption, initOption} from "../data/CatalogOption";
import InputNumber from "../../../components/InputNumber";
import InputCurrency from "../../../components/InputCurrency";
import {newError} from "../../../common/data/Error";
import {toast} from "react-toastify";

interface AddCatalogOptionsProps {
    open: boolean,
    onClose(): void,
    initialOption: CatalogOption,
    onEdit(option: CatalogOption): void
}

const initErrors = {
    titlePT: {
        error: false,
        message: ""
    },
    titleEN: {
        error: false,
        message: ""
    },
    descriptionPT: {
        error: false,
        message: ""
    },
    descriptionEN: {
        error: false,
        message: ""
    },
    minimum: {
        error: false,
        message: ""
    },
    maximum: {
        error: false,
        message: ""
    },
    order: {
        error: false,
        message: ""
    },
}

export default function EditCatalogOption({open, onClose, onEdit, initialOption}: AddCatalogOptionsProps) {

    const [overlay, setOverlay] = React.useState(false)
    const [option, setOption] = React.useState<CatalogOption>(initialOption)
    const [errors, setErrors] = useState(initErrors)

    useEffect(() => {
        setOption(initialOption)
    }, [])

    function validate(): boolean {

        let hasError = false;

        let copyError = {...errors}

        if (option.names.PT == "" || option.names.PT < 5) {
            copyError = {...copyError, titlePT: newError(true, "Invalid name PT")}
            hasError = true
        }

        if (option.names.EN == "" || option.names.EN < 5) {
            copyError = {...copyError, titleEN: newError(true, "Invalid name EN")}
            hasError = true
        }

        if (option.descriptions.PT == "" || option.descriptions.PT < 5) {
            copyError = {...copyError, descriptionPT: newError(true, "Invalid description PT")}
            hasError = true
        }

        if (option.descriptions.EN == "" || option.descriptions.EN < 5) {
            copyError = {...copyError, descriptionEN: newError(true, "Invalid description EN")}
            hasError = true
        }

        if (option.minimum == null) {
            copyError = {...copyError, minimum: newError(true, "Invalid minimum")}
            hasError = true
        }

        if (option.maximum == null) {
            copyError = {...copyError, maximum: newError(true, "Invalid maximum")}
            hasError = true
        }

        if (option.maximum == null) {
            copyError = {...copyError, maximum: newError(true, "Invalid order")}
            hasError = true
        }

        setErrors(copyError)

        return hasError
    }
    function onSubmit(e: any) {
        if(validate()) {
            toast("Error: Please fill all required fields",{type: "error"})
            return
        }
        e.preventDefault()
        onEdit(option)
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {
                onClose()
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform w-full overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-6xl  sm:p-6">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => {
                                            onClose()
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Edit
                                        Option</h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        Edit catalog product option
                                    </p>
                                </div>
                                <div
                                    className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5 mt-5">
                                            <div
                                                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="username"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Name PT
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <div
                                                        className="flex max-w-lg rounded-md">
                                                        <InputText error={errors.titlePT.error}
                                                                   initialValue={option.names.PT}
                                                                   errorMessage={errors.titlePT.message}
                                                                   placeholder={"Option name PT"}
                                                                   onChange={(e) => {
                                                                       const names = {...option.names, PT: e}
                                                                       setOption({...option, names: names})
                                                                   }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5 mt-5">
                                            <div
                                                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="username"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Name EN
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <div
                                                        className="flex max-w-lg rounded-md">
                                                        <InputText error={errors.titleEN.error}
                                                                   initialValue={option.names.EN}
                                                                   errorMessage={errors.titleEN.message}
                                                                   placeholder={"Option name EN"}
                                                                   onChange={(e) => {
                                                                       const names = {...option.names, EN: e}
                                                                       setOption({...option, names: names})
                                                                   }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5 mt-5">
                                            <div
                                                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="username"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Description PT
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <div
                                                        className="flex max-w-lg rounded-md">
                                                        <InputTextArea
                                                            error={errors.descriptionPT.error}
                                                            placeholder={"Option description PT"}
                                                            initialValue={option.descriptions.PT}
                                                            errorMessage={errors.descriptionEN.message}
                                                            rows={3}
                                                            onChange={(e) => {
                                                                const descriptions = {...option.descriptions, PT: e}
                                                                setOption({...option, descriptions: descriptions})
                                                            }}
                                                        />
                                                    </div>
                                                    <p className="mt-2 text-sm text-gray-500">Write
                                                        a few sentences about product in
                                                        portuguese.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5 mt-5">
                                            <div
                                                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="username"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Description EN
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <div
                                                        className="flex max-w-lg rounded-md">
                                                        <InputTextArea
                                                            error={errors.descriptionPT.error}
                                                            initialValue={option.descriptions.EN}
                                                            errorMessage={errors.descriptionPT.message}
                                                            placeholder={"Option description EN"}
                                                            rows={3}
                                                            onChange={(e) => {
                                                                const descriptions = {...option.descriptions, EN: e}
                                                                setOption({...option, descriptions: descriptions})
                                                            }}
                                                        />
                                                    </div>
                                                    <p className="mt-2 text-sm text-gray-500">Write
                                                        a few sentences about product in
                                                        portuguese.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5 mt-5">
                                            <div
                                                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="username"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Minimum
                                                </label>
                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                    <div
                                                        className="flex max-w-xs rounded-md">
                                                        <InputNumber
                                                            error={errors.minimum.error}
                                                            initialValue={option.minimum}
                                                            placeholder={errors.minimum.message}
                                                            errorMessage={""}
                                                            onChange={(e) => {
                                                                setOption({...option, minimum: e})
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5 mt-5">
                                            <div
                                                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="username"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Maximum
                                                </label>
                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                    <div
                                                        className="flex max-w-xs rounded-md">
                                                        <InputNumber
                                                            error={errors.maximum.error}
                                                            initialValue={option.maximum}
                                                            errorMessage={errors.maximum.message}
                                                            placeholder={"Maximum selection"}
                                                            onChange={(e) => {
                                                                setOption({...option, maximum: e})
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5 mt-5">
                                            <div
                                                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="username"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Order
                                                </label>
                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                    <div
                                                        className="flex max-w-xs rounded-md">
                                                        <InputNumber
                                                            error={errors.order.error}
                                                            placeholder={"Order shown"}
                                                            initialValue={option.order}
                                                            errorMessage={errors.order.message}
                                                            onChange={(e) => {
                                                                setOption({...option, order: e})
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-6 mt-7 pb-7 sm:pt-5 border-t border-b">
                                    <div role="group" aria-labelledby="label-notifications">
                                        <div
                                            className="sm:grid sm:grid-cols-5 sm:items-baseline sm:gap-4">
                                            <div>
                                                <div
                                                    className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                                                    id="label-notifications"
                                                >
                                                    Is Multi?
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <div className="max-w-lg">
                                                    <div className="mt-4 space-y-4">
                                                        <div className="flex items-center">
                                                            <input
                                                                type="radio"
                                                                value="enabled"
                                                                onChange={(e) => {
                                                                    setOption({...option, isMulti: true})
                                                                }}
                                                                checked={option.isMulti === true}
                                                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                            />
                                                            <label htmlFor="push-everything"
                                                                   className="ml-3 block text-sm font-medium text-gray-700">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <input
                                                                id="disabled"
                                                                name="disabled"
                                                                type="radio"
                                                                value="disabled"
                                                                onChange={(e) => {
                                                                    setOption({...option, isMulti: false})
                                                                }}
                                                                checked={option.isMulti === false}
                                                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"/>
                                                            <label htmlFor="push-email"
                                                                   className="ml-3 block text-sm font-medium text-gray-700">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="items-end flex">
                                    <div className="flex-1 mt-8">
                                        <h2 className="text-lg font-medium leading-6 text-gray-900">Select options</h2>
                                        <p className="mt-1 text-sm text-gray-500">
                                            Set select options for this option
                                        </p>
                                    </div>
                                    <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                                        <button
                                            onClick={(e) => {
                                                const options = option.options
                                                options.push(initOption())
                                                setOption({...option, options: options})
                                            }}
                                            type="button"
                                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                        >
                                            New Select Option
                                        </button>
                                    </div>
                                </div>
                                {
                                    option.options.map((o, index) => {
                                        return <>
                                            <div className="space-y-8 sm:space-y-5 border-t mt-5">
                                                <div className="space-y-6 sm:space-y-5">
                                                    <div className="space-y-6 sm:space-y-5 mt-5">
                                                        <div
                                                            className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                            <label htmlFor="username"
                                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                                Name PT
                                                            </label>
                                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                <div
                                                                    className="flex max-w-lg rounded-md">
                                                                    <InputText error={false}
                                                                               placeholder={"Select option name PT"}
                                                                               initialValue={o.names.PT}
                                                                               errorMessage={""}
                                                                               onChange={(e) => {
                                                                                   const o = option.options
                                                                                   o[index].names.PT = e
                                                                                   setOption({...option, options: o})
                                                                               }}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="space-y-6 sm:space-y-5 mt-5">
                                                    <div
                                                        className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                        <label htmlFor="username"
                                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                            Name EN
                                                        </label>
                                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                            <div
                                                                className="flex max-w-lg rounded-md">
                                                                <InputText error={false}
                                                                           errorMessage={""}
                                                                           initialValue={o.names.EN}
                                                                           placeholder={"Select option name EN"}
                                                                           onChange={(e) => {
                                                                               const o = option.options
                                                                               o[index].names.EN = e
                                                                               setOption({...option, options: o})
                                                                           }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="space-y-6 sm:space-y-5 mt-5">
                                                    <div
                                                        className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                        <label htmlFor="username"
                                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                            Description PT
                                                        </label>
                                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                            <div
                                                                className="flex max-w-lg rounded-md">
                                                                <InputText error={false}
                                                                           errorMessage={""}
                                                                           initialValue={o.descriptions.PT}
                                                                           placeholder={"Select description PT"}
                                                                           onChange={(e) => {
                                                                               const o = option.options
                                                                               o[index].descriptions.PT = e
                                                                               setOption({...option, options: o})
                                                                           }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="space-y-6 sm:space-y-5 mt-5">
                                                    <div
                                                        className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                        <label htmlFor="username"
                                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                            Description EN
                                                        </label>
                                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                            <div
                                                                className="flex max-w-lg rounded-md">
                                                                <InputText error={false}
                                                                           errorMessage={""}
                                                                           initialValue={o.descriptions.EN}
                                                                           placeholder={"Select description EN"}
                                                                           onChange={(e) => {
                                                                               const o = option.options
                                                                               o[index].descriptions.EN = e
                                                                               setOption({...option, options: o})
                                                                           }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                                    <div className="space-y-6 sm:space-y-5">
                                                        <div className="space-y-6 sm:space-y-5 mt-5">
                                                            <div
                                                                className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                                <label htmlFor="username"
                                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                                    Price
                                                                </label>
                                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                    <div
                                                                        className="flex max-w-xs rounded-md">
                                                                        <InputCurrency
                                                                            error={false}
                                                                            initialValue={o.price?.toFixed(2) ?? "0"}
                                                                            errorMessage={""}
                                                                            onChange={(e) => {
                                                                                const o = option.options
                                                                                const y: number = +e;
                                                                                o[index].price = y
                                                                                setOption({...option, options: o})
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })}
                                <form onSubmit={onSubmit}>
                                    <div className="pt-5">
                                        <div className="flex justify-end">
                                            <button
                                                onClick={onClose}
                                                type="button"
                                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <LoadingOverlay active={overlay}/>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}