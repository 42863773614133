import {Switch} from "@headlessui/react";
import clsx from "clsx";
import {BusinessService} from "../../../business/service/BusinessService";
import {BusinessServiceImpl} from "../../../business/service/impl/BusinessServiceImpl";
import React, {useEffect, useState} from "react";
import {
    BusinessPlan,
    BusinessStatus,
    getBusinessPlan,
    getBusinessStatus,
    IBusiness,
    newBusiness
} from "../../../business/data/Business";
import InputTextArea from "../../../../components/InputTextArea";
import InputText from "../../../../components/InputText";
import {TagsInput} from "react-tag-input-component";
import GenericFileUpload from "../../../../components/GenericFileUpload";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import {toast} from "react-toastify";
import {CategoryService} from "../../../business/service/CategoryService";
import CategoryServiceImpl from "../../../business/service/impl/CategoryServiceImpl";
import {Value} from "../../../../components/Select";
import SimpleSelect, {Option} from "../../../../components/SimpleSelect";
import MultiSelect from "../../../../components/MultiSelect";
import {SessionHelper} from "../../../../common/helper/SessionHelper";
import {AddressSelect} from "../../../../components/select/dynamic/AddressSelect";
import {IAddress} from "../../../business/data/Address";

const EMPTY_CATEGORIES: Value[] = [{id: "", name: "Select category.."} as Value]
const EMPTY_SUBCATEGORIES: Value[] = [{id: "", name: "Select subcategory.."} as Value]
const COMPANY_STATUSES: Option[] = Object.values(BusinessStatus).map((status) => {
    return {id: status, name: getBusinessStatus(status)}
})
const COMPANY_PLANS: Option[] = Object.values(BusinessPlan).map((plan) => {
    return {id: plan, name: plan.toString()}
})

interface GeneralProps {
    isCreate?: boolean
    onCreated?(business: IBusiness): void
}

export default function General({isCreate = false, onCreated}: GeneralProps) {

    const businessService: BusinessService = BusinessServiceImpl.Instance;
    const categoryService: CategoryService = CategoryServiceImpl.Instance;

    const [categories, setCategories] = useState<Value[]>(EMPTY_CATEGORIES)
    const [subCategories, setSubCategories] = useState<Value[]>(EMPTY_SUBCATEGORIES)
    const [business, setBusiness] = useState<IBusiness | null>(null)
    const [logoModal, setLogoModal] = useState<boolean>(false)
    const [coverModal, setCoverModal] = useState<boolean>(false)
    const [overlay, setOverlay] = useState<boolean>(false)

    useEffect(() => {
        fetchBusiness().then()
        fetchCategories().then()
        fetchSubcategories().then()
    }, [])

    async function fetchBusiness() {
        if (isCreate) {
            setBusiness(newBusiness())
        } else {
            businessService.find().then((business) => {
                setBusiness(business)
            })
        }
    }

    async function fetchCategories() {
        const resp = await categoryService.findAll()
        setCategories(EMPTY_CATEGORIES.concat(resp.map((c) => {
            return {name: c.name, id: c.id}
        })))
    }

    async function fetchSubcategories() {
        const resp = await categoryService.findAllSubCategories()
        setSubCategories(resp.map((c) => {
            return {name: c.name, id: c.id}
        }))
    }

    async function onSubmit(e: any) {
        e.preventDefault();
        try {
            if (business != null) {
                setOverlay(true)
                if(isCreate) {
                    await businessService.create(business)
                    if(onCreated) {
                        onCreated(business)
                    }
                } else {
                    await businessService.update({...business, updateCover: true, contacts: null})
                }
                setOverlay(false)
                toast("Business has been updated successfully")
            }
        } catch (e) {
            setOverlay(false)
            toast.error("Failed to update/create business. Check your input and try again")
        }
    }

    return <>
        {
            (business != null) ?
                <form className="divide-y divide-gray-200 lg:col-span-9" onSubmit={onSubmit}
                      action="#" method="POST">
                    <div className="py-6 px-4 sm:p-6 lg:pb-8">
                        <div>
                            <h2 className="text-lg font-medium leading-6 text-gray-900">General</h2>
                            <p className="mt-1 text-sm text-gray-500">
                                General configuration for your business
                            </p>
                        </div>

                        <div className="mt-6 flex flex-col lg:flex-row">
                            <div className="flex-grow space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Name
                                    </label>
                                    <div className="mt-2">
                                        <InputText
                                            disabled={(SessionHelper.retrieveUserSession()?.isAdminRole() == false)}
                                            initialValue={business.name}
                                            onChange={(v) => {
                                                const businessCopy = {
                                                    ...business,
                                                    name: v
                                                }
                                                setBusiness(businessCopy)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 grid grid-cols-12 gap-6">
                            <div className="col-span-6">
                                <label className="block text-sm font-medium text-gray-700">
                                    Slogan PT
                                </label>
                                <div className="mt-2">
                                    <InputTextArea
                                        onChange={(newValue) => {
                                            const businessCopy = {
                                                ...business,
                                                descriptions: {
                                                    ...business.descriptions,
                                                    PT: newValue
                                                }
                                            }
                                            setBusiness(businessCopy)
                                        }}
                                        initialValue={(business.descriptions) ? business.descriptions.PT : ""}
                                    />
                                </div>
                            </div>
                            <div className="col-span-6">
                                <label className="block text-sm font-medium text-gray-700">
                                    Slogan EN
                                </label>
                                <div className="mt-2">
                                    <InputTextArea
                                        onChange={(newValue) => {
                                            const businessCopy = {
                                                ...business,
                                                descriptions: {
                                                    ...business.descriptions,
                                                    EN: newValue
                                                }
                                            }
                                            setBusiness(businessCopy)
                                        }}
                                        initialValue={(business.descriptions) ? business.descriptions.EN : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 grid grid-cols-12 gap-6">
                            <div className="col-span-6">
                                <label className="block text-sm font-medium text-gray-700">
                                    About PT
                                </label>
                                <div className="mt-2">
                                    <InputTextArea
                                        onChange={(newValue) => {
                                            const businessCopy = {
                                                ...business,
                                                about: {...business.about, PT: newValue}
                                            }
                                            setBusiness(businessCopy)
                                        }}
                                        initialValue={(business.about) ? business.about.PT : ""}
                                    />
                                </div>
                                <p className="mt-2 text-sm text-gray-500">
                                    Brief about description for your profile in portuguese.
                                </p>
                            </div>
                            <div className="col-span-6">
                                <label className="block text-sm font-medium text-gray-700">
                                    About EN
                                </label>
                                <div className="mt-2">
                                    <InputTextArea
                                        onChange={(newValue) => {
                                            const businessCopy = {
                                                ...business,
                                                about: {...business.about, EN: newValue}
                                            }
                                            setBusiness(businessCopy)
                                        }}
                                        initialValue={(business.about) ? business.about.EN : ""}
                                    />
                                </div>
                                <p className="mt-2 text-sm text-gray-500">
                                    Brief about description for your profile in english.
                                </p>
                            </div>
                            <div className="col-span-6">
                                <label className="block text-sm font-medium text-gray-700">
                                    Tags Search (10)
                                </label>
                                <div className="mt-2">
                                    <TagsInput
                                        classNames={{
                                            tag: 'text-sm',
                                            input: 'border-gray-300 focus:border-indigo-500'
                                        }}
                                        beforeAddValidate={(tag: string, existingTags: string[]) => {
                                            return existingTags.length <= 9;
                                        }}
                                        value={business.tags}
                                        onChange={(values) => {
                                            setBusiness({...business, tags: values})
                                        }}
                                        placeHolder="Enter tags"
                                    />
                                </div>
                            </div>
                            <div className="col-span-6">
                                <label className="block text-sm font-medium text-gray-700">
                                    Plan
                                </label>
                                <div className="mt-2">
                                    <SimpleSelect
                                        disabled={(SessionHelper.retrieveUserSession()?.isAdminRole() == false)}
                                        value={business.businessPlan}
                                        options={COMPANY_PLANS} onChange={(p) => {
                                        setBusiness({...business, businessPlan: getBusinessPlan(p)})
                                    }}/>
                                </div>
                                <p className="mt-2 text-sm text-gray-500">
                                    Plans are used to change search results order.
                                </p>
                            </div>
                            <div className="col-span-6">
                                <label className="block text-sm font-medium text-red-700">
                                    Status
                                </label>
                                <div className="mt-2">
                                    <SimpleSelect
                                        disabled={(SessionHelper.retrieveUserSession()?.isAdminRole() == false)}
                                        value={business.status} options={COMPANY_STATUSES}
                                        onChange={(s) => {
                                            setBusiness({
                                                ...business,
                                                status: getBusinessStatus(s)
                                            })
                                        }}/>
                                </div>
                                <p className="mt-2 text-sm text-gray-500">
                                    Inactive companies will not appear on the app.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6 pb-8">
                        <div className="px-4 sm:px-6">
                            <div>
                                <h2 className="text-lg font-medium leading-6 text-gray-900">Address</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    Business address
                                </p>
                            </div>
                            <div className="mt-6 grid grid-cols-12 gap-6">
                                <div className="col-span-12 sm:col-span-12">
                                    <label className="block text-sm mb-1 font-medium text-gray-700">
                                        Find your address
                                    </label>
                                    <AddressSelect onChange={(ref) => {
                                        const addr = {
                                            postCode: ref.postalCode,
                                            city: ref.locality,
                                            street: ref.street,
                                            country: ref.country,
                                            partialCity: "",
                                            addressDefined: true
                                        }
                                        
                                        const coords = {
                                            ...business?.location?.coordinates,
                                            lat: ref.location.latitude,
                                            long: ref.location.longitude
                                        }

                                        const businessCopy = {
                                            ...business,
                                            location: {address: addr, coordinates: coords}
                                        }
                                        
                                        setBusiness(businessCopy)
                                    }}/>
                                </div>
                                <div className="col-span-12 sm:col-span-6">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Street
                                    </label>
                                    <InputText
                                        initialValue={business.location?.address?.street ?? ""}
                                        onChange={(newValue) => {
                                            const addr = (business?.location?.address) ? {
                                                ...business.location.address,
                                                street: newValue
                                            } : {} as IAddress
                                            setBusiness({
                                                ...business,
                                                location: {...business?.location, address: addr}
                                            })
                                        }}
                                        disabled={false}
                                    />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Postcode
                                    </label>
                                    <InputText
                                        initialValue={business.location?.address?.postCode ?? ""}
                                        onChange={(newValue) => {
                                            const addr = (business?.location?.address) ? {
                                                ...business.location.address,
                                                postCode: newValue
                                            } : {} as IAddress
                                            setBusiness({
                                                ...business,
                                                location: {...business?.location, address: addr}
                                            })
                                        }}
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-span-12 sm:col-span-6">
                                    <label className="block text-sm font-medium text-gray-700">
                                        City
                                    </label>
                                    <InputText
                                        initialValue={business.location?.address?.city ?? ""}
                                        onChange={(newValue) => {
                                            const addr = (business?.location?.address) ? {
                                                ...business.location.address,
                                                city: newValue
                                            } : {} as IAddress
                                            setBusiness({
                                                ...business,
                                                location: {...business?.location, address: addr}
                                            })
                                        }}
                                        disabled={true}
                                    />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Country
                                    </label>
                                    <InputText
                                        initialValue={business.location?.address?.country ?? ""}
                                        onChange={(newValue) => {
                                            const addr = (business?.location?.address) ? {
                                                ...business.location.address,
                                                country: newValue
                                            } : {} as IAddress
                                            setBusiness({
                                                ...business,
                                                location: {...business?.location, address: addr}
                                            })
                                        }}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6">
                        <div className="px-4 sm:px-6">
                            <div>
                                <h2 className="text-lg font-medium leading-6 text-gray-900">Logo</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    Upload business logo
                                </p>
                            </div>
                        </div>
                        <div className="col-span-12 p-6">
                            <div className="flex items-center">
                                <span
                                    className="h-20 w-20 overflow-hidden rounded-full bg-gray-100">
                                 <img src={(business.logoThumbnail) ? business.logoThumbnail.url : ""}
                                      alt={(business.logoThumbnail) ? business.logoThumbnail.alt : ""}/>
                                </span>
                                <button
                                    onClick={() => {
                                        setLogoModal(true)
                                    }}
                                    type="button"
                                    className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Change
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6 mb-6">
                        <div className="px-4 sm:px-6">
                            <div>
                                <h2 className="text-lg font-medium leading-6 text-gray-900">Category
                                    and Subcategories</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    Categories and subcategories configuration for your business
                                </p>
                                <div className="mt-6 grid grid-cols-12 gap-6">
                                    <div className="col-span-12 sm:col-span-6">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Category
                                        </label>
                                        {
                                            (business) ? <SimpleSelect value={business.categoryId}
                                                                       options={categories.map((c) => {
                                                                           return {
                                                                               id: c.id,
                                                                               name: c.name
                                                                           } as Option
                                                                       })} onChange={(v) => {
                                                setBusiness({...business, categoryId: v})
                                            }}/> : null
                                        }
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Subcategory
                                        </label>
                                        {
                                            (business && subCategories) ?
                                                <MultiSelect options={subCategories.map((c) => {
                                                    return {value: c.id, label: c.name}
                                                })} values={subCategories.map((c) => {
                                                    return {value: c.id, label: c.name}
                                                }).filter((c) => {
                                                    return (business?.subCategoryIds?.includes(c.value) ?? false)
                                                })} onChange={(v) => {
                                                    setBusiness({
                                                        ...business,
                                                        subCategoryIds: v.map((c) => c.value)
                                                    })
                                                }}/> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6">
                        <div className="px-4 sm:px-6 flex justify-between">
                            <div>
                                <h2 className="text-lg font-medium leading-6 text-gray-900">Profile
                                    Cover</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    Upload profile covers
                                </p>
                            </div>
                            <div>
                                <button
                                    onClick={() => {
                                        setCoverModal(true)
                                    }}
                                    type="button"
                                    className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-indigo-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
                                    Add new cover
                                </button>
                            </div>
                        </div>
                        <div className="col-span-12 p-6 flex space-x-6">
                            {
                                (business.profileCovers) ? business.profileCovers.map((p, i) => {
                                    return <div>
                                <span
                                    className="h-auto w-auto overflow-hidden bg-gray-100">
                                 <img className="rounded-md shadow-md" src={p.url} alt={p.alt}/>
                                </span>
                                        <div className="py-4">
                                            <button
                                                onClick={() => {
                                                    const newProfileCovers = business.profileCovers.filter((_, index) => index !== i);
                                                    setBusiness({
                                                        ...business,
                                                        profileCovers: newProfileCovers
                                                    })
                                                }}
                                                type="button"
                                                className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-red-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                }) : null
                            }
                        </div>
                    </div>
                    <div className="divide-y divide-gray-200 pt-6">
                        <div className="px-4 sm:px-6">
                            <div>
                                <h2 className="text-lg font-medium leading-6 text-gray-900">Marketplace</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    Marketplace configurations.
                                </p>
                            </div>
                            <ul role="list" className="mt-2 divide-y divide-gray-200">
                                <Switch.Group as="li"
                                              className="flex items-center justify-between py-4">
                                    <div className="flex flex-col">
                                        <Switch.Label as="p"
                                                      className="text-sm font-medium text-gray-900"
                                                      passive>
                                            Marketplace enabled
                                        </Switch.Label>
                                        <Switch.Description className="text-sm text-gray-500">
                                            Enable this option to see your store and products
                                        </Switch.Description>
                                    </div>
                                    <Switch
                                        checked={true}
                                        onChange={() => {
                                        }}
                                        className={clsx(
                                            true ? 'bg-teal-500' : 'bg-gray-200',
                                            'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                        )}
                                    >
                          <span
                              aria-hidden="true"
                              className={clsx(
                                  true ? 'translate-x-5' : 'translate-x-0',
                                  'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                              )}
                          />
                                    </Switch>
                                </Switch.Group>
                                <Switch.Group as="li"
                                              className="flex items-center justify-between py-4">
                                    <div className="flex flex-col">
                                        <Switch.Label as="p"
                                                      className="text-sm font-medium text-gray-900"
                                                      passive>
                                            Marketplace preview
                                        </Switch.Label>
                                        <Switch.Description className="text-sm text-gray-500">
                                            Enable your store to be available for preview
                                        </Switch.Description>
                                    </div>
                                    <Switch
                                        checked={true}
                                        onChange={() => {
                                        }}
                                        className={clsx(
                                            true ? 'bg-teal-500' : 'bg-gray-200',
                                            'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                        )}
                                    >
                          <span
                              aria-hidden="true"
                              className={clsx(
                                  true ? 'translate-x-5' : 'translate-x-0',
                                  'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                              )}
                          />
                                    </Switch>
                                </Switch.Group>
                            </ul>
                        </div>
                        <div className="mt-4 flex justify-end py-4 px-4 sm:px-6">
                            <button
                                type="submit"
                                className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-indigo-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                    <GenericFileUpload open={logoModal} onClose={() => {
                        setLogoModal(false)
                    }} onCompleted={
                        (uploadResponse) => {

                            const businessCopy = {
                                ...business,
                                logo: uploadResponse.media,
                                logoThumbnail: uploadResponse.thumbnail
                            }

                            setBusiness(businessCopy)
                        }
                    }/>
                    <GenericFileUpload open={coverModal} onClose={() => {
                        setCoverModal(false)
                    }} onCompleted={(uploadResponse) => {

                        const profileCovers = business.profileCovers
                        profileCovers.push(uploadResponse.media)

                        const businessCopy = {
                            ...business,
                            profileCovers: profileCovers
                        }

                        setBusiness(businessCopy)
                    }} aspect={16 / 9}/>
                    <LoadingOverlay active={overlay}/>
                </form> : null
        }
    </>
}