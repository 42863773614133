import {BusinessStatus as Status} from "../data/Business";

interface StatusProps {
    status: Status
}

export default function BusinessStatus({status} : StatusProps) {
    switch (status) {
        case Status.ACTIVE:
            return <p className="text-sm text-teal-700">Active</p>
        case Status.INACTIVE:
            return <p className="text-sm text-red-700">Inactive</p>
        default:
            return <p className="text-sm text-emerald-700">Active</p>
    }
}