import {APPLICATION_URL} from "../../../common/const/Variables";
import React, {useEffect} from "react";
import {Routes} from "../../../routes/const/Routes";
import {useNavigate} from "react-router-dom";

export default function Address() {

    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.backgroundColor = "#f5f5f5";
    },[])

    return (
        <>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Where is your company based?</h2>
                    <p className="mt-3 text-center text-gray-500">Tell us your business address</p>
                </div>

                <div className="mt-8 mx-auto w-full max-w-sm md:max-w-xl">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-4" action="#" method="POST">
                            <div>
                                <label htmlFor="email"
                                       className="block text-sm font-semibold text-gray-900">
                                    Postcode
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="company"
                                        name="company"
                                        type="text"
                                        autoComplete="company"
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email"
                                       className="block text-sm font-semibold text-gray-700">
                                    Street
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="lastname"
                                        name="lastname"
                                        type="text"
                                        autoComplete="lastname"
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email"
                                       className="block text-sm font-semibold text-gray-700">
                                    Business or building name
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="lastname"
                                        name="lastname"
                                        type="text"
                                        autoComplete="lastname"
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email"
                                       className="block text-sm font-semibold text-gray-700">
                                    City
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="lastname"
                                        name="lastname"
                                        type="text"
                                        autoComplete="lastname"
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email"
                                       className="block text-sm font-semibold text-gray-700">
                                    Country
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="lastname"
                                        name="lastname"
                                        type="text"
                                        autoComplete="lastname"
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(Routes.ONBOARDING_CATEGORY);
                                    }}
                                    type="submit"
                                    className="flex w-full mt-12 justify-center rounded-md border border-transparent bg-indigo-600 py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Next
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}