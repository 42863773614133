import {Media} from "../../../common/data/Media";
import {JwtHelper} from "../../../common/helper/JwtHelper";
import {Value} from "../../../components/MultiSelect";
import Tag from "../../../common/data/Tag";
import {Customization} from "./CatalogOption";
export default interface CatalogItem {
    readonly id: string,
    readonly businessId: string,
    readonly enabled: boolean,
    readonly catalogSectionId: string,
    readonly names: any,
    readonly picture: Media,
    readonly pictureThumb: Media,
    readonly descriptions: any
    readonly order: number,
    readonly currency: string,
    readonly fullPrice?: number | null,
    readonly discount?: number | null,
    readonly price?: number,
    readonly stock?: number | null,
    readonly tags: Tag[],
    readonly created?: Date,
    readonly updated?: Date,
    readonly hasTaxes: boolean,
    readonly customization: Customization | null
}

export function initCatalogItem(): CatalogItem {
    return {
        businessId: JwtHelper.getBusinessId(),
        id: "",
        enabled: false,
        names: {
            PT: "",
            EN: ""
        },
        descriptions: {
            PT: "",
            EN: ""
        },
        picture: {
            url: "https://static.staging.conecta55.com/marketplace/placeholder.png",
            alt: "catalog item",
            blurHash: ""
        } as Media,
        pictureThumb: {
            url: "https://static.staging.conecta55.com/marketplace/placeholder-thumb.png",
            alt: "catalog item thumb",
            blurHash: ""
        } as Media,
        catalogSectionId: "",
        order: 1,
        currency: "GBP",
        fullPrice: 0,
        discount: 0,
        price: 0,
        stock: null,
        tags: [],
        hasTaxes: false,
        customization: null,
    } as CatalogItem
}

export function fromTagToMultiSelection(tags: Tag[]): TagMultiSelection[] {
    return tags.map((tag) => {
        return {
            color: tag.color,
            name: tag.name,
            label: tag.name,
            value: tag.name
        } as TagMultiSelection
    })
}

interface TagMultiSelection extends Tag, Value {}

export const DEFAULT_TAGS_MULTI_SELECTION = [
    {
        name : "Popular",
        value: "Popular",
        label: "Popular",
        color: "#2811AF"
    },
    {
        name : "Vegetarian",
        value: "Vegetarian",
        label: "Vegetarian",
        color: "#09BA4F"
    },
    {
        name : "Vegan",
        value: "Vegan",
        label: "Vegan",
        color: "#1C6A40"
    },
    {
        name : "On sale!",
        value: "On sale!",
        label: "On sale!",
        color: "#671FFF"
    }
] as TagMultiSelection[]
