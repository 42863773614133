import {InputMask as Input} from "primereact/inputmask";
import clsx from "clsx";

interface InputMaskProps {
    mask: string,
    value: string,
    placeholder?: string,
    error?: boolean,
    errorMessage?: string,
    disabled?: boolean,

    onChange(newValue: string): void
}

export default function InputMask({
                                      mask,
                                      value,
                                      onChange,
                                      placeholder = "",
                                      error = false,
                                      errorMessage = "",
                                      disabled = false
                                  }: InputMaskProps) {
    return <div className="relative mt-1 rounded-md w-full text-sm text-black">
        <Input style={{fontSize: "0.875rem",color: "rgba(0,0,0,.8)"}} mask={mask} value={value} onChange={(evt) => {
            onChange(evt.value ?? "")
        }}
               className={clsx((error) ? "border-red-600" : "border-gray-300 ", "block", " w-full", "min-w-0", "flex-1", "rounded-md", "h-10", "focus:border-indigo-500", "focus:ring-indigo-500", "sm:text-sm", (disabled) ? "bg-gray-100" : "")}/>
    </div>
}