import React, {useEffect, useState} from "react";
import {IBusiness} from "../../../../business/data/Business";
import {BusinessService} from "../../../../business/service/BusinessService";
import {BusinessServiceImpl} from "../../../../business/service/impl/BusinessServiceImpl";
import {capitalizeFirstLetter} from "../../../../../common/helper/StringHelper";
import EditWorkingHours from "./EditWorkingHours";
import {defaultWorkingHours, IWorkingHours} from "../../../../business/data/WorkingHours";
import {getTimeStr} from "../../../../../common/helper/DateHelper";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import {toast} from "react-toastify";

export default function WorkingHours() {

    const businessService: BusinessService = BusinessServiceImpl.Instance;

    const [business, setBusiness] = useState<IBusiness | null>(null)
    const [editItem, setEditItem] = useState<IWorkingHours>({} as IWorkingHours)
    const [editOpen, setEditOpen] = useState<boolean>(false)
    const [overlay, setOverlay] = useState<boolean>(false)

    useEffect(() => {
        setOverlay(true)
        fetchBusiness().then((business) => {
            if (business.workingHours.length === 0) {
                business = {...business, workingHours: defaultWorkingHours()}
            }
            setBusiness(business)
            setOverlay(false)
        })
    }, [])

    async function fetchBusiness(): Promise<IBusiness> {
        return await businessService.find()
    }

    function onSubmit(e: any) {
        e.preventDefault();
        if (business != null) {
            setOverlay(true)
            const updatedBusiness = {...business, contacts: null}
            businessService.update(updatedBusiness).then((b) => {
                setBusiness(b)
                setOverlay(false)
                toast("Working times has been successfully updated")
            })
        }
    }

    function renderWorkingHours(w: IWorkingHours) {
        if (w.closed)
            return "Closed";
        else if (!w.provided)
            return "Not Provided";
        else {
            return w.times?.map((t) => {
                return `${getTimeStr(t.open)} - ${getTimeStr(t.close)}`
            }).join("|")
        }
    }

    return <>
        {
            (business != null) ?
                <div className="lg:col-span-9">
                    <form className="divide-y divide-gray-200" onSubmit={onSubmit}>
                        <div className="py-6 px-4 sm:p-6 lg:pb-8">
                            <div>
                                <h2 className="text-lg font-medium leading-6 text-gray-900">Working
                                    Times</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    This information will be displayed publicly so be careful what
                                    you
                                    share.
                                </p>
                            </div>
                        </div>

                        <div className="divide-y divide-gray-200">
                            <div className="px-4 sm:px-6">
                                <ul role="list" className="divide-y divide-gray-200">
                                    {
                                        business.workingHours.map((w, i) => {
                                            w = {...w, position: i}
                                            return <li className="flex items-center py-4">
                                                <div className="flex-1">
                                                    <p className="text-sm font-medium text-gray-900">
                                                        {
                                                            capitalizeFirstLetter(w.day)
                                                        }
                                                    </p>
                                                    <p className="text-sm text-gray-500">
                                                        {
                                                            renderWorkingHours(w)
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <button
                                                        onClick={() => {
                                                            setEditOpen(true)
                                                            setEditItem(w)
                                                        }}
                                                        type="button"
                                                        className="rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Edit
                                                    </button>
                                                </div>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="flex justify-end py-4 px-4 sm:px-6">
                                <button
                                    type="submit"
                                    className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-indigo-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                        <LoadingOverlay active={overlay}/>
                    </form>
                    <EditWorkingHours key={editItem.day} onClose={() => {
                        setEditOpen(false)
                    }} open={editOpen} initialItem={editItem} onChange={(workingHours) => {
                        const newWorkingTimes = business?.workingHours
                        newWorkingTimes[workingHours?.position ?? 0] = workingHours
                        setBusiness({...business, workingHours: newWorkingTimes})
                        setEditOpen(false)
                    }}/>
                </div> : <div className="h-96">
                    <LoadingOverlay active={true}/>
                </div>
        }
    </>
}