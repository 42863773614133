import { useEffect, useState } from "react";
import BannerService from "./service/BannerService";
import Banner from "./data/Banner";
import { Pagination as PaginationData } from "../../common/data/Pagination";
import DataTable from 'react-data-table-component';
import moment from "moment";
import BannerServiceImpl from "./service/BannerServiceImpl";

export default function Banners() {

    const bannerService: BannerService = BannerServiceImpl.Instance;
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [overlay, setOverlay] = useState<boolean>(false);
    const [banners, setBanners] = useState<PaginationData<Banner[]>>({} as PaginationData<Banner[]>);

    useEffect(() => {
        findAll(page, pageSize);
    }, [])

    function findAll(page: number, pageSize: number): void {
        setOverlay(true)
        bannerService.findAll(page - 1, pageSize).then((banners) => {
            setBanners(banners);
            setOverlay(false);
        })
    }

    
const columns = [
    {
        name: 'Business',
        selector: (row: Banner) => row.business.name,
    },
    {
        name: 'Location',
        width: "100px",
        selector: (row: Banner) => row.locations[0],
    },
    {
        name: 'Updated',
        width: "200px",
        selector: (row: Banner) => moment(row.updated).format("YYYY-MM-DD HH:MM:SS"),
    },
    {
        name: 'Drive Key',
        width: "400px",
        selector: (row: Banner) => row.bannerKey,
    },
    {
        name: 'Image',
        cell: (row: Banner) => <img src={row.banner.url}/>
    }
];


    const handlePerRowsChange =  (pageSize: number, page: number) => {
        setPageSize(pageSize);
        findAll(page, pageSize);
	};

    const handlePageChange = (page: number) => {
        setPage(page);
        findAll(page, pageSize);
	};

    return (
        <DataTable
			columns={columns}
			data={banners.content}
			progressPending={overlay}
			pagination
			paginationServer
			paginationTotalRows={banners.totalElements}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
		/>
    );
}