import { TimeRange } from "../../../common/data/TimeRange";
import Address from "../../orders/data/Address";
import {useState} from "react";

export default interface TaskV2 {
    readonly id: string;
    readonly expectedPreparationTime: TimeRange;
    readonly expectedDeliveryTime: TimeRange;
    readonly status: TaskStatus;
    readonly deliveryPrice: DeliveryPrice
    readonly routes: Route[]
    readonly distance: number
    readonly orderIds: string[]
    readonly scheduledAfter: Date
    readonly orders: TaskOrder[]
    readonly cancellable: boolean,
    readonly adminCancellable: boolean,
    readonly taskDetails: TaskDetails
}

export interface DeliveryPrice {
    readonly business: number
    readonly driver: number
}


export interface TaskOrder {
    readonly id: string,
    readonly orderNumber: number,
}
export interface Route {
    readonly type: RouteType,
    readonly pickup: RoutePickup;
    readonly delivery: RouteDelivery;
    readonly orders: PartialOrder[]
}

export interface TaskDetails {
    distanceInKm: number,
    durationInMinutes: number
}

export enum RouteType {
    PICKUP, DELIVERY
}

export interface RoutePickup {
    readonly business: PartialBusiness,
    readonly orders: PartialOrder[]
    readonly address: Address
}

export enum TaskStatus {
    SCHEDULED="SCHEDULED",CREATED="CREATED",ASSIGNED="ASSIGNED",WAITING_ASSIGNMENT="WAITING_ASSIGNMENT",COLLECTED="COLLECTED",DELIVERED="DELIVERED",CANCELLED="CANCELLED",NO_ASSIGNEE_AVAILABLE="NO_ASSIGNEE_AVAILABLE",PENDING_CONFIRMATION="PENDING_CONFIRMATION"
}


export interface RouteDelivery {
    readonly address: Address
}


export interface PartialOrder {
    readonly id: string,
    readonly orderNumber: number,
    readonly status: string,
    readonly deliveryPrice: number
}

export interface Quote {
    price: number
}

export interface PartialBusiness {
    name: string
}