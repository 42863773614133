import React, {useEffect, useState} from "react";
import clsx from "clsx";


interface InputProps {
    onChange(newValue: string): void

    initialValue?: string
    placeholder?: string,
    error?: boolean,
    errorMessage?: string,
    disabled?: boolean,
    key?: string,
}

export default function InputText({
                                      onChange,
                                      initialValue,
                                      placeholder = "",
                                      error = false,
                                      errorMessage = "",
                                      disabled = false
                                  }: InputProps) {

    return <div className="relative mt-1 rounded-md w-full">
        <input
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => {
                onChange(e.target.value)
            }}
            value={initialValue}
            type="text"
            className={clsx((error) ? "border-red-600" : "border-gray-300 ", "block", " w-full", "min-w-0", "flex-1", "rounded-md", "focus:border-indigo-500", "focus:ring-indigo-500", "sm:text-sm", (disabled) ? "bg-gray-100" : "")}/>
        {
            (error && errorMessage != "") ? <p className="mt-2 text-sm text-red-600">
                {errorMessage}
            </p> : <></>
        }
    </div>
}

