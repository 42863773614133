import {BusinessPlan as Plan} from "../data/Business";

interface PlanProps {
    plan: Plan
}

export default function BusinessPlan({plan}: PlanProps) {
    switch (plan) {
        case Plan.NONE:
            return <p className="text-sm font-bold text-neutral-700">No Plan</p>
        case Plan.STANDARD:
            return <p className="text-sm text-neutral-700">Plan Standard</p>
        case Plan.PROFESSIONAL:
            return <p className="text-sm text-neutral-700">Plan Professional</p>
        case Plan.BUSINESS:
            return <p className="text-sm text-neutral-700">Plan Business</p>
        default:
            return <p className="text-sm text-neutral-800 font-bold">Plan Not Defined</p>
    }
}