import React, {useEffect, useState} from "react";
import clsx from "clsx";

interface NullableInputNumberProps {
    onChange(newValue: number | null): void
    initialValue?: number | null
    error?: boolean,
    errorMessage?: string
}

export default function NullableInputNumber({
                                      onChange,
                                      initialValue,
                                      error = false,
                                      errorMessage = ""
                                  }: NullableInputNumberProps) {

    const [value, setValue] = useState<number | null>((initialValue) ? initialValue : null);

    useEffect(() => {
        onChange(value)
    }, [value])

    return <div className="relative mt-1 rounded-md">
        <input onChange={(e) => {
            setValue(Number(e.target.value))
        }}
               value={value || ''}
               type="text"
               inputMode="numeric"
               pattern="\d*"
               min={0}
               className={clsx((error) ? "border-red-600" : "border-gray-300 ", "block", " w-full", "min-w-0", "flex-1", "rounded-md", "focus:border-indigo-500", "focus:ring-indigo-500", "sm:text-sm")}/>

        {
            (error && errorMessage != "") ? <p className="mt-2 text-sm text-red-600">
                {errorMessage}
            </p> : <></>
        }
    </div>
}