import { Switch } from "@headlessui/react";
import clsx from "clsx";
import { MarketplaceProfileServiceImpl } from "../../../service/impl/MarketplaceProfileServiceImpl";
import { MarketplaceProfileService } from "../../../service/MarketplaceProfileService";
import React, { useEffect, useState } from "react";
import MarketplaceProfile, { DeliveryConfigurationItem, Feature } from "../../../data/MarketplaceProfile";
import EditDeliveryConfigurationItem from "./EditDeliveryConfigurationItem";
import AddDeliveryConfigurationItem from "./AddDeliveryConfigurationItem";
import { capitalizeFirstLetter } from "../../../../../common/helper/StringHelper";
import AlertConfirmation from "../../../../../components/AlertConfirmation";
import { toast } from "react-toastify";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import InputNumber from "../../../../../components/InputNumber";
import { TagsInput } from "react-tag-input-component";
import SimpleEmptyState from "../../../../../components/SimpleEmptyState";
import InputText from "../../../../../components/InputText";

export default function Delivery() {

    const marketplaceProfileService: MarketplaceProfileService = MarketplaceProfileServiceImpl.Instance

    const [marketplaceProfile, setMarketplaceProfile] = useState<MarketplaceProfile>({} as MarketplaceProfile)
    const [item, setItem] = useState<DeliveryConfigurationItem | null>(null)

    const [noMarketplaceProfile, setNoMarketplaceProfile] = useState<boolean>(false)

    const [editOpen, setEditOpen] = useState<boolean>(false)
    const [addOpen, setAddOpen] = useState<boolean>(false)

    const [removeOpen, setRemoveOpen] = useState<boolean>(false)
    const [removeItem, setRemoveItem] = useState<DeliveryConfigurationItem | null>(null)

    const [overlay, setOverlay] = useState<boolean>(false)

    useEffect(() => {
        fetchProfile().then((marketplaceProfile) => {
            setMarketplaceProfile(marketplaceProfile);
        }).catch((error) => {
            if (error.response.status === 404) {
                setNoMarketplaceProfile(true)
            }
        })
    }, [])

    async function fetchProfile(): Promise<MarketplaceProfile> {
        return marketplaceProfileService.find()
    }

    function setupProfile() {
        setOverlay(true)
        const profile = {} as MarketplaceProfile
        marketplaceProfileService.create(profile).then(result => {
            setOverlay(false)
            toast("Default profile created")
            setMarketplaceProfile(result)
        })
    }

    async function updateProfile(): Promise<MarketplaceProfile> {
        return await marketplaceProfileService.update(marketplaceProfile)
    }

    function activateProfile() {
        setOverlay(true)
        marketplaceProfileService.update({ enableBillingAccount: true } as unknown as MarketplaceProfile)
            .then(result => {
                setOverlay(false)
                toast("Billing account requested")
                window.open(result.billingOnboardUrl, '_blank', 'noreferrer');
            })
    }

    function onSubmit(e: any) {
        setOverlay(true)
        e.preventDefault()
        updateProfile().then(() => {
            setOverlay(false)
            toast("Delivery has been successfully updated")
        })
    }

    function removeDeliveryConfig() {
        const items = marketplaceProfile.deliveryConfiguration.items
        items.splice(removeItem?.position ?? 0, 1)
        const deliveryConfiguration = { ...marketplaceProfile.deliveryConfiguration, items: items }
        setMarketplaceProfile({ ...marketplaceProfile, deliveryConfiguration: deliveryConfiguration })
        setRemoveOpen(false)
    }

    function editDeliveryConfig(item: DeliveryConfigurationItem) {
        const items = marketplaceProfile.deliveryConfiguration.items
        items[item.position ?? 0] = item
        const deliveryConfiguration = { ...marketplaceProfile.deliveryConfiguration, items: items }
        setMarketplaceProfile({ ...marketplaceProfile, deliveryConfiguration: deliveryConfiguration })
        setEditOpen(false)
    }

    function addDeliveryConfig(item: DeliveryConfigurationItem) {
        const items = marketplaceProfile.deliveryConfiguration.items
        items.push(item)
        const deliveryConfiguration = { ...marketplaceProfile.deliveryConfiguration, items: items }
        setMarketplaceProfile({ ...marketplaceProfile, deliveryConfiguration: deliveryConfiguration })
        setAddOpen(false)
    }

    useEffect(() => {
    }, [marketplaceProfile])

    if (noMarketplaceProfile)
        return <div className="lg:col-span-9 mt-10">
            <SimpleEmptyState description="You don't have a marketplace profile yet"
                subDescription="Create a default profile clicking in the button bellow!" />
            <button
                onClick={(e) => {
                    setupProfile()
                    e.preventDefault()
                }}
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
                Setup Profile
            </button>
        </div>

    return <> {
        (marketplaceProfile.businessId && !noMarketplaceProfile) ?
            <div className="lg:col-span-9">
                <form className="divide-y divide-gray-200" action="#" method="POST"
                    onSubmit={onSubmit}>
                    <div className="py-6 px-4 sm:p-6 lg:pb-8">
                        <div>
                            <h2 className="text-lg font-medium leading-6 text-gray-900">Delivery</h2>
                            <p className="mt-1 text-sm text-gray-500">
                                This information will be displayed publicly so be careful what you
                                share.
                            </p>
                        </div>
                    </div>
                    <div className="divide-y divide-gray-200">
                        <div className="px-4 sm:px-6">
                            <ul role="list" className="mt-2 divide-y divide-gray-200">
                                <Switch.Group as="li"
                                    className="flex items-center justify-between py-4">
                                    <div className="flex flex-col">
                                        <Switch.Label as="p"
                                            className="text-sm font-medium text-gray-900"
                                            passive>
                                            Delivery
                                        </Switch.Label>
                                        <Switch.Description className="text-sm text-gray-500">
                                            This option enable/disable delivery as an option
                                        </Switch.Description>
                                    </div>
                                    <Switch
                                        checked={marketplaceProfile.deliveryEnabled}
                                        onChange={() => {
                                            setMarketplaceProfile(
                                                {
                                                    ...marketplaceProfile,
                                                    deliveryEnabled: (!marketplaceProfile.deliveryEnabled)
                                                }
                                            )
                                        }}
                                        className={clsx(
                                            marketplaceProfile.deliveryEnabled ? 'bg-teal-500' : 'bg-gray-200',
                                            'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                        )}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={clsx(
                                                marketplaceProfile.deliveryEnabled ? 'translate-x-5' : 'translate-x-0',
                                                'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                            )}
                                        />
                                    </Switch>
                                </Switch.Group>
                                <Switch.Group as="li"
                                    className="flex items-center justify-between py-4">
                                    <div className="flex flex-col">
                                        <Switch.Label as="p"
                                            className="text-sm font-medium text-gray-900"
                                            passive>
                                            Delivery Now
                                        </Switch.Label>
                                        <Switch.Description className="text-sm text-gray-500">
                                            This option enables users order by delivery anytime
                                            between
                                            the
                                            working hours
                                        </Switch.Description>
                                    </div>
                                    <Switch
                                        checked={marketplaceProfile.deliveryNowEnabled}
                                        onChange={() => {
                                            setMarketplaceProfile(
                                                {
                                                    ...marketplaceProfile,
                                                    deliveryNowEnabled: (!marketplaceProfile.deliveryNowEnabled)
                                                }
                                            )
                                        }}
                                        className={clsx(
                                            marketplaceProfile.deliveryNowEnabled ? 'bg-teal-500' : 'bg-gray-200',
                                            'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                        )}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={clsx(
                                                marketplaceProfile.deliveryNowEnabled ? 'translate-x-5' : 'translate-x-0',
                                                'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                            )}
                                        />
                                    </Switch>
                                </Switch.Group>
                                <Switch.Group as="li"
                                    className="flex items-center justify-between py-4">
                                    <div className="flex flex-col">
                                        <Switch.Label as="p"
                                            className="text-sm font-medium text-gray-900"
                                            passive>
                                            Partners Delivery
                                        </Switch.Label>
                                        <Switch.Description className="text-sm text-gray-500">
                                            Enable Conecta Partners Delivery
                                        </Switch.Description>
                                    </div>
                                    <Switch
                                        checked={marketplaceProfile.features.includes(Feature.PARTNERS_DELIVERY.toString())}
                                        onChange={(e: boolean) => {
                                            var features = marketplaceProfile.features;
                                            if (e) {
                                                features.push(Feature.PARTNERS_DELIVERY.toString())
                                            } else {
                                                const index = features.indexOf(Feature.PARTNERS_DELIVERY.toString())
                                                if (index > -1) {
                                                    features.splice(index, 1);
                                                }
                                            }
                                            setMarketplaceProfile(
                                                {
                                                    ...marketplaceProfile,
                                                }
                                            )
                                        }}
                                        className={clsx(
                                            marketplaceProfile.features.includes(Feature.PARTNERS_DELIVERY.toString()) ? 'bg-teal-500' : 'bg-gray-200',
                                            'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                        )}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={clsx(
                                                marketplaceProfile.features.includes(Feature.PARTNERS_DELIVERY.toString()) ? 'translate-x-5' : 'translate-x-0',
                                                'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                            )}
                                        />
                                    </Switch>
                                </Switch.Group>
                                <Switch.Group as="li"
                                    className="flex items-center justify-between py-4">
                                    <div className="flex flex-col">
                                        <Switch.Label as="p"
                                            className="text-sm font-medium text-gray-900"
                                            passive>
                                            Pick-up
                                        </Switch.Label>
                                        <Switch.Description className="text-sm text-gray-500">
                                            This option enables pick up as an option
                                        </Switch.Description>
                                    </div>
                                    <Switch
                                        checked={marketplaceProfile.pickupEnabled}
                                        onChange={() => {
                                            setMarketplaceProfile(
                                                {
                                                    ...marketplaceProfile,
                                                    pickupEnabled: (!marketplaceProfile.pickupEnabled)
                                                }
                                            )
                                        }}
                                        className={clsx(
                                            marketplaceProfile.pickupEnabled ? 'bg-teal-500' : 'bg-gray-200',
                                            'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                        )}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={clsx(
                                                marketplaceProfile.pickupEnabled ? 'translate-x-5' : 'translate-x-0',
                                                'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                            )}
                                        />
                                    </Switch>
                                </Switch.Group>
                                <Switch.Group as="li"
                                    className="flex items-center justify-between py-4">
                                    <div className="flex flex-col">
                                        <Switch.Label as="p"
                                            className="text-sm font-medium text-gray-900"
                                            passive>
                                            Scheduled
                                        </Switch.Label>
                                        <Switch.Description className="text-sm text-gray-500">
                                            This option enables scheduled orders
                                        </Switch.Description>
                                    </div>
                                    <Switch
                                        checked={marketplaceProfile.scheduleEnabled}
                                        onChange={() => {
                                            setMarketplaceProfile(
                                                {
                                                    ...marketplaceProfile,
                                                    scheduleEnabled: (!marketplaceProfile.scheduleEnabled)
                                                }
                                            )
                                        }}
                                        className={clsx(
                                            marketplaceProfile.scheduleEnabled ? 'bg-teal-500' : 'bg-gray-200',
                                            'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
                                        )}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={clsx(
                                                marketplaceProfile.scheduleEnabled ? 'translate-x-5' : 'translate-x-0',
                                                'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                            )}
                                        />
                                    </Switch>
                                </Switch.Group>
                            </ul>
                        </div>
                        <div className="px-6 py-4 lg:pb-8 items-end lg:flex flex-none sm:flex">
                            <div className="flex-1">
                                <h2 className="text-lg font-medium leading-6 text-gray-900">Email</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    This is profile email
                                </p>
                            </div>
                            <div className="flex-grow space-y-6">
                                <div>
                                    <div className="mt-2">
                                        <InputText
                                            initialValue={marketplaceProfile.email}
                                            onChange={(newValue) => {
                                                setMarketplaceProfile({
                                                    ...marketplaceProfile,
                                                    email: newValue
                                                })
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-4 lg:pb-8 items-end lg:flex flex-none sm:flex">
                            <div className="flex-1">
                                <h2 className="text-lg font-medium leading-6 text-gray-900">Billing Status</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    (It may take some time to reflect the status in case you already onboarded)
                                </p>
                            </div>
                            <div className="mt-6 grid grid-cols-12 gap-6">
                                <div className="col-span-6">
                                    <InputText
                                        initialValue={marketplaceProfile.billingStatus}
                                        disabled={true} onChange={() => { }} />
                                </div>
                                <div className="col-span-6">
                                    <div className="sm:mt-0 sm:ml-16 sm:flex-none space-y-3">
                                        <button
                                            disabled={marketplaceProfile.billingStatus === "ENABLED"}
                                            onClick={(e) => {
                                                activateProfile()
                                                e.preventDefault()
                                            }}
                                            type="button"
                                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                        >
                                            Activate Billing Profile
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-4 lg:pb-8 items-end lg:flex flex-none sm:flex">
                            <div className="flex-1">
                                <h2 className="text-lg font-medium leading-6 text-gray-900">Preparation
                                    Configuration</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    This is the preparation time range for your order
                                </p>
                            </div>
                            <div className="grid grid-cols-2 gap-6 mt-3">
                                <div>
                                    <label className="text-sm">Start</label>
                                    <InputNumber onChange={(newValue) => {
                                        const preparationRange = {
                                            ...marketplaceProfile.orderConfiguration.preparationRange,
                                            start: newValue
                                        }
                                        setMarketplaceProfile({
                                            ...marketplaceProfile,
                                            orderConfiguration: {
                                                ...marketplaceProfile.orderConfiguration,
                                                preparationRange
                                            }
                                        })
                                    }}
                                        initialValue={marketplaceProfile.orderConfiguration.preparationRange.start} />
                                </div>
                                <div>
                                    <label className="text-sm">End
                                    </label>
                                    <InputNumber
                                        initialValue={marketplaceProfile.orderConfiguration.preparationRange.end}
                                        onChange={(newValue) => {
                                            const preparationRange = {
                                                ...marketplaceProfile.orderConfiguration.preparationRange,
                                                end: newValue
                                            }
                                            setMarketplaceProfile({
                                                ...marketplaceProfile,
                                                orderConfiguration: {
                                                    ...marketplaceProfile.orderConfiguration,
                                                    preparationRange
                                                }
                                            })
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-4 lg:pb-8 items-end lg:flex flex-none sm:flex">
                            <div className="flex-1">
                                <h2 className="text-lg font-medium leading-6 text-gray-900">Printer</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    Printer name configuration
                                </p>
                            </div>
                            <div className="grid grid-cols-1 gap-6 mt-3">
                                <div className="col-1 w-full md:w-96">
                                    <label className="text-sm">Printer Name</label>
                                    <InputText onChange={(newValue) => {
                                        setMarketplaceProfile({
                                            ...marketplaceProfile, printerName : newValue
                                        })
                                    }}
                                               initialValue={marketplaceProfile.printerName ?? ""} />
                                </div>
                            </div>
                        </div>
                        <div className="py-6 px-4 sm:p-6 lg:pb-8 items-end flex">
                            <div className="flex-1 mt-8">
                                <h2 className="text-lg font-medium leading-6 text-gray-900">Distance
                                    Configuration</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    This information will help to calculate the distance and prices
                                    for
                                    final routes
                                </p>
                            </div>
                            <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setAddOpen(true)
                                    }}
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    New Distance
                                </button>
                            </div>
                        </div>
                        {
                            (marketplaceProfile.deliveryConfiguration) ?
                                <ul role="list" className="divide-y divide-gray-200">
                                    {
                                        marketplaceProfile.deliveryConfiguration.items.map((i, index) => {
                                            return <li
                                                className="px-6 py-4 text-sm flex items-center">
                                                <div className="flex-1">
                                                    {`${i.distanceRange.start} - ${i.distanceRange.end} ${capitalizeFirstLetter(i.distanceRange.measure.toString())}`}
                                                    <p className="text-gray-500 text-sm">
                                                        {
                                                            `${i.timeRange.start}-${i.timeRange.end} min · £${i.price}`
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setItem({ ...i, position: index })
                                                            setEditOpen(true)
                                                        }}
                                                        type="button"
                                                        className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setRemoveItem({ ...i, position: index })
                                                            setRemoveOpen(true)
                                                        }}
                                                        type="button"
                                                        className="rounded-md bg-white font-medium ml-4 text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </li>
                                        })
                                    }
                                </ul> : <></>
                        }
                        <div className="mt-4 flex justify-end py-4 px-2 sm:px-6">
                            <button
                                type="submit"
                                className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-indigo-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </form>
                <LoadingOverlay active={overlay} />

                <AlertConfirmation
                    title="Do you confirm this operation"
                    description="Are you sure you want to delete this item"
                    open={removeOpen}
                    onConfirm={removeDeliveryConfig}
                    onClose={() => {
                        setRemoveOpen(false)
                    }
                    } />

                <AddDeliveryConfigurationItem onChange={addDeliveryConfig} open={addOpen}
                    onClose={() => {
                        setAddOpen(false)
                    }} />

                {
                    (item) ?
                        <EditDeliveryConfigurationItem
                            key={`${item.distanceRange.start}-${item.price}`}
                            onChange={editDeliveryConfig}
                            initialConfigurationItem={item} onClose={() => {
                                setEditOpen(false)
                            }} open={editOpen} /> : null
                }
            </div> : <div className="h-96">
                <LoadingOverlay active={true} />
            </div>
    }
    </>
}