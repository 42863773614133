import CurrencyInput from "react-currency-input-field";
import React, {useEffect, useState} from "react";
import clsx from "clsx";

interface InputCurrency {
    onChange(newValue: string): void

    initialValue?: string
    error?: boolean,
    rows?: number,
    errorMessage?: string
}

export default function InputCurrency({
                                          onChange,
                                          initialValue,
                                          error,
                                          rows,
                                          errorMessage
                                      }: InputCurrency) {

    const [value, setValue] = useState<string>((initialValue) ? initialValue : "");

    useEffect(() => {
        onChange(value)
    }, [value])

    return <div className="relative mt-1 rounded-md">
        <CurrencyInput placeholder="Enter the price"
                       defaultValue={value}
                       decimalsLimit={2}
                       decimalSeparator={"."}
                       disableGroupSeparators={true}
                       className={clsx((error) ? "border-red-600" : "border-gray-300 ", "block", " w-full", "min-w-0", "flex-1", "rounded-md", "focus:border-indigo-500", "focus:ring-indigo-500", "sm:text-sm")}
                       onValueChange={(value, name) => {
                           setValue((value) ? value : "")
                       }}
        />
        {
            (error && errorMessage != "") ? <p className="mt-2 text-sm text-red-600">
                {errorMessage}
            </p> : <></>
        }
    </div>
}