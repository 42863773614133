import {OrderService} from "../../service/OrderService";
import {OrderServiceDefaultImpl} from "../../service/impl/OrderServiceDefaultImpl";
import {OrderStatus} from "../../data/OrderStatus";
import React, {Fragment, ReactNode, useContext, useEffect, useState} from "react";
import OrdersList from "../OrdersList";
import {Pagination} from "../../../../common/data/Pagination";
import {Order, OrderSelectable} from "../../data/Order";
import {Menu, Transition} from "@headlessui/react";
import classNames from "../../../../common/helper/CssHelper";
import {CheckIcon, ChevronDownIcon, PrinterIcon, TruckIcon, XIcon} from "@heroicons/react/solid";
import {toast} from "react-toastify";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import PrinterHelper from "../../helper/PrinterHelper";
import {PrinterConfigContext} from "../../../../context/printer/PrinterConfigContext";
import {FaBicycle} from "@react-icons/all-files/fa/FaBicycle";
import AlertConfirmation from "../../../../components/AlertConfirmation";
import TaskService from "../../../tasks/service/TaskService";
import TaskServiceImpl from "../../../tasks/service/TaskServiceImpl";
import RequestDelivery from "../RequestDelivery";
import { SessionHelper } from "../../../../common/helper/SessionHelper";

interface PreparingProps {
    onChangeState(): void
}

export default function Preparing({onChangeState}: PreparingProps) {

    const orderService: OrderService = OrderServiceDefaultImpl.Instance;
    const taskService: TaskService = TaskServiceImpl.Instance;
    const isAdmin = SessionHelper.retrieveUserSession()?.isAdminRole() || false;

    const {printerConfig, setPrinterConfig} = useContext(PrinterConfigContext);
    const printerHelper = new PrinterHelper(setPrinterConfig, printerConfig)

    const [orders, setOrders] = useState<Pagination<Order[]> | null>(null);
    const [selectedOrders, setSelectedOrders] = useState<OrderSelectable[]>([]);
    const [quote, setQuote] = useState<number>(0);
    const [overlay, setOverlay] = useState<boolean>(false);
    const [requestDeliveryConfirmation, setRequestDeliveryConfirmation] = useState<boolean>(false);
    const [cancelDeliveryConfirmation, setCancelDeliveryConfirmation] = useState<boolean>(false);
    const [cancelTaskId, setCancelTaskId] = useState<string>("");

    async function findAllOrders(): Promise<Pagination<Order[]>> {
        return await orderService.findAll([OrderStatus.IN_PREPARATION], 0, 50);
    }

    async function markAsReady(orderId: string) {
        await orderService.markOrderAsReady(orderId)
    }

    async function markAsInTransit(orderId: string) {
        await orderService.markOrderAsInTransit(orderId)
    }

    async function requestPartnerDelivery(orderId: string) {
        await orderService.requestPartnerDelivery(orderId)
    }

    async function rejectOrder(order: Order) {
        await orderService.rejectOrder(order.id)
    }

    useEffect(() => {
        findAllOrders().then((orders) => {
            setOrders(orders)
            onChangeState();
        })

        const interval = setInterval(function () {
            findAllOrders().then((orders) => {
                setOrders(orders)
                onChangeState();
            })
        }, 3000);

        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        if (cancelTaskId != "") {
            setCancelDeliveryConfirmation(true)
        }
    }, [cancelTaskId])

    useEffect(() => {
        if (selectedOrders.length > 0) {
            setRequestDeliveryConfirmation(true)
        }
    }, [selectedOrders])

    // useEffect(() => {
    //     if(requestDeliveryConfirmation) {
    //         taskService.getQuote([requestDriverOrderId]).then((quote) => {
    //             setQuote(quote.price)
    //         })
    //     } else {
    //         setQuote(0)
    //     }
    // },[requestDeliveryConfirmation])

    function getMenuMarkAsReady(orderId: string) {
        return <button
            type="button"
            onClick={(e) => {
                e.preventDefault();
                setOverlay(true);
                markAsReady(orderId).then(() => {
                    findAllOrders().then(() => {
                        toast("Order successfully updated!");
                        setOverlay(false)
                        onChangeState()
                    })
                })
            }}
            className="rounded-l-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Mark as ready
        </button>
    }
    function getRejectOrderAction(order: Order) {
        return <Menu.Item>
            {({active}) => (
                <a onClick={(e) => {
                    e.preventDefault();
                    setOverlay(true);
                    rejectOrder(order).then(() => {
                        findAllOrders().then(() => {
                            toast("Order successfully cancelled!");
                            setOverlay(false)
                            onChangeState()
                        })
                    })
                }}
                   href="#"
                   className={classNames(
                       active ? 'bg-red-100 text-red-900' : 'text-red-700',
                       'group flex items-center px-4 py-2 text-sm'
                   )}
                >
                    <XIcon
                        className="mr-3 h-8 w-8 text-red-400 group-hover:text-red-500"
                        aria-hidden="true"
                    />
                    Cancel order
                </a>
            )}
        </Menu.Item>
    }

    function getMenuMarkAsInTransit(orderId: string) {
        return <Menu.Item>
            {({active}) => (
                <a onClick={(e) => {
                    e.preventDefault();
                    setOverlay(true);
                    markAsInTransit(orderId).then(() => {
                        findAllOrders().then(() => {
                            toast("Order successfully updated!");
                            setOverlay(false)
                            onChangeState()
                        })
                    })
                }}
                   href="#"
                   className={classNames(
                       active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                       'group flex items-center px-4 py-2 text-sm'
                   )}
                >
                    <TruckIcon
                        className="mr-3 h-8 w-8 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                    />
                    Mark as In Transit
                </a>
            )}
        </Menu.Item>
    }

    function getPrintOrder(order: Order) {
        return <Menu.Item>
            {({active}) => (
                <a onClick={(e) => {
                    e.preventDefault();
                    setOverlay(true);
                    printerHelper.printOrder(order).then(() => {
                        toast("Order successfully printed!");
                        setOverlay(false)
                    })
                }}
                   href="#"
                   className={classNames(
                       active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                       'group flex items-center px-4 py-2 text-sm'
                   )}
                >
                    <PrinterIcon
                        className="mr-3 h-8 w-8 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                    />
                    Print order
                </a>
            )}
        </Menu.Item>
    }

    function getRequestDriver(order: Order) {
        return <div>
            <button
                onClick={() => {
                    setSelectedOrders([order as OrderSelectable])
                }}
                type="button"
                className="rounded-full block bg-indigo-600 px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Request Conecta Driver
            </button>
        </div>
    }

    function getActions(order: Order, seeDetailsAction: ReactNode) {
        return <div className="inline-flex rounded-md shadow-sm">
            {getPrimaryAction(order)}
            <Menu as="div" className="relative -ml-px block">
                <Menu.Button className="rounded-r-md bg-indigo-600 border-l-2 border-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <span className="sr-only">Open options</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            {getSecondaryActions(order)}
                            {seeDetailsAction}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    }

    function getSecondaryActions(order: Order) {
        // if (isAdmin) {
            // return [getMenuMarkAsInTransit(order.id), getPrintOrder(order), getRejectOrderAction(order)]
        // } else {
            return [getMenuMarkAsInTransit(order.id), getPrintOrder(order)]
        // }
    }
    function getPrimaryAction(order: Order): JSX.Element {
        return getMenuMarkAsReady(order.id)
    }

    return <>
        <LoadingOverlay active={overlay}/>
        <OrdersList onCancelDelivery={(taskId) => {
            setCancelTaskId(taskId)
        }} requestDriver={getRequestDriver} orders={orders} actions={getActions}/>
        <RequestDelivery selectedOrders={selectedOrders} key={"request-delivery"} open={requestDeliveryConfirmation}
                         onClose={() => {
                             setRequestDeliveryConfirmation(false)
                         }}/>
        <AlertConfirmation
            title="Do you want cancel this delivery?"
            description="This delivery will be cancelled."
            color={"red"}
            confirmText={"Yes, cancel it"}
            open={cancelDeliveryConfirmation}
            onConfirm={() => {
                setOverlay(true)
                taskService.cancelTask(cancelTaskId).then(() => {
                    findAllOrders().then(() => {
                        toast("Task successfully canceled!")
                        setOverlay(false)
                        setCancelDeliveryConfirmation(false)
                        onChangeState()
                    })
                }).catch(() => {
                    setCancelDeliveryConfirmation(false)
                    setOverlay(false)
                    toast("Error while canceling task!", {type: "error"})
                })
            }}
            onClose={() => {
                setCancelTaskId("")
                setCancelDeliveryConfirmation(false)
            }
            }/>
    </>

}