import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon, PencilAltIcon} from "@heroicons/react/solid";
import React, {Fragment, ReactNode, useState} from "react";
import classNames from "../../../common/helper/CssHelper";
import Status from "../components/OrderStatus";
import {Order} from "../data/Order";
import OrderType from "./OrderType";
import DeliveryType from "./DeliveryType";
import Moment from "react-moment";
import {Pagination} from "../../../common/data/Pagination";
import Spinner from "../../../components/Spinner";
import EmptyState from "../../../components/EmptyState";
import OrderDetails from "./OrderDetails";
import clsx from "clsx";
import PaginationComp from "../../../components/Pagination";
import OrderAddress from "./OrderAddress";
import DeliveryTask from "./DeliveryTask";
import {SessionHelper} from "../../../common/helper/SessionHelper";


interface OrderListProps {
    orders: Pagination<Order[]> | null
    actions: (order: Order, seeDetailsAction: ReactNode) => ReactNode,
    requestDriver?: (order: Order) => ReactNode,
    onCancelDelivery?: (taskId: string) => void,
    onDeliveryTaskDetails?: (taskId: string) => void,
    paginationControl?: (page: number) => void | null
}

export default function OrdersList({
                                       orders,
                                       actions,
                                       requestDriver,
                                       paginationControl,
                                       onCancelDelivery,
                                       onDeliveryTaskDetails
                                   }: OrderListProps) {


    const isMultiBusinesses = SessionHelper.retrieveUserSession()?.isMultipleBusinesses() || false;
    const [open, setOpen] = useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = useState<Order>({} as Order);
    const isAdmin = SessionHelper.retrieveUserSession()?.isAdminRole() || false;

    function getSeeDetailsAction(order: Order) {
        return <Menu.Item>
            {({active}) => (
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setSelectedOrder(order);
                        setOpen(true);
                    }}
                    className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm'
                    )}
                >
                    <PencilAltIcon
                        className="mr-3 h-8 w-8 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                    />
                    See Details
                </a>
            )}
        </Menu.Item>
    }

    return <>
        <div
            className="shadow ring-1 ring-black md:overflow-visible overflow-x-auto md:overflow-visible ring-opacity-5 md:rounded-sm mt-5">
            <table className="min-w-full z-0 divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                    <th scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Order
                    </th>
                    <th scope="col"
                        className=" py-3.5 text-left text-sm font-semibold text-gray-900">
                        Customer
                    </th>
                    <th scope="col"
                        className="py-3.5 text-left text-sm font-semibold text-gray-900">
                        Driver
                    </th>
                    <th scope="col"
                        className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Address
                    </th>
                    <th scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Time
                    </th>
                    <th scope="col"
                        className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Price/Status
                    </th>
                    <th scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Action
                    </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {
                    (orders) ? orders.content.map((o) => {
                        return (
                            <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                    <div className="flex items-center">
                                        <strong className="text-blue text-lg">
                                            {o.orderNumber}
                                        </strong>
                                    </div>
                                    {
                                        (isMultiBusinesses) ?
                                            <div>
                                                <p className="font-medium text-gray-900">({o.business.name})</p>
                                            </div> : <></>
                                    }

                                </td>
                                <td className="whitespace-nowrap text-sm">
                                    <div className="flex items-center">
                                        <div className="h-10 w-10 flex-shrink-0">
                                            <img className="h-10 w-10 rounded-full"
                                                 src={o.user.avatar} alt=""/>
                                        </div>
                                        <div className="ml-4">
                                            <div
                                                className="font-medium text-gray-900">{(o.user.fullName != "") ? o.user.fullName : "Unknown"}</div>
                                            <div
                                                className="text-gray-500">{(o.user.phoneNumber != "") ? o.user.phoneNumber : "Phone not defined"}</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="whitespace-nowrap text-sm">
                                    {
                                        (o.driver) ? <div className="flex items-center">
                                            <div className="h-10 w-10 flex-shrink-0">
                                                <img className="h-10 w-10 rounded-full"
                                                     src={o.driver.avatar} alt=""/>
                                            </div>
                                            <div className="ml-4">
                                                <div
                                                    className="font-medium text-gray-900">{(o.driver.fullName != "") ? o.driver.fullName : "Unknown"}</div>
                                                <div
                                                    className="text-gray-500">{(o.driver.phoneNumber != "") ? o.driver.phoneNumber : "Phone not defined"}</div>
                                            </div>
                                        </div> : <div
                                            className="font-medium text-gray-600">
                                            {
                                                o.deliveryType === "MARKETPLACE" ? (requestDriver) ? requestDriver(o) : "Driver not assigned" : <></>
                                            }
                                        </div>
                                    }
                                    {(o.deliveryTask) ? <DeliveryTask onCancel={() => {
                                        if (onCancelDelivery && o.deliveryTask) {
                                            onCancelDelivery(o.deliveryTask.id)
                                        }
                                    }}
                                                                      deliveryTask={o.deliveryTask}
                                                                      isAdmin={isAdmin}
                                                                      onPackDetails={() => {
                                                                          if (onDeliveryTaskDetails && o.deliveryTask) {
                                                                              onDeliveryTaskDetails(o.deliveryTask.id)
                                                                          }
                                                                      }}/> : <></>}
                                </td>
                                <td className="whitespace-nowrap px-1 py-4 text-sm">
                                    <OrderType orderType={o.type}/>
                                    <OrderAddress address={o.deliveryAddress}/>
                                    <DeliveryType deliveryType={o.deliveryType}/>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm ">
                                    <strong
                                        className={clsx("pt-2", (o.scheduleType == 'NOW' ? 'text-cyan-700' : 'text-blue'))}>
                                        {o.scheduleType.toLowerCase()}
                                    </strong>
                                    <div>
                                        <strong>Kitchen: </strong>
                                        <Moment date={o.expectedPreparationTime.start}
                                                format="H:mm"/>
                                    </div>
                                    <div>
                                        <strong>Delivery: </strong>
                                        <Moment date={o.expectedCompletionTime.start}
                                                format="H:mm"/> - <Moment
                                        date={o.expectedCompletionTime.end} format="H:mm"/>
                                    </div>
                                    <p className="text-gray-500">
                                        <Moment date={o.expectedCompletionTime.start}
                                                format="YYYY-MM-DD"/>
                                    </p>
                                </td>
                                <td className="whitespace-nowrap px-1 py-4 text-sm">
                                    <p className="font-bold">
                                        {
                                            (o && o.discount) ? <span
                                                    className="text-gray-500 text-md font-medium mr-1 line-through">£{o.totalBeforeDiscount}</span>
                                                : <></>
                                        }
                                        £{o.total}<span
                                        className="text-gray-600 font-medium">({o.paymentMethod})</span>
                                    </p>
                                    <div className="mt-1">
                                        <Status orderStatus={o.status}/>
                                    </div>
                                </td>

                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4  text-sm font-medium sm:pr-6">
                                    {actions(o,getSeeDetailsAction(o))}
                                </td>
                            </tr>
                        )
                    }) : null
                }
                </tbody>
            </table>
            <PaginationComp totalPages={orders?.totalPages ?? 1} currentPage={orders?.number ?? 0}
                            paginationControl={paginationControl}/>

            <OrderDetails order={selectedOrder} onClose={() => {
                setOpen(false)
            }} open={open}/>

            {
                (orders) ? null : <div className="flex h-40 w-full">
                    <div className="m-auto">
                        <Spinner/>
                    </div>
                </div>
            }
            {
                (orders && orders.content.length == 0) ?
                    <div className="flex w-full p-10">
                        <EmptyState description="Sorry, no content to be shown"
                                    subDescription="No orders were found."/>
                    </div> : null
            }
        </div>
    </>
}