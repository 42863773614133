import axios from "axios";
import RestResponse from "../data/RestResponse";
import {API_URL} from "../const/Variables";
import {JwtHelper} from "./JwtHelper";
import {SessionHelper} from "./SessionHelper";

class HttpHelper {

    async get<T>(resource: string, params?: URLSearchParams): Promise<T> {
        try {
            const {data} = await Axios.get<RestResponse<T>>(resource, {
                params: params
            });
            return data.response;
        } catch (error) {
            console.error(`error at request ${resource}`, error);
            throw error;
        }
    }

    async post<T>(
        resource: string,
        params?: URLSearchParams | null,
        payload?: any,
        headers?: any
    ): Promise<T> {
        try {
            const {data} = await Axios.post<RestResponse<T>>(resource, payload, {
                params: params,
                headers: {
                    ...headers
                }
            });
            return data.response;
        } catch (error) {
            console.error(`error at request ${resource}`, error);
            throw error;
        }
    }

    async put<T>(
        resource: string,
        params?: URLSearchParams | null,
        payload?: any,
        headers?: any
    ): Promise<T> {
        try {
            const {data} = await Axios.put<RestResponse<T>>(resource, payload, {
                params: params,
                headers: {
                    ...headers
                }
            });
            return data.response;
        } catch (error) {
            console.error(`error at request ${resource}`, error);
            throw error;
        }
    }

    async delete<T>(
        resource: string,
        params?: URLSearchParams | null,
        headers?: any
    ): Promise<T> {
        try {
            const {data} = await Axios.delete<RestResponse<T>>(resource, {
                params: params,
                headers: {
                    ...headers
                }
            });
            return data.response;
        } catch (error) {
            console.error(`error at request ${resource}`, error);
            throw error;
        }
    }

}

const Axios = axios.create({
    baseURL: API_URL
});

Axios.interceptors.request.use((config: any) => {

    const jwt = JwtHelper.getJwt()
    const businessId = SessionHelper.retrieveUserSession()?.businessId
    const businessIds = SessionHelper.retrieveUserSession()?.businessIds
    const role = SessionHelper.retrieveUserSession()?.role

    if (jwt) {
        config.headers.Authorization = `Bearer ${jwt}`;
    }

    if(businessId && role === "admin") {
        config.headers["business-id"] = businessId
    }

    if(businessIds && role === "admin") {
        config.headers["business-ids"] = businessIds
    }

    return config;
});

export const httpHelper = new HttpHelper();
