import TaskV2, {TaskOrder} from "../../tasks/data/TaskV2";
import Moment from "react-moment";
import React from "react";

interface DeliveryTaskProps {
    deliveryTask: TaskV2
    onCancel: (taskId: string) => void,
    onPackDetails: (taskId: string) => void,
    isAdmin: boolean
}

export default function DeliveryTask({deliveryTask,onCancel,onPackDetails,isAdmin = false}: DeliveryTaskProps) {
    function getCancelButton(isCancellable: boolean, isAdminCancellable: Boolean) {
        const mayCancel = isAdmin ? isAdminCancellable : isCancellable;
        return mayCancel && mayCancel != undefined ? <button
            onClick={() => onCancel(deliveryTask.id)}
            type="button"
            className="rounded-full mt-2 ml-7 bg-white px-2.5 py-1 text-xs font-semibold text-red-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
            Cancel Delivery
        </button> : null;
    }
  
    function getOrderPack(orders: TaskOrder[]) {
        return (orders && orders.length > 1) ? <div className="flex items-center gap-x-1 sm:justify-start">
            <div className="text-gray-600" onClick={() => onPackDetails(deliveryTask.id)}>Pack</div>
            <div className="flex items-center gap-x-2 text-indigo-700 font-bold">
                {orders.map((order) => order.orderNumber).join(", ")}
            </div>
        </div> : null;
    }

    switch (deliveryTask.status) {
        case "SCHEDULED":
            return <div>
                <div className="flex items-center">
                    <div className={'text-emerald-300 bg-emerald-500/100 flex-none rounded-full p-1'}>
                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                    </div>
                    <div className="ml-4">
                        <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                            <div className="hidden sm:block">Collection Scheduled</div>
                        </div>
                        <div>
                            <p className="text-gray-600 text-sm">
                                Driver ring time <span className="font-bold"><Moment format="H:mm">{deliveryTask.scheduledAfter}</Moment></span>
                            </p>
                        </div>
                        {getOrderPack(deliveryTask.orders)}
                    </div>
                </div>
                {getCancelButton(deliveryTask.cancellable, deliveryTask.adminCancellable)}
            </div>
        case "CREATED":
            return <div>
                <div className="flex items-center">
                    <div className={'text-violet-300 bg-violet-500/100 flex-none rounded-full p-1'}>
                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                    </div>
                    <div className="ml-4">
                        <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                            <div className="hidden sm:block">Collection created</div>
                        </div>
                        {getOrderPack(deliveryTask.orders)}
                    </div>
                </div>
                {getCancelButton(deliveryTask.cancellable, deliveryTask.adminCancellable)}
            </div>
        case "PENDING_CONFIRMATION":
            return <div>
                <div className="flex items-center">
                    <div className={'text-violet-300 bg-violet-500/100 flex-none rounded-full p-1'}>
                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                    </div>
                    <div className="ml-4">
                        <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                            <div className="hidden sm:block">Pending Confirmation</div>
                        </div>
                        {getOrderPack(deliveryTask.orders)}
                    </div>
                </div>
                {getCancelButton(deliveryTask.cancellable, deliveryTask.adminCancellable)}
            </div>
        case "WAITING_ASSIGNMENT":
            return <div>
                <div className="flex items-center">
                    <div className={'text-indigo-300 bg-indigo-500/100 flex-none rounded-full p-1'}>
                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                    </div>
                    <div className="ml-4">
                        <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                            <div className="hidden sm:block">Ringing drivers now..</div>
                        </div>
                        {getOrderPack(deliveryTask.orders)}
                    </div>
                </div>
                {getCancelButton(deliveryTask.cancellable, deliveryTask.adminCancellable)}
            </div>
        case "NO_ASSIGNEE_AVAILABLE":
            return <div>
                <div className="flex items-center">
                    <div className={'text-red-300 bg-red-500/100 flex-none rounded-full p-1'}>
                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                    </div>
                    <div className="ml-4">
                        <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                            <div className="hidden sm:block">Trying again shortly</div>
                        </div>
                        {getOrderPack(deliveryTask.orders)}
                    </div>
                </div>
                {getCancelButton(deliveryTask.cancellable, deliveryTask.adminCancellable)}
            </div>
        default:
            return <>
                {getCancelButton(deliveryTask.cancellable, deliveryTask.adminCancellable)}
            </>
    }
}