import React from "react";
import Status from "./Status";
import StatusPin from "./StatusPin";

interface DriverRowProps {
    readonly id: string;
    readonly image: string;
    readonly name: string;
    readonly status: string;
    readonly earnings: number;
    readonly onNewPosition: (id: string) => void;
}

export default function DriverRow({
                                      id,
                                      image,
                                      name,
                                      status,
                                      earnings,
                                      onNewPosition
                                  }: DriverRowProps) {
    return <tr key={id}>
        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
            <p onClick={() => {
                onNewPosition(id)
            }}>
                <div className="flex items-center">
                <span className="relative inline-block">
                    <div className="h-11 w-11 flex-shrink-0">
                    <img className="h-11 w-11 rounded-full" src={image} alt=""/>
                    <StatusPin status={status}/>
                </div>
                </span>
                    <div className="ml-4">
                        <div className="font-medium text-gray-900">{name}</div>
                        <div className="mt-1 text-gray-500">Earnings {earnings}</div>
                    </div>
                </div>
            </p>
        </td>
    </tr>;
}